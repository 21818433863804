import { Col, Row } from 'antd';
import Lottie from 'lottie-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import feedback from '../../animate-lottie/feedback.json';
import { NJVButton } from '../../components/core-component';
import Theme from '../../components/theme';

class Feedback extends Component {
  render() {
    return (
      <Row  style={{ textAlign: 'center',display: 'flex',alignItems:'center',height: '100%' }}>
        <Col span={24}>
          <Lottie style={{ height: 200, marginBottom: 20 }} animationData={feedback} />
          <Link to="https://docs.google.com/forms/d/e/1FAIpQLScehGQToHy5rGiDZPHnz8vqdRLUoInzkVc1H5LWl920tBhcwQ/viewform" target='_blank'>
            <NJVButton size="large" type="primary" style={{ background: Theme.colors.primary }}>Give us your feedback</NJVButton>
          </Link>
        </Col>
      </Row>
    )
  }
}

export default Feedback;