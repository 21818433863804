import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: {},
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state,{payload}) => {
            const { key, data } = payload;
            if (!state.items[key]) {
                state.items[key] = [data];
            } else {
                const itemIndex = state.items[key].findIndex(item => item.id === data.id)
                if(itemIndex !== -1){
                    state.items[key][itemIndex] = data
                }else{

                    state.items[key].push(data);
                }
            }
        },
        increaseItem : (state,{payload}) => {
            const {id,stock} = payload
            for(const key in state.items){
                if(state.items.hasOwnProperty(key)){
                    const itemIndex = state.items[key].findIndex(item => item.id === id);
                    if (itemIndex !== -1) {
                        if (state.items[key][itemIndex].quantity < stock) {
                            state.items[key][itemIndex].quantity += 1;
                        }
                        break;
                    }
                }
            }
        },
        decreaseItem: (state,{payload}) => {
            const {id} = payload
            for(const key in state.items){
                if(state.items.hasOwnProperty(key)){
                    const itemIndex = state.items[key].findIndex(item => item.id === id);
                    if (itemIndex !== -1) {
                        if (state.items[key][itemIndex].quantity > 1) {
                            state.items[key][itemIndex].quantity -= 1;
                        }
                        break;
                    }
                }
            }
        },
        removeItem: (state,{payload}) => {
            for(const key in state.items){
                if(state.items.hasOwnProperty(key)){
                    state.items[key] = state.items[key].filter(item => item.id !== payload)
                    if(state.items[key].length === 0){
                        delete state.items[key]
                    }
                    break;
                }
            }
        },
        clearCart: (state) => {
            state.items = {}
        }
    }
})

export const { addToCart, increaseItem, decreaseItem, removeItem, clearCart } = cartSlice.actions;
export const cartItems = (state) => state.cart.items;

export default cartSlice.reducer