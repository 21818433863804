import { Navigate } from "react-router-dom"
import { authenticated } from "../redux/auth-slice"

export const RouteGuard = ({ children }) => {
    if(authenticated){
        return <Navigate to={'/v1/dashboard'} />
    }else{
        return children
    }
}
export default RouteGuard