import { compose } from "@reduxjs/toolkit";
import { Col, Image, Row, Skeleton, Steps } from "antd";
import i18next from "i18next";
import Lottie from "lottie-react";
import React from "react";
import NoDataFoundAnimation from '../animate-lottie/lottie-not-data-found.json';
import { NJVButton, NJVCard, NJVSpacer } from "../components/core-component";
import Api from "../network/api";
import { HTTP_METHOD } from "../network/httpMethod";
import { MEDIA_TYPE } from "../network/mediaType";
import { ApiHandler } from "../network/network-manager";
import withRouter from "../network/with-router";
import CustomPath from "../routes/custom-path";
const containerW = '3in'
const containerH = '4in'

const NJVLabel = ({ label }) => {
    return (
        <>
            <span style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</span>
            <br />
        </>)

}

const NJVValue = ({ value }) => {
    return (
        <>
            <span style={{ fontSize: 16, fontWeight: '500', color: 'black' }}>{value}</span>
            <br />
        </>)

}

class SingleOrderResult extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: {},
            isDataFetching: true,
            viewPhoto: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const requestParams = {
                trackingNumber: this.props.params.id
            }
            const response = await ApiHandler({url: Api.order_get_by_tracking_number, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams})
            if (response) {
                this.setState({
                    data: response,
                })
            }

        } catch (error) {
            if(error.response.status === 500){
                this.setState({
                 data: null
                })
             }
        }
        this.setState({
            isDataFetching: false
        })
    }




    render() {
        const { data, isDataFetching, viewPhoto } = this.state
        const customSizeStyles = {
            width: containerW,
            height: containerH,
        };


        return (
            <>


                <Row>

                    <Col lg={6} md={6} xs={1} sm={1} />
                    <Col lg={12} md={6} xs={22} sm={22}>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col span={12}>
                                {/* <Breadcrumb
                                    items={[
                                        {
                                            title: 'Order Detail'
                                        }

                                    ]}
                                /> */}
                                <h2>{i18next.t("parcel_detail")}</h2>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <NJVButton type="primary" onClick={() => this.props.navigate(CustomPath.dashboard)}>Back To Dashboard</NJVButton>
                            </Col>
                        </Row>
                        <NJVSpacer height={20} />
                        {
                            isDataFetching ?
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <Skeleton active />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    {
                                        data ?
                                            <div>
                                                <Row>
                                                    <Col span={24}>
                                                        <NJVCard>
                                                            <NJVLabel label="Tracking Number" />
                                                            <NJVValue value={data.trackingNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.fromAddress && data.fromTownship ?
                                                                    <>
                                                                        <NJVLabel label="Pickup Address" />
                                                                        <NJVValue value={`${data.fromAddress}, ${data.fromTownship.name}, ${data.fromTownship.name} `} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }


                                                        </NJVCard>
                                                    </Col>
                                                </Row>
                                                <NJVSpacer height={20} />
                                                <Row>
                                                    <Col span={24}>
                                                        <NJVCard>
                                                            <NJVLabel label="Receiver Name" />
                                                            <NJVValue value={`${data.recipientName}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="Receiver Phone Number" />
                                                            <NJVValue value={data.recipientPhoneNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.toAddress && data.toTownship ?
                                                                    <>
                                                                        <NJVLabel label="Receiver Address" />
                                                                        <NJVValue value={`${data.toAddress}, ${data.toTownship.name}, ${data.toTownship.division.name}`} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </NJVCard>
                                                    </Col>
                                                </Row>
                                                <NJVSpacer height={20} />
                                                <Row>
                                                    <Col span={24}>
                                                        <NJVCard>
                                                            <NJVSpacer height={30} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="Pickup Time" />
                                                            <NJVValue value={`${data.pickupDate}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="OrderType" />
                                                            <NJVValue value={`${data.orderType}`} />
                                                            {
                                                                data.paymentOption ?
                                                                    <>
                                                                        <NJVSpacer height={30} />
                                                                        <NJVLabel label="Payment Option" />
                                                                        <NJVValue value={`${data.paymentOption}`} />
                                                                    </>
                                                                    :
                                                                    <></>

                                                            }
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data && data.note ?
                                                                    <>
                                                                        <NJVSpacer height={30} />
                                                                        <NJVLabel label="Note" />
                                                                        <NJVValue value={`${data.note}`} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                            <NJVLabel label="COD Amount" />
                                                            <NJVValue value={`${data.codAmount} MMK`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="Status" />
                                                            <NJVSpacer height={10} />
                                                            <Steps
                                                                size="small"
                                                                direction="vertical"
                                                                items={[
                                                                    {
                                                                        title: 'Pending Pickup',

                                                                    },
                                                                    {
                                                                        title: 'Arrived at Origin Hub',
                                                                    },
                                                                    {
                                                                        title: 'Delivered',
                                                                    },
                                                                    {
                                                                        title: 'Received by Customer',
                                                                    },
                                                                ]}
                                                            />
                                                            {
                                                                data.busGateVoucherImagePath ?
                                                                    <>
                                                                        <NJVLabel label="Bus Gate Voucher" />
                                                                        <Image
                                                                            style={{
                                                                                marginTop: 5,
                                                                                borderRadius: 9,
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={() => this.setState({ viewPhoto: true })}
                                                                            src={data.busGateVoucherImagePath}
                                                                            // preview={false}
                                                                            width={200}
                                                                        />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                        </NJVCard>
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                                    <Col span={24}>
                                                        <ReactToPrint
                                                            trigger={() => <Button type="primary">Print</Button>}
                                                            content={() => this.refComponent}
                                                        />
                                                        <div style={{ height: 20 }} />
                                                        <div
                                                            key={2}
                                                            ref={(el) => this.refComponent = el}
                                                            style={customSizeStyles}>
                                                            <AirWayBillSlip
                                                                key={1}
                                                                senderName="Asoneya Distribution MDY"
                                                                senderPhone="97878343423"
                                                                senderAddress="26th street, between 80x81 street, ChanAyeThazan Township, Mandalay"
                                                                receiverName="Ma Kaing Sant"
                                                                receiverPhone="97878343423"
                                                                receiverAddress="No.135, 4th Floor, Thu Kha Street, Hlaing Township, Yangon"
                                                                trackId="NVMMYOEAS0088991234"
                                                                createDate="16 Sep, 23"
                                                                cod="13,000"
                                                                sortingCode="O-A-03"
                                                            />
                                                            {this.test}
                                                        </div>
                                                        
                                                    </Col>
                                                </Row> */}


                                            </div>
                                            :
                                            <Row>
                                                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Lottie
                                                        style={{ height: '200px' }}
                                                        animationData={NoDataFoundAnimation}
                                                        loop={true}
                                                        autoplay={true}
                                                    />
                                                </Col>
                                                <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                                    <div style={{ fontSize: 16, fontWeight: '600', marginBottom: 10 }}>
                                                        {`No Parcel found`}
                                                    </div>
                                                </Col>
                                            </Row>
                                    }
                                </>
                        }
                    </Col>

                </Row>
            </>
        )
    }

}

export default compose(withRouter)(SingleOrderResult)