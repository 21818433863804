import { compose } from "@reduxjs/toolkit";
import { Col, Collapse, Row, Skeleton, Typography } from "antd";
import i18next from "i18next";
import React from "react";
import { SvgChevronDown, SvgChevronRight } from "../components/custom-svgIcon";
import { TitleLevel3 } from "../components/general-component";
import Api from "../network/api";
import { HTTP_METHOD } from "../network/httpMethod";
import { MEDIA_TYPE } from "../network/mediaType";
import { ApiHandler } from "../network/network-manager";
import withRouter from "../network/with-router";
const { Panel } = Collapse;

class FAQ extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isDataFetching: false
        }
    }

    componentDidMount() {
        this.setState({ isDataFetching: true })
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const response = await ApiHandler({ url: Api.faq, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {                
                this.setState({ isDataFetching: false, data: response })
            }
        } catch (error) {

        }
    }


    render() {
        const { data, isDataFetching } = this.state

        const itemsNest = data?.map(faq => faq?.faqs?.length > 0 && faq?.faqs?.map(faqs => ({
            key: faqs.id,
            label: faqs.question,
            children: faqs.answer,
        })))

        const items = data?.map((faqData, index) => ({
            key: faqData.id,
            label: faqData.title,
            children: <Collapse defaultActiveKey="1" items={itemsNest[index]} />,
        }));

        return (
            <>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <TitleLevel3 label={i18next.t("faq")} />
                            </Col>
                            <Col span={24}>
                                {
                                    isDataFetching ?
                                        <Skeleton />
                                        :

                                        <Collapse items={items}
                                            expandIconPosition="end"
                                            bordered={false}
                                            expandIcon={({ isActive }) => <>
                                                {isActive ? <SvgChevronDown width={20} height={20} color={'black'} /> : <SvgChevronRight width={20} height={20} color={'black'} />}</>}
                                        />
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(FAQ)
