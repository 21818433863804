import Api from "./api"
import { HTTP_METHOD } from "./httpMethod"
import { MEDIA_TYPE } from "./mediaType"
import { ApiHandler } from "./network-manager"

export const FetchOrderHistory = async (params) => {
    let responseData = null
    await ApiHandler({ url: Api.order_history, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams: params })
        .then(res => { responseData = res })
        .catch(error => { throw error })
    return responseData
}