import { compose } from "@reduxjs/toolkit";
import React from "react";
import withRouter from "../network/with-router";
import { Col, Row } from "antd";
import { TitleLevel3 } from "../components/general-component";
import Theme from "../components/theme";
import { SvgPrinterFilled } from "../components/custom-svgIcon";
import { LOC_getPrinterType, LOC_updatePrinterType } from "../core/custom-local-storage";
import { Printer } from "../core/constant";
import i18next from "i18next";

const cardStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 66,
    paddingLeft: 15,
    borderRadius: 15,
    backgroundColor: '#f1f1f1',
    cursor: 'pointer',
}

const selectedCardStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 66,
    paddingLeft: 15,
    borderRadius: 15,
    backgroundColor: '#f1f1f1',
    cursor: 'pointer',
    border: '2px dashed red'
}

class PrinterSetting extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            printerType: null
        }
    }
    componentDidMount() {
        this.setState({
            printerType: LOC_getPrinterType()
        })
    }

    render() {
        const { printerType } = this.state
        return (
            <Row gutter={[16, 16]}>
                <Col span={1} />
                <Col span={22}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <TitleLevel3 label={i18next.t("printer_setting")} />
                        </Col>
                        <Col span={6} >
                            <div
                                onClick={() => {
                                    this.setState({ printerType: Printer.GPrinter })
                                    LOC_updatePrinterType(Printer.GPrinter)
                                }}
                                style={printerType === Printer.GPrinter ? selectedCardStyle : cardStyle}>
                                <div className="icon-circle" style={{ backgroundColor: Theme.colors.primary }}>
                                    <SvgPrinterFilled width={20} height={20} color={'#fff'} />
                                </div>
                                <span style={{ marginLeft: 10, fontWeight: 'bold' }}> GPrinter</span>
                            </div>
                        </Col>
                        <Col span={6} >
                            <div
                                onClick={() => {
                                    this.setState({ printerType: Printer.APrinter })
                                    LOC_updatePrinterType(Printer.APrinter)
                                }}
                                style={printerType === Printer.APrinter ? selectedCardStyle : cardStyle}>
                                <div className="icon-circle" style={{ backgroundColor: Theme.colors.primary }}>
                                    <SvgPrinterFilled width={20} height={20} color={'#fff'} />
                                </div>
                                <span style={{ marginLeft: 10, fontWeight: 'bold' }}> APrinter</span>
                            </div>
                        </Col>
                    </Row>
                </Col >
            </Row >

        )
    }
}

export default compose(withRouter)(PrinterSetting)