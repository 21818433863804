import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Card, Col, Form, Modal, Row, Select, Space, Table, Tag, message } from 'antd';
import i18next from 'i18next';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import animationData from '../../animate-lottie/animation_lnfk8gz0.json';
import { NJVButton, NJVCard, NJVInput, NJVSelect, NJVSpacer, NJVTable } from '../../components/core-component';
import { FormLabel, TitleLevel3 } from '../../components/general-component';
import Theme from '../../components/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import withRouter from '../../network/with-router';
import CustomPath from '../../routes/custom-path';
// import FailAnimation from '../../animate-lottie/lottie-404.json';
import Lottie from 'lottie-react';
import ShippingAnimation from '../../animate-lottie/lottie-shipping.json';
const { Option } = Select;

const initialState = 1
const confirmState = 2
const successState = 3
// const failState = 4

class CreateVoucher extends Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultLocation: {},
      isFetchingMyLocation: false,
      page: 1,
      voucherInfo: {},
      openConfirmModal: false,
      isCopied: false,
      voucherState: initialState,
      voucherLink: null
    }
    this.formRef = React.createRef()
  }

  componentDidMount() {
    this.fetchMyLocation()
  }

  fetchMyLocation = async () => {
    this.setState({
      isFetchingMyLocation: true
    })
    try {
      const requestParams = { isMyLocation: true }
      const response = await ApiHandler({ url: Api.user_address_available, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
      if (response) {
        let location = {}
        let foundDefaultLocation = false
        let userAddress = {}
        response.map((address) => {
          if (address.defaultLocation) {
            location = address.id
            foundDefaultLocation = true
            userAddress = address
          }
        })
        if (!foundDefaultLocation && response.length !== 0) {
          location = response[0].id
          userAddress = response[0]
        }
        this.setState(previous => (
          {
            pickUpAddress_collection: response,
            defaultLocation: location,
            voucherInfo: { ...previous.voucherInfo, userAddress },
            isFetchingMyLocation: false
          }
        ))
      }
    } catch (error) {
    }
  }

  handlePickupLocation = (value) => {
    const { pickUpAddress_collection } = this.state
    let userAddress = {}
    pickUpAddress_collection.map(add => {
      if (add.id === value) {
        userAddress = add
      }
    })
    this.setState({
      defaultLocation: value,
      voucherInfo: userAddress,
    })
  }

  handleAddProduct = (values) => {
    const { voucherInfo, isUpdateData } = this.state;
    const uid = uuidv4()

    let isUpdate = false;
    let currentUserInput = { ...values }

    if (!values.uuid) {
      currentUserInput = { ...currentUserInput, uuid: uid, key: uid }
    } else {
      isUpdate = true
    }
    let new_userinput_collection = []
    if (isUpdate) {
      new_userinput_collection = voucherInfo.shippingItems.map(info => {
        return info.uuid === currentUserInput.uuid ? currentUserInput : info
      })
    } else {
      new_userinput_collection = [...(voucherInfo.shippingItems || []), currentUserInput]
    }
    this.setState((prevState) => ({
      isUpdateData: false,
      voucherInfo: {
        ...prevState.voucherInfo,
        shippingItems: new_userinput_collection
      }
    }))
    message.success('product successfully ' + (isUpdateData ? 'updated' : 'added'));
    this.formRef.current.resetFields();
  }

  handleCreateVoucher = async () => {
    const { voucherInfo } = this.state;
    this.setState({
      voucherState: confirmState
    })

    const totalAmount = voucherInfo.shippingItems?.reduce((acc, item) => acc + (item.quantity * item.price), 0)

    try {
      const requestData = {
        "uniqueId": null,
        "customerPhoneNo": null,
        "customerName": null,
        "addressDetail": null,
        "townshipId": null,
        "orderType": "Home Delivery",
        "busGateId": null,
        "paymentOption": "Collect from customer",
        "deliveryCharges": 0.0,
        "codAmount": 0.0,
        "parcelAmount": totalAmount,
        "pickupLocationId": voucherInfo?.userAddress?.id || null,
        "isSameDay": null,
        "note": null,
        "facebookName": voucherInfo?.fbName,
        "shippingItems": voucherInfo?.shippingItems || null
      };
      const response = await ApiHandler({ url: Api.all_vouchers, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData })
      if (response && response.status === 200) {
        this.setState({
          voucherState: successState,
          voucherLink: response?.data?.customerConfirmUrl
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  editProduct = (uid) => {
    const { voucherInfo } = this.state
    const editProduct = voucherInfo?.shippingItems.find(item => item.uuid === uid);
    if (editProduct) {
      this.formRef.current.setFieldsValue({ uuid: uid, ...editProduct });
    }
    this.setState({
      isUpdateData: true
    })
  }

  deleteProduct = (uid) => {
    const { voucherInfo, page, isUpdateData } = this.state
    if (isUpdateData) {
      message.destroy()
      message.error("You can't delete the receiver while editing their information.")
      return
  }
    const new_ProductItems = voucherInfo?.shippingItems.filter(item => item.uuid !== uid)
    const newPage = new_ProductItems.length === 0 ? 1 : (Math.ceil(new_ProductItems.length / 10) >= page ? page : page - 1);
    this.setState({
      voucherInfo: {
        ...voucherInfo,
        shippingItems: new_ProductItems,
      },
      page: newPage
    })
  }

  onChangePagination = (currentPage) => {
    this.setState({
      page: currentPage.current
    })
  }

  handleCopy = () => {
    navigator.clipboard?.writeText(this.state.voucherLink).then(() => {
      this.setState({ isCopied: true });
      setTimeout(() => this.setState({ isCopied: false }), 3000)
    }).catch((error) => {
      console.log('Unable to copy: ', error);
    })
  }

  handleSubmitBtn = () => {
    const { voucherInfo } = this.state;
    if (!voucherInfo?.fbName) {
      message.error(`${i18next.t('error_msg_fb')}`);
      return;
    }
    this.setState({ openConfirmModal: true });
  }

  handleOnChangeFbName = (value) => {
    const trimValue = value.trim();
    const inputValue = trimValue.length > 50 ? '' : trimValue;
    if (trimValue.length > 50) {
      message.error('Facebook name cannot exceed 50 characters')
      return
    }
    this.setState((prev) => ({
      voucherInfo: {
        ...prev.voucherInfo,
        fbName: inputValue
      }
    }));
  }

  render() {
    const { defaultLocation, isFetchingMyLocation, pickUpAddress_collection, voucherInfo, page, isUpdateData, openConfirmModal, voucherState, isCopied } = this.state;

    let columns = [
      {
        title: <>{i18next.t("no")}</>,
        key: 'index',
        render: (value, item, index) => {
          return <span>{(page - 1) * 10 + index + 1}</span>
        },
        width: 5
      },
      {
        title: <>{i18next.t("name")}</>,
        dataIndex: 'name',
        key: 'name',
        align: 'center'
      },
      {
        title: <>{i18next.t("price")}</>,
        dataIndex: 'price',
        key: 'price',
        align: 'center'
      },
      {
        title: <>{i18next.t("quantity")}</>,
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center'
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, product) => (
          <Space>
            <NJVButton size={'middle'} type="primary" shape="circle"
              onClick={() => this.editProduct(product.uuid)}
            ><EditOutlined /></NJVButton>
            <NJVButton size={'middle'} type="primary" shape="circle"
              onClick={() => this.deleteProduct(product.uuid)}
              danger><DeleteOutlined /></NJVButton>
          </Space>
        ),
        width: 10
      },
    ]

    return (
      <>
        <Modal
          title={null}
          centered
          open={openConfirmModal}
          onOk={() => this.handleCreateVoucher()}
          footer={null}
          closeIcon={null}
          className='custom-modal'
        >
          <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '100%', textAlign: 'center' }}>
              {
                voucherState === initialState ?
                  <>
                    <Lottie
                      style={{ height: '200px' }}
                      animationData={ShippingAnimation}
                      loop={true}
                      autoplay={true}
                    />
                    <div style={{ marginBottom: 10, fontSize: 18, fontWeight: '500' }}>{i18next.t("confirm_to_create_voucher")}</div>
                    <NJVButton type='text' style={{ marginTop: 10, marginRight: 10 }} onClick={() => this.setState({ openConfirmModal: false, currentStep: 1 })}>{i18next.t("cancel")}</NJVButton>
                    <NJVButton type='primary' style={{ marginTop: 10 }} onClick={() => this.handleCreateVoucher()}>{i18next.t("confirm")}</NJVButton>

                  </> :
                  <></>
              }
              {
                voucherState === confirmState ?
                  <>

                    <Lottie
                      style={{ height: '200px' }}
                      animationData={animationData}
                      loop={true}
                      autoplay={true}
                    />

                    <div style={{ fontSize: 15, fontWeight: '500' }}>{i18next.t("msg_please_wait")}</div>
                  </> :
                  <></>
              }
              {
                voucherState === successState ?
                  <>
                    <div style={{ marginTop: 10, fontSize: 21, fontWeight: '600' }}>{i18next.t("order_created")}</div>
                    <p style={{ fontWeight: 'bold' }}>{i18next.t("msg_order_success")}</p>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: 10, marginBottom: 20, alignItems: 'center' }}>
                      <span style={{ fontSize: 13 }}>{this.state.voucherLink}</span>
                      <NJVButton type="primary" size="small" style={{ background: Theme.colors.primary }} onClick={this.handleCopy}>{isCopied ? <><CheckOutlined />{i18next.t("copied")}</> : <><CopyOutlined />{i18next.t('copy')}</>}</NJVButton>
                    </div><NJVButton type='primary' size="larger" style={{ background: Theme.colors.primary }} onClick={() => this.props.navigate(CustomPath.confirm_order)}>Ok</NJVButton>
                  </>
                  :
                  <></>
              }
              {/* {
                        voucherState === failState ?
                            <>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={FailAnimation}
                                    loop={false}
                                    autoplay={true}
                                />
                                <div style={{ marginTop: 20, marginBottom: 20, fontSize: 15, fontWeight: '500' }}>
                                    {
                                        fail_message_code === '076' ?
                                            <>
                                                {i18next.t("msg_fail_to_create_parcel")}
                                            </>
                                            :
                                            fail_message_code === '008' ? <>{i18next.t("msg_reselect_date")}</> :
                                                <>
                                                    {
                                                        fail_message_code === '077' ?
                                                            <>
                                                                {i18next.t("msg_fail_to_create_parcel_077")}
                                                            </>
                                                            :
                                                            <>
                                                                {i18next.t("msg_fail_to_create_parcel")}
                                                            </>
                                                    }
                                                </>
                                    }



                                </div>
                                <NJVButton type='primary' onClick={() => this.setState({ openConfirmModal: false, voucherState: initialState })}>Yes</NJVButton>
                            </>
                            :
                            <></>
                    } */}
            </div>
          </div>
        </Modal>
        <Row style={{ marginBlock: 20 }}>
          <Col lg={4} md={4} sm={1} xs={1} />
          <Col lg={16} md={16} sm={22} xs={22}>
            <Row>
              <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                <TitleLevel3 label={i18next.t("create_confirm_order")} />
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <NJVButton type="primary" onClick={() => this.props.navigate(CustomPath.confirm_order)}>{i18next.t('back')}</NJVButton>
              </Col>
            </Row>
            <NJVSpacer height={20} />
            <Row>
              <Col span={24}>
                <NJVCard style={{ paddingInline: 8, marginBottom: 20, paddingBottom: 20 }}>
                  <Row gutter={24}>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <h4>{i18next.t("sender_info")}</h4>
                      <FormLabel label={i18next.t("pickup_address")} />
                      <NJVSelect name="pickupAddress" size="large" style={{ width: "100%" }} value={defaultLocation} onChange={value => this.handlePickupLocation(value)} loading={isFetchingMyLocation}>
                        {
                          pickUpAddress_collection && pickUpAddress_collection?.map((address) => {
                            return <Option key={address.id} value={address.id}>{address.name}{address.defaultLocation ? <Tag style={{ borderRadius: 20, marginLeft: 10 }} color='#8f8f8f'>Default</Tag> : <></>}</Option>
                          })
                        }
                      </NJVSelect>
                      <p style={{ color: Theme.colors.primary, marginBottom: 0 }}>{i18next.t("msg_pickup_notice")}</p>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <h4>{i18next.t("customer_info")}</h4>
                      <FormLabel label={i18next.t("fb_name")} />
                      <NJVInput name="fbName" size="large" onChange={(e) => this.handleOnChangeFbName(e.target.value)} />
                    </Col>
                  </Row>
                </NJVCard>
                <Card style={{
                  backgroundColor: "#f5f5f5",
                  boxShadow: 'none',
                }}
                  bordered={false}>
                  <h4>{i18next.t("product_info")}</h4>
                  <Row style={{ marginBottom: 20 }}>
                    <Col span={24}>
                      <NJVTable columns={columns} dataSource={voucherInfo?.shippingItems} onChange={this.onChangePagination} />
                    </Col>
                  </Row>
                  <Form ref={this.formRef} onFinish={this.handleAddProduct} layout='vertical'>
                    <Row gutter={24}>
                      <Form.Item name="uuid" hidden>
                        <NJVInput />
                      </Form.Item>
                      <Col lg={8} md={8} sm={24} xs={24} style={{ textAlign: 'start' }}>
                        <Form.Item name="name" label={i18next.t("name")} rules={[
                          {
                            required: true,
                            message: i18next.t('enter_name')
                          },
                          {
                            max: 50,
                            message: i18next.t('Name cannot exceed 50 characters.'),
                          }
                        ]}>
                          <NJVInput size="large" />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={8} sm={24} xs={24}>
                        <Form.Item name="price" label={i18next.t("price")} rules={[
                          {
                            required: true,
                            message: i18next.t('error_msg_price'),
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: i18next.t('Invalid Price'),
                          },
                          {
                            max: 7,
                            message: i18next.t('Price cannot exceed 7.'),
                          }
                        ]}>
                          <NJVInput size="large" min={1} style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={8} sm={24} xs={24}>
                        <Form.Item name="quantity" label={i18next.t("quantity")} rules={[
                          {
                            required: true,
                            message: i18next.t('error_msg_quantity')
                          },
                          {
                            pattern: /^[1-9][0-9]*$/,
                            message: i18next.t('Quantity must be a positive integer and must start from 1'),
                          },
                          {
                            max: 7,
                            message: i18next.t('Quantity cannot exceed 7.'),
                          }
                        ]}>
                          <NJVInput size="large" min={1} style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                      <Col span={24} style={{ textAlign: 'right' }}>
                        <Form.Item>
                          <NJVButton type="primary" danger style={{ marginRight: 10 }} onClick={() => this.formRef.current.resetFields()}>{i18next.t("clear")}</NJVButton>
                          <NJVButton type="primary" htmlType="submit">
                            {
                              isUpdateData ? <span>Update</span> : <span>{i18next.t("add_product")}</span>
                            }
                          </NJVButton>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>
                <Row>
                  <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                    {
                      voucherInfo.shippingItems ?
                        <NJVButton htmlType="submit" type="primary" onClick={() => this.handleSubmitBtn()} style={{ background: Theme.colors.primary }} size='large'>{i18next.t("create_voucher")}</NJVButton>
                        : <></>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(withRouter)(CreateVoucher)