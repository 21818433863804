import { createSlice } from '@reduxjs/toolkit'

export const orderFormSlice = createSlice({
    name: 'orderform',
    initialState: {
        orderList: [],
        orderCardList: [],
        advanceOrderList: [],
        orderCreateTime: null,
    },
    reducers: {
        updateOrderList: (state, action) => {
            state.orderList = action.payload
        },
        updateOrderCardList: (state, action) => {
            state.orderCardList = action.payload
        },
        updateAdvanceOrderList: (state, action) => {
            state.advanceOrderList = action.payload
        },
        updateOrderSession: (state, action) => {
            state.orderCreateTime = action.payload
        },
        clearOrder: (state, action) => {
            state.orderList = []
            state.advanceOrderList = []
            state.time = null
        }
    },
})

export const { updateOrderList, updateOrderCardList, clearOrder, updateAdvanceOrderList, updateOrderSession } = orderFormSlice.actions
export const orderList = (state) => state.orderform.orderList
export const orderCardList = (state) => state.orderform.orderCardList
export const advanceOrderList = (state) => state.orderform.advanceOrderList
export const orderCreateTime = (state) => state.orderform.orderCreateTime
export default orderFormSlice.reducer
