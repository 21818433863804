import { createSlice } from '@reduxjs/toolkit'

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        shipperData: null,
        isFetchProfile: false,
        fullName: '',
        phoneNumber: '',
        shipperUniqueId: '',
        businessName: '',
        profileImgUrl: '',
        isSettlement: false,
        statusKey: { isPending: false,isReady: false, isConfirm: false}    
    },
    reducers: {
        updateProfile: (state, {payload}) => {
            state.shipperData = payload
            state.isFetchProfile = true
            state.fullName = payload.fullName
            state.phoneNumber = payload.phoneNumber
            state.shipperUniqueId = payload.uniqueId
            state.businessName = payload.business?.businessName
            state.profileImgUrl = payload.profileUrl
        },
        clearProfile: (state, action) => {
            state.phoneNumber = ''
            state.fullName = ''
            state.shipperUniqueId = ''
            state.isFetchProfile = false
        },
        updateSettlementNoti : (state,{payload}) => {
            state.isSettlement = payload
        },
        updateStatusKey: (state, {payload}) => {
            state.statusKey = payload
        }
    },
})

export const { updateProfile,clearProfile, updateSettlementNoti, updateStatusKey } = profileSlice.actions
export const shipperData = (state) => state.profile.shipperData
export const fullName = (state) => state.profile.fullName
export const phoneNumber = (state) => state.profile.phoneNumber
export const shipperUniqueId = (state) => state.profile.shipperUniqueId
export const isFetchProfile = (state) => state.profile.isFetchProfile
export const businessName = (state) => state.profile.businessName
export const profileImg = (state) => state.profile.profileImgUrl
export const isSettlement = (state) => state.profile.isSettlement
export const statusKey = (state) => state.profile.statusKey
export default profileSlice.reducer
