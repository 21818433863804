import { compose } from "@reduxjs/toolkit";
import { PlusOutlined } from '@ant-design/icons';
import { Checkbox, Col, ConfigProvider, Form, Image, Modal, Row, Select, Steps, Typography, Upload, message } from "antd";
import i18next from "i18next";
import Lottie from "lottie-react";
import React from "react";
import { Link } from "react-router-dom";
import SentAnimation from '../../animate-lottie/lottie-sent.json';
import { NJVButton, NJVCard, NJVInput, NJVSelect } from "../../components/core-component";
import { SvgCircleOneFill, SvgCircleTwoFill } from "../../components/custom-svgIcon";
import { FormLabel } from "../../components/general-component";
import Theme from "../../components/theme";
import { nrcCode_map, nrcNo_collection, payment_type_collection } from "../../core/constant";
import { NRCValidator, NumberOnlyValidator, PhoneNumberValidator } from "../../core/validator";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandlerNoAuth } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import TermsAndCondition from "../terms-and-condition/terms-and-condition";
const { Option } = Select

const stepLabelStyle = {
    fontWeight: '500',
    fontSize: 14
}
class SignUpPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentStep: 0,
            townshipCode_collection: [],
            township_collection: [],
            division_collection: [],
            township_collection_map: {},
            product_category_collection: [],
            nrcDivisionNumber: null,
            nrctownshipCode: null,
            nrcCitizen: null,
            showContentA: true,
            openSuccessModal: false,
            shipperInfo: {},
            businessInfo: {},
            is_loading: false,
            agreeTermsAndCondition: false,
            showTermsAndConditionModal: false,
            previewOpen: false,
            previewImage: '',
            fileList: [],
        }
        this.userInformationRef = React.createRef();
        this.businessInformationRef = React.createRef();

    }
    componentDidMount = () => {
        window.scrollTo(0, 0)
        this.fetchDivision()
        this.fetchProductCategory()
    }
    fetchDivision = async () => {
        try {
            const response = await ApiHandlerNoAuth({ url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                let divisionList = []
                let townshipMap = {}
                response.map(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                this.setState({
                    division_collection: divisionList,
                    township_collection_map: townshipMap
                })
            }
        } catch (error) {
        }
    }
    fetchProductCategory = async () => {
        try {
            const requestParams = {
                page: 0,
                size: 20
            }
            const response = await ApiHandlerNoAuth({ url: Api.product_category, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                this.setState({
                    product_category_collection: response.content
                })
            }
        } catch (error) {
        }
    }
    handleDivisionSelect = (value) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[value],
        })
        this.userInformationRef.current.setFieldsValue({ township: null });
    }

    handleOnCodeChange = (value) => {
        this.setState({
            townshipCode_collection: nrcCode_map[value],
            nrctownshipCode: null,
            nrcDivisionNumber: value
        })
    }

    handleOnTownShipCodeChange = (value) => {
        this.setState({
            nrctownshipCode: value
        })
    }
    accept = () => {
        this.setState({
            currentStep: 1
        })
    }

    completeUserInfo = (formData) => {

        this.setState({
            shipperInfo: formData,
            currentStep: 1
        })
    }

    getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
        });
    };

    handleChange = (value) => {
        this.setState({ fileList: value.fileList, businessInfo: { ...this.state.businessInfo, 'bankPhoto': value.file } });
        this.businessInformationRef.current?.setFieldsValue({ 'bankPhoto': value.file })
    };

    signUp = async (formData) => {
        const { nrcCitizen, nrcDivisionNumber, nrctownshipCode, shipperInfo, agreeTermsAndCondition } = this.state

        this.setState({
            is_loading: true
        })
        if (!agreeTermsAndCondition) {
            message.error("Please accept the terms and conditions first")
            this.setState({
                is_loading: false
            })
            return
        }

        try {
            if (nrcCitizen === null || nrcDivisionNumber === null || nrctownshipCode === null) {
                message.error("Please fill nrc information")
            } else if ((formData.primaryBank === 'AYA_PAY' || formData.primaryBank === 'KBZ_PAY' || formData.primaryBank === 'CB_PAY') && (formData.primaryAccounNumber.length < 6 && formData.primaryAccounNumber.length > 11)) {
                message.error("Invalid primary account number")
            } else if ((formData.primaryBank === 'AYA' || formData.primaryBank === 'KBZ' || formData.primaryBank === 'YOMA') && formData.primaryAccounNumber.length < 15) {
                message.error("Invalid primary account number")
            }
            else {
                const formDatas = new FormData()
                formDatas.append('firstName', shipperInfo.firstName);
                formDatas.append('lastName', shipperInfo.lastName);
                formDatas.append('phoneNumber', shipperInfo.phoneNumber);
                formDatas.append('accounts[0].loginId', shipperInfo.phoneNumber);
                formDatas.append('accounts[0].accountType', 'PHONE');
                formDatas.append('accounts[0].password', shipperInfo.password);
                formDatas.append('emailAddress', shipperInfo.email);
                formDatas.append('divisionId', shipperInfo.division)
                formDatas.append('township.id', shipperInfo.township);
                formDatas.append('address1', shipperInfo.address1);
                formDatas.append('business.businessName', formData.businessName)
                formDatas.append('business.businessOwnerNRC', nrcDivisionNumber + "/" + nrctownshipCode + "(" + nrcCitizen + ")" + formData.nrcNo)
                formDatas.append('business.facebookPageLink', formData.businessFacebookPageLink)
                formDatas.append('business.paymentNumber', formData.primaryAccounNumber)
                formDatas.append('business.ecommerceRegNumber', formData.ecommerceRegNumber)
                formDatas.append('business.accountOwnerName', formData.accountOwnerName)
                formDatas.append('business.paymentType', formData.primaryBank)
                formDatas.append('business.productCategory.id', formData.businessCategory)
                formDatas.append('bankBookImg', formData.bankPhoto)

                const response = await ApiHandlerNoAuth({ url: Api.signup, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formDatas })
                if (response && response.status === 200) {
                    this.setState({ openSuccessModal: true })
                }
            }

        } catch (error) {
            if (error.response && error.response.status === 500) {
                if (error.response.data) {
                    message.error(error.response.data.message)
                }
            }
        }
        this.setState({
            is_loading: false
        })
    }

    // gotoUserInfomation = () => {
    //     this.setState({ currentStep: 0 })
    // }

    handBusinessInput = (key, value) => {
        this.setState({
            businessInfo: { ...this.state.businessInfo, [key]: value }
        })
    }

    render() {
        const { currentStep, businessInfo, townshipCode_collection, division_collection, township_collection, nrctownshipCode, product_category_collection, nrcDivisionNumber, openSuccessModal, showTermsAndConditionModal, shipperInfo, is_loading, fileList, previewImage, previewOpen, agreeTermsAndCondition } = this.state
        
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        const steps = [
            {
                title: <span style={stepLabelStyle}>{i18next.t("personal_information")}</span>,
                icon: <SvgCircleOneFill width={30} height={30} color={Theme.colors.primary} />
            },
            {
                title: <span style={stepLabelStyle}>{i18next.t("business_information")}</span>,
                icon: <SvgCircleTwoFill width={30} height={30} color={Theme.colors.primary} />
            }
        ];



        return (
            <>
                <Modal
                    title={null}
                    style={{
                        top: 20,
                        bottom: 50
                    }}
                    centered
                    open={showTermsAndConditionModal}
                    onOk={() => this.setState({ showTermsAndConditionModal: false })}
                    onCancel={() => this.setState({ showTermsAndConditionModal: false })}
                    footer={null}
                    width={600}
                    className='custom-modal'
                >
                    <TermsAndCondition />
                </Modal>

                <Modal
                    title={null}
                    centered
                    open={openSuccessModal}
                    onOk={() => this.gotohome()}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <Lottie
                                style={{ height: '200px' }}
                                animationData={SentAnimation}
                                loop={true}
                                autoplay={true}
                            />
                            <div style={{ marginTop: 30, fontSize: 15, fontWeight: '500' }}>
                                Your registration request has been sent to Ninja Van Myanmar.
                                We'll connect with you within 24 hours for account approval.<br />
                                Have a pleasant day.</div>
                            <Link to={CustomPath.login}><NJVButton type='primary' style={{ marginTop: 10, backgroundColor: Theme.colors.primary }}>Ok</NJVButton></Link>
                        </div>
                    </div>
                </Modal>
                <Row>
                    <Col lg={7} md={7} sm={4} xs={1} />
                    <Col lg={10} md={10} sm={16} xs={22}>
                        <Steps
                            current={currentStep}
                            labelPlacement="vertical" items={steps} style={{ marginBottom: 20, marginTop: 20, color: Theme.colors.primary }} />
                        <NJVCard
                            style={{ paddingTop: 15, marginBottom: 30 }}
                            bordered={false}
                        >
                            {/* {
                                currentStep === 0 ?
                                    <Row>
                                        <Col span={24}>
                                            <TermsAndCondition />
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center', marginBottom: 20 }}>
                                            <NJVButton type="primary" style={{ width: 150, marginTop: 30, backgroundColor: Theme.colors.primary }} size="large" onClick={this.accept}>{i18next.t("agree")}</NJVButton>
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center', marginBottom: 20 }}>
                                            <Link to={CustomPath.login}>
                                                <span style={{ color: 'black' }}>{i18next.t("go_back_to_home")}</span>
                                            </Link>
                                        </Col>
                                    </Row>
                                    :
                                    <></>
                            } */}
                            {
                                currentStep === 0 ?
                                    <Form
                                        layout="vertical"
                                        onFinish={this.completeUserInfo}
                                        initialValues={shipperInfo}
                                        ref={this.userInformationRef}
                                    >
                                        <Row>
                                            <Col span={24}>
                                                <Row gutter={16} className="hide-owner-info">
                                                    <Col span={12}>
                                                        <Form.Item name="firstName" label={i18next.t("first_name")} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <NJVInput size="large" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item name="lastName" label={i18next.t("last_name")} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <NJVInput size="large" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item name="phoneNumber" label={i18next.t("phone_number")} size={'large'} rules={[{ required: true, validator: PhoneNumberValidator, message: 'Invalid phone number' }]}>
                                                            <NJVInput size="large" placeholder={"09"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item name="password" label={i18next.t("password")} size={'large'}
                                                            rules={[
                                                                { required: true, message: '*(requried)' },
                                                                { min: 8, message: 'Please enter at least 8' }

                                                            ]}>
                                                            <NJVInput
                                                                size="large" placeholder={"*********"}
                                                                type={"password"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item name="email" label={i18next.t("email")} size={'large'}
                                                            rules={[
                                                                {
                                                                    type: 'email',
                                                                    message: 'Please use valid email',
                                                                },
                                                                {
                                                                    required: true,
                                                                    message: '*(required)',
                                                                },]}>
                                                            <NJVInput size="large" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>

                                                        <Form.Item name="division" label={i18next.t("division_state")} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <NJVSelect
                                                                className="my-location-selector"
                                                                placeholder={i18next.t("division_state")}
                                                                onChange={this.handleDivisionSelect}
                                                                size="large"
                                                                options={
                                                                    division_collection && division_collection.map((division) => {
                                                                        return {
                                                                            value: division.id,
                                                                            label: division.name,
                                                                            type: 'division'
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item name="township" label={i18next.t("township_city")} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <NJVSelect
                                                                className="my-location-selector"
                                                                placeholder={i18next.t("township_city")}
                                                                onChange={this.divisionSelectorChange}
                                                                size="large"
                                                                options={
                                                                    township_collection && township_collection.map((township) => {
                                                                        return {
                                                                            value: township.id,
                                                                            label: township.name,
                                                                            type: 'division'
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item name="address1" label={i18next.t("address")} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <NJVInput
                                                                style={{ height: 60 }} placeholder={i18next.t("address")} showCount maxLength={180} istextareainput="true" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                                                        {
                                                            currentStep > 0 && (
                                                                <NJVButton size="large" type="text" onClick={() => this.setState({ currentStep: currentStep - 1 })} style={{ width: 150, marginRight: 10 }}>{i18next.t("previous")}</NJVButton>
                                                            )
                                                        }
                                                        <NJVButton size="large" type="primary" htmlType="submit" style={{ backgroundColor: Theme.colors.primary, width: 150 }}>{i18next.t("continue")}</NJVButton>
                                                    </Col>
                                                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginBottom: 20, gap: 10 }}>
                                                        <Typography.Text>{i18next.t("have_account")}</Typography.Text>
                                                        <Link to={CustomPath.login}>Login</Link>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>
                                    :
                                    <></>
                            }

                            {
                                currentStep === 1 ?
                                    <Form
                                        layout="vertical"
                                        onFinish={this.signUp}
                                        initialValues={businessInfo}
                                        ref={this.businessInformationRef}
                                    >
                                        <Row gutter={[16, 16]}>
                                            <Col span={24}>
                                                <Form.Item name="businessName" label={i18next.t("business_name")} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <NJVInput size="large" onChange={(event) => this.handBusinessInput('businessName', event.target.value)} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="ecommerceRegNumber" label={i18next.t("reg_number")} size={'large'}>
                                                    <NJVInput size="large" onChange={(event) => this.handBusinessInput('ecommerceRegNumber', event.target.value)} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="businessFacebookPageLink" label={i18next.t("business_fb_page_link")} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <NJVInput size="large" placeholder={"https://www.facebook.com/yourbusinessname"} onChange={(event) => this.handBusinessInput('businessFacebookPageLink', event.target.value)} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <FormLabel label={i18next.t("business_owner_nrc")} />
                                                    </Col>
                                                    <Col span={4}>
                                                        <NJVSelect
                                                            className="my-location-selector"
                                                            style={{ width: '100%' }}
                                                            placeholder={"1"}
                                                            onChange={this.handleOnCodeChange}
                                                            size="large"
                                                            value={nrcDivisionNumber}
                                                            options={nrcNo_collection}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <NJVSelect
                                                            className="my-location-selector"
                                                            style={{ width: '100%' }}
                                                            onChange={this.handleOnTownShipCodeChange}
                                                            value={nrctownshipCode}
                                                            size="large"
                                                            options={
                                                                townshipCode_collection && townshipCode_collection.map((code) => {
                                                                    return {
                                                                        key: code.id,
                                                                        value: code.value,
                                                                        label: code.value,
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </Col>
                                                    <Col span={4}>
                                                        <NJVSelect
                                                            className="my-location-selector"
                                                            style={{ width: '100%' }}
                                                            placeholder={"N"}
                                                            onChange={(value) => this.setState({ nrcCitizen: value })}
                                                            size="large"
                                                        >
                                                            <Option value={"N"}>N</Option>
                                                            <Option value={"F"}>F</Option>
                                                            <Option value={"P"}>P</Option>
                                                            <Option value={"T"}>T</Option>
                                                            <Option value={"E"}>E</Option>
                                                        </NJVSelect>
                                                    </Col>
                                                    <Col span={10}>
                                                        <Form.Item name="nrcNo" size={'large'}
                                                            rules={[
                                                                { validator: NRCValidator }
                                                            ]}>
                                                            <NJVInput size="large" onChange={(event) => this.handBusinessInput('nrcNo', event.target.value)} />
                                                        </Form.Item>
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="businessCategory" label={i18next.t("business_category")} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <NJVSelect
                                                        className="my-location-selector"
                                                        placeholder={"Choose Business Category"}
                                                        onChange={(value) => this.handBusinessInput('businessCategory', value)}
                                                        size="large"
                                                        options={
                                                            product_category_collection && product_category_collection.map(category => {
                                                                return {
                                                                    value: category.id,
                                                                    label: category.name,
                                                                }
                                                            })
                                                        }
                                                    >

                                                    </NJVSelect>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="accountOwnerName" label={i18next.t("account_owner_name")} size={'large'} rules={[{ required: true }]}>
                                                    <NJVInput size="large" onChange={(event) => this.handBusinessInput('accountOwnerName', event.target.value)} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="primaryBank" label={i18next.t("primary_payment")} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <NJVSelect
                                                        className="my-location-selector"
                                                        placeholder={i18next.t("primary_payment")}
                                                        onChange={(value) => this.handBusinessInput('primaryBank', value)}
                                                        size="large"
                                                        options={
                                                            payment_type_collection && payment_type_collection.map((payment) => {
                                                                return {
                                                                    value: payment.key,
                                                                    label: payment.value,
                                                                    type: 'primaryPayment'
                                                                }
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="primaryAccounNumber" label={i18next.t("primary_account_number")} size={'large'} rules={[{ required: true, validator: NumberOnlyValidator }]}>
                                                    <NJVInput size="large" placeholder={"xxx-xxx-xxx"} onChange={(event) => this.handBusinessInput('primaryAccounNumber', event.target.value)} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="bankPhoto" label={i18next.t("bank_acc_photo")}
                                                    // valuePropName="fileList" getValueFromEvent={({ fileList }) => fileList}
                                                    rules={[{ required: true, message: 'Photo is required!' }]}
                                                    style={{ textAlign: 'center' }}>
                                                    <Upload
                                                        listType="picture-card"
                                                        fileList={fileList}
                                                        onPreview={this.handlePreview}
                                                        onChange={this.handleChange}
                                                        maxCount={1}
                                                        accept={["image/png, image/jpeg"]}
                                                        beforeUpload={() => false}
                                                    >
                                                        {fileList.length >= 1 ? null : uploadButton}
                                                    </Upload>
                                                    {previewImage && (
                                                        <Image
                                                            wrapperStyle={{ display: 'none' }}
                                                            preview={{
                                                                visible: previewOpen,
                                                                onVisibleChange: (visible) => this.setState({ previewOpen: visible }),
                                                                afterOpenChange: (visible) => !visible && this.setState({ previewImage: '' }),
                                                            }}
                                                            src={previewImage}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <ConfigProvider theme={{
                                                    token: {
                                                        colorPrimary: Theme.colors.primary
                                                    },
                                                }}>
                                                    <Checkbox
                                                    checked={agreeTermsAndCondition}
                                                        onChange={(event) => this.setState({ agreeTermsAndCondition: event.target.checked })} />
                                                </ConfigProvider>

                                                <span
                                                    style={{
                                                        marginLeft: 5,
                                                        cursor: 'pointer',
                                                        color: Theme.colors.primary,
                                                        fontWeight: '600'
                                                    }}
                                                    onClick={() => this.setState({ showTermsAndConditionModal: true })}
                                                >
                                                    I have read and agreed to the Terms and Conditions*
                                                </span>

                                            </Col>
                                            <Col span={24}>
                                                <Form.Item style={{ textAlign: 'center' }}>
                                                    {
                                                        currentStep > 0 && (
                                                            <NJVButton type="text" size="large" style={{ marginRight: 10, width: 150 }} onClick={() => this.setState({ currentStep: currentStep - 1 })}>{i18next.t("previous")}</NJVButton>
                                                        )
                                                    }
                                                    <NJVButton type="primary" size="large" htmlType="submit" style={{ backgroundColor: Theme.colors.primary, width: 150 }} loading={is_loading}>{i18next.t("register")}</NJVButton>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                    :
                                    <></>
                            }


                        </NJVCard>
                    </Col >
                </Row >
            </>
        )
    }
}
export default compose(withRouter)(SignUpPage)