import { PlusOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Col, Image, Modal, Row, Select, Skeleton, Steps, Tag, Upload, message } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import Lottie from "lottie-react";
import React from "react";
import ReactToPrint from "react-to-print";
import { NJVButton, NJVCard, NJVInput, NJVSelect, NJVSpacer } from "../../components/core-component";
import { SvgCheck, SvgCustomerServiceFill, SvgOrderIncomplete } from "../../components/custom-svgIcon";
import Theme, { Colors } from "../../components/theme";
import Api from "../../network/api";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

import IssueCloseAnimation from '../../animate-lottie/lottie-issue-close.json';
import IssuePendingAnimation from '../../animate-lottie/lottie-issue-pending.json';
import IssueCompleteAnimation from '../../animate-lottie/lottie-success.json';
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import AirWayBillSlip from "../awb/airway-bill-slip";
import { Link } from "react-router-dom";

const containerW = '3in'
const containerH = '4in'

const PENDING_PICK_UP = 'PENDING_PICK_UP'
// const SUCCESSFUL_PICKUP = 'SUCCESSFUL_PICKUP'
// const SUCCESSFUL_DELIVERY = 'SUCCESSFUL_DELIVERY'
// const ARRIVED_AT_SORTING_HUB = 'ARRIVED_AT_SORTING_HUB'
// const EN_ROUTE_TO_SORTING_HUB = 'EN_ROUTE_TO_SORTING_HUB'
// const ON_VEHICLE_FOR_DELIVERY = 'ON_VEHICLE_FOR_DELIVERY'
// const COMPLETED = 'COMPLETED'
// const PENDING_RESCHEDULE = 'PENDING_RESCHEDULE'
// const RETURNED_TO_SENDER = 'RETURNED_TO_SENDER'
const CANCELLED = 'CANCELLED'

// const eventMap = {
//     'PENDING_PICK_UP': 0,
//     'SUCCESSFUL_PICKUP': 1,
//     'ARRIVED_AT_SORTING_HUB': 2,
//     'EN_ROUTE_TO_SORTING_HUB': 3,
//     'ON_VEHICLE_FOR_DELIVERY': 4,
//     'SUCCESSFUL_DELIVERY': 5,
//     'COMPLETED': 6,
//     'PENDING_RESCHEDULE': 7,
//     'RETURNED_TO_SENDER': 8,
//     'CANCELLED': 9
// }

const { Option } = Select

const NJVLabel = ({ label }) => {
    return (
        <>
            <span style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</span>
            <br />
        </>)

}

const NJVValue = ({ value }) => {
    return (
        <>
            <span style={{ fontSize: 16, fontWeight: '500', color: 'black' }}>{value}</span>
            <br />
        </>)
}

const StepTitileStyle = ({ title, date, event }) => {
    return (<>
        <>
            {
                date ?
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                            <span>{title}</span>
                            <div><Tag>{date}</Tag></div>
                        </div>

                        {
                            event.deliveryException ?
                                <>
                                    {
                                        event.deliveryException.failure_reason ?
                                            <div style={{ color: 'red' }}>Fail Reason : {event.deliveryException.failure_reason}</div>
                                            :
                                            <></>
                                    }


                                    {
                                        event.deliveryException.proof && event.deliveryException.proof.image_uris ?
                                            <div style={{ display: 'flex' }}>
                                                {
                                                    event.deliveryException.proof.image_uris.map(image =>
                                                        <div style={{ marginLeft: 5 }}>
                                                            <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            :
                                            <></>
                                    }
                                </>

                                :
                                <></>
                        }
                        {
                            event.deliveryInformation ?

                                <div style={{ display: 'flex' }}>
                                    {
                                        event.deliveryInformation.proof && event.deliveryInformation.proof.image_uris ?
                                            <>
                                                {
                                                    event.deliveryInformation.proof.image_uris.map(image =>
                                                        <div style={{ marginLeft: 5 }}>
                                                            <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                        </div>
                                                    )
                                                }
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        event.deliveryInformation.proof && event.deliveryInformation.proof.signature_uri ?
                                            <div style={{ marginLeft: 5 }}>
                                                <Image src={event.deliveryInformation.proof.signature_uri} width={50} height={50} style={{ borderRadius: 6, backgroundColor: 'white' }} />
                                            </div>
                                            :
                                            <></>

                                    }
                                </div>
                                :
                                <></>
                        }
                        {
                            event.pickupException ?
                                <>
                                    {
                                        event.pickupException.failure_reason ?
                                            <div style={{ color: 'red' }}>Fail Reason : {event.pickupException.failure_reason}</div>
                                            :
                                            <></>
                                    }
                                </>

                                :
                                <></>
                        }
                    </div >
                    :
                    <>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}><span>{title}</span></div>
                    </>
            }
        </>
    </>)
}

const category = [{ label: "Request Support", value: "Request Support", key: 'request_support' }, { label: "Complaint Issue", value: "Complaint Issue", key: 'complaint_issue' }]
const subCategory = {
    "Request Support": [
        { label: "Return to Sender", value: "Return to Sender", key: 'ticket_return_to_sender' },
        { label: "Bus Gate Pickup", value: "Bus Gate Pickup", key: 'ticket_bus_gate_pickup' },
        { label: "Change Phone Number", value: "Change Phone Number", key: 'ticket_change_phone_number' },
        { label: "Change Address", value: "Change Address", key: 'ticket_change_address' },
        { label: "Change COD Amount", value: "Change COD Amount", key: 'ticket_change_cod_amount' },
        { label: "Change Delivery Date", value: "Change Delivery Date", key: 'ticket_delivery_date' },
        { label: "Pending Credit Payment", value: "Pending Credit Payment", key: 'ticket_credit_payment' }
    ],
    "Complaint Issue": [
        { label: "Remittance Issue", value: "Remittance Issue", key: 'ticket_remittance_issue' },
        { label: "Parcel Loss", value: "Parcel Loss", key: 'ticket_parcel_loss' },
        { label: "Parcel Damage", value: "Parcel Damage", key: 'ticket_parcel_damage' },
        { label: "Pick Up Issue", value: "Pick Up Issue", key: 'ticket_pickup_issue' },
        { label: "Delivery Lead Time Issue", value: "Delivery Lead Time Issue", key: 'ticket_delivery_lead_time_issue' },
        { label: "Bus Gate Vouchers", value: "Bus Gate Vouchers", key: 'ticket_bus_gate_vouchers' },
        { label: "KG/CM Issue", value: "KG/CM Issue", key: 'ticket_kg_cm_issue' },
        { label: "Service Attitude", value: "Service Attitude", key: 'ticket_service_attitude' }
    ]
}

class OrderDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            order_object: props.location.state?.data || {},
            data: {},
            orderEvent: [],
            currentStep: 0,
            orderSteps: [],
            sub_category_collection: [],
            fileList: [],
            pending_reschedule: null,
            isDataFetching: false,
            viewPhoto: false,
            showInfoModal: false,
            showOrderCancelConfirmModal: false,
            isCancelling: false,
            showIssueProgressingModal: false,
            isFormSubmittedSuccess: false,
            is_submit_request: false,
            form_category: null,
            form_sub_category: null,
            form_reason: null,

            confirming_tracking_number: false
        }
        this.inputRef = React.createRef();

    }

    componentDidMount() {
        this.fetchData()
        this.fetchOrderEvent()
    }

    fetchData = async () => {
        const { order_object } = this.state
        try {
            const requestParams = {
                trackingNumber: order_object.id
            }
            this.setState({
                isDataFetching: true
            })
            const response = await ApiHandler({ url: Api.order_get_by_tracking_number, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let data = response
                let fromAddress = data.fromAddress
                let toAddress = data.toAddress
                if (data && data.fromTownship) {
                    fromAddress = fromAddress + "," + data.fromTownship.name
                    if (data.fromTownship.division) {
                        fromAddress = fromAddress + "," + data.fromTownship.division.name
                    }
                }
                if (data && data.toTownship) {
                    toAddress = toAddress + "," + data.toTownship.name
                    if (data.toTownship.division) {
                        toAddress = toAddress + "," + data.toTownship.division.name
                    }
                }
                data = { ...data, senderAddress: fromAddress, recipientAddress: toAddress }

                if (data.createdDate != null) {
                    const createdDate = dayjs(data.createdDate, 'YYYY-MM-DD HH:mm A');
                    if (createdDate) {
                        data = { ...data, 'createdDate': createdDate.format('YYYY-MMM-DD hh:mm A') }
                    }
                }

                this.setState({
                    data: data
                })
            }
        } catch (error) {

        }
        this.setState({
            isDataFetching: false,
        })
    }

    fetchOrderEvent = async () => {
        await ApiHandler({ url: Api.order_event, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams: { trackingNumber: this.state.order_object.id } })
            .then((response) => {

                let currentStep = 0
                let status = PENDING_PICK_UP
                let timeMap = {}
                let steps = []
                let pending_reschedule = null

                response?.map((orderEvent, index) => {
                    status = orderEvent.orderStatus
                    let formatedEventTime = ''
                    if (orderEvent.timestamp) {
                        const eventTime = dayjs(orderEvent.timestamp, 'YYYY-MM-DD HH:mm:ss');
                        if (eventTime) {
                            timeMap[orderEvent.orderStatus] = eventTime.format('YYYY-MMM-DD hh:mm A')
                            formatedEventTime = eventTime.format('YYYY-MMM-DD hh:mm A')
                        }
                    }

                    if (status === CANCELLED) {
                        steps.push(
                            {
                                key: index + 1,
                                title: <StepTitileStyle title={orderEvent.status} date={formatedEventTime} event={orderEvent} />,
                                icon: <SvgOrderIncomplete width={25} height={25} color={'red'} />
                            }
                        )
                    } else {
                        steps.push(
                            {
                                key: index + 1,
                                title: <StepTitileStyle title={orderEvent.status} date={formatedEventTime} event={orderEvent} />,
                                icon: <SvgCheck width={25} height={25} color={'green'} />
                            }
                        )
                    }

                    if (orderEvent.orderStatus === 'PENDING_RESCHEDULE') {
                        pending_reschedule = orderEvent.comments
                    }

                    currentStep = index + 1
                })
                this.setState({
                    pending_reschedule,
                    orderEvent: response.orderEvents ? response.orderEvents : [],
                    currentStep: currentStep,
                    orderSteps: steps
                })
            }).catch(() => {

            })
    }

    cancelOrder = async (tracking_number) => {
        this.setState({
            isCancelling: true
        })
        try {
            await ApiHandler({ url: Api.order, method: HTTP_METHOD.DELETE, mediaType: MEDIA_TYPE.JSON, specificId: tracking_number, curstomMessage: "Your order has been cancelled." })
        } catch (error) {
        }
        this.setState({
            showOrderCancelConfirmModal: false,
            isCancelling: false
        })

        this.fetchData()
    }

    onchangeCategory = (value) => {
        this.setState({
            form_sub_category: null,
            form_category: value,
            sub_category_collection: subCategory[value]
        })
    }

    handleChange = ({ fileList: newFileList }) => this.setState({
        fileList: newFileList
    })

    submitRequestSupport = async () => {

        const { form_category, form_reason, form_sub_category, fileList, data } = this.state


        if (form_category === null || form_category === '') {
            message.error("Please select category")
            return
        } else if (form_sub_category === null || form_sub_category === '') {
            message.error("Please select sub category")
            return
        } else if (form_reason === null || form_reason === '') {
            message.error("Please fill reason")
            return
        }



        if (form_sub_category === 'Bus Gate Pickup' && fileList[0] === undefined) {
            message.error("Please select photo")
            return
        }
        if (form_sub_category === 'Parcel Damage' && fileList[0] === undefined) {
            message.error("Please select photo")
            return
        }
        this.setState({ is_submit_request: true })
        try {
            let formData = new FormData();
            formData.append('trackingNumber', data.trackingNumber)
            formData.append('category', form_category)
            formData.append('subCategory', form_sub_category)
            formData.append('reason', form_reason)
            formData.append('type', 'NORMAL_ORDER')
            if (fileList && fileList[0] && fileList[0].originFileObj) {
                formData.append('imageFile', fileList[0].originFileObj)
            }
            const response = await ApiHandler({ url: Api.order_issue, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData })

            if (response && response.data) {
                const orderIssue = response.data
                let orderDetail = {
                    ...orderIssue.order, issue: orderIssue
                }

                this.setState({
                    isFormSubmittedSuccess: true,
                    data: orderDetail,
                    is_submit_request: false
                })
            }

        } catch (error) {

        }
    }

    searchTrackingNumber = async () => {
        const { data } = this.state
        this.setState({ confirming_tracking_number: true })
        try {
            const requestParams = {
                trackingNumber: data.trackingNumber
            }
            const response = await ApiHandler({ url: Api.confirm_rom_order, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                this.props.navigate(
                    CustomPath.rom_order,
                    { state: { data: response } }
                );
            }
        } catch (error) {
            message.error("Can not request this time")
        }
        this.setState({ confirming_tracking_number: false })
    }

    render() {
        const { businessName } = this.props
        const { data, isDataFetching, currentStep, orderSteps, showInfoModal, fileList, showIssueProgressingModal, showOrderCancelConfirmModal, isCancelling, order_object, sub_category_collection, form_category, form_sub_category, form_reason, isFormSubmittedSuccess, is_submit_request, confirming_tracking_number, pending_reschedule } = this.state

        const customSizeStyles = {
            width: containerW,
            height: containerH,
        };
        return (
            <>
                <Modal
                    title={i18next.t("request_support")}
                    centered
                    open={showInfoModal}
                    onCancel={() => this.setState({ showInfoModal: false, form_category: null, form_sub_category: null, form_reason: null, fileList: [] })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    {
                        isFormSubmittedSuccess ?
                            <div style={{ textAlign: 'center' }}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={IssuePendingAnimation}
                                    loop={true}
                                    autoplay={true}
                                />
                                <div style={{ margin: '15px 0px' }}>
                                    {i18next.t("lb_ticket_successfully_submitted")}
                                </div>
                                <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.setState({ isFormSubmittedSuccess: false, showInfoModal: false })}>Close</NJVButton>
                            </div>
                            :
                            <>
                                <NJVLabel label={i18next.t("category")} />
                                <NJVSelect bgcolor={Theme.colors.secondary}
                                    value={form_category}
                                    onChange={(value) => this.onchangeCategory(value)}
                                    size="large"
                                    allowClear={true}
                                    style={{ width: '100%', marginBottom: 20, marginTop: 5 }}
                                    className='my-location-selector'
                                    placeholder={i18next.t("select_category")}
                                >
                                    {
                                        category && category.map((cat) => {
                                            return <Option value={cat.value}>{i18next.t(cat.key)}</Option>
                                        })
                                    }
                                </NJVSelect>
                                <NJVLabel label={i18next.t("sub_category")} />
                                <NJVSelect bgcolor={Theme.colors.secondary}
                                    value={form_sub_category}
                                    onChange={(value) => this.setState({ form_sub_category: value })}
                                    size="large"
                                    allowClear={true}
                                    style={{ width: '100%', marginBottom: 20, marginTop: 5 }}
                                    className='my-location-selector'
                                    placeholder={i18next.t("select_sub_category")}
                                >
                                    {
                                        sub_category_collection && sub_category_collection.map((subCategory) => {
                                            return <Option value={subCategory.value}>{i18next.t(subCategory.key)}</Option>
                                        })
                                    }
                                </NJVSelect>
                                <NJVLabel label={i18next.t("reason")} />
                                <NJVInput value={form_reason} bgcolor={Theme.colors.secondary} onChange={(e) => this.setState({ form_reason: e.target.value })} placeholder={i18next.t("enter_reason")} style={{ marginBottom: 20, marginTop: 5 }} isTextAreaInput="true" />

                                <>
                                    <NJVLabel label={i18next.t("photo")} />
                                    <Upload
                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={this.handlePreview}
                                        onChange={this.handleChange}
                                    >
                                        {fileList.length >= 1 ? null :
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                            </div>}
                                    </Upload>
                                </>
                                <div style={{ textAlign: 'right' }}>
                                    <NJVButton type="primary" onClick={() => this.submitRequestSupport()} style={{ backgroundColor: Theme.colors.primary }} loading={is_submit_request}>{i18next.t("request")}</NJVButton>
                                </div>
                            </>
                    }
                </Modal>
                <Modal
                    title={"Confirmation of Order Cancellation"}
                    centered
                    open={showOrderCancelConfirmModal}
                    onCancel={() => this.setState({ showOrderCancelConfirmModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    <p>Are you sure you want to cancel this order?</p>
                    <NJVButton type='primary' loading={isCancelling} onClick={() => this.cancelOrder(data.trackingNumber)}>Cancel Now</NJVButton>
                </Modal>

                <Modal
                    title={"Issue Status"}
                    centered
                    open={showIssueProgressingModal}
                    onCancel={() => this.setState({ showOrderCancelConfirmModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    {
                        data && data.issue && data.issue.status === 'PENDING' ?
                            <div style={{ textAlign: 'center' }}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={IssuePendingAnimation}
                                    loop={true}
                                    autoplay={true}
                                />
                                <div style={{ margin: '15px 0px' }}>
                                    {i18next.t("lb_ticket_processing")}
                                </div>
                                <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.setState({ showIssueProgressingModal: false })}>Close</NJVButton>
                            </div>
                            :
                            <></>
                    }
                    {
                        data && data.issue && data.issue.status === 'CLOSED' ?
                            <div style={{ textAlign: 'center' }}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={IssueCloseAnimation}
                                    loop={true}
                                    autoplay={true}
                                />
                                <div style={{ margin: '15px 0px' }}>
                                    {i18next.t("lb_ticket_closed")}
                                </div>
                                <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.setState({ showIssueProgressingModal: false })}>Close</NJVButton>
                            </div>

                            :
                            <></>
                    }
                    {
                        data && data.issue && data.issue.status === 'COMPLETE' ?
                            <div style={{ textAlign: 'center' }}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={IssueCompleteAnimation}
                                    loop={true}
                                    autoplay={true}
                                />
                                <div style={{ margin: '15px 0px' }}>
                                    {i18next.t("lb_ticket_resolve")}
                                </div>
                                <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.setState({ showIssueProgressingModal: false })}>Close</NJVButton>
                            </div>
                            :
                            <></>
                    }
                </Modal>
                <Row style={{ marginBottom: 200, paddingBottom: 200 }}>
                    <Col lg={6} md={6} xs={1} sm={1} />
                    <Col lg={12} md={12} xs={22} sm={22}>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <h2>{i18next.t("parcel_detail")}</h2>
                                <>
                                    {
                                        data !== null && data !== undefined ?
                                            <>
                                                {
                                                    data.orderStatus === 'CANCEL' ?
                                                        <></>
                                                        :
                                                        <NJVButton type="primary" shape="circle" onClick={() => {
                                                            this.setState({ showInfoModal: true })
                                                        }} style={{ marginLeft: 10 }}>
                                                            <SvgCustomerServiceFill width={18} height={18} color={'#fff'} />
                                                        </NJVButton>
                                                }
                                            </>
                                            :
                                            <></>
                                    }
                                </>

                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                {
                                    order_object.isFromOrderHistory ?
                                        <Link to={order_object.route} relative="path">
                                            <NJVButton type="primary">{i18next.t('back')}</NJVButton>
                                        </Link>
                                        :
                                        <NJVButton type="primary" onClick={() => this.props.navigate(CustomPath.notification)}>{i18next.t('back')}</NJVButton>
                                }

                            </Col>
                        </Row>
                        <NJVSpacer height={20} />
                        {
                            isDataFetching ?
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <Skeleton active />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    {
                                        data ?
                                            <div>
                                                {
                                                    data.orderStatus === 'CANCELLED' ?
                                                        <>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <NJVCard style={{ backgroundColor: 'red' }}>
                                                                        <span style={{ fontSize: 13, fontWeight: '600', color: 'white' }}>This order has been cancelled</span>
                                                                    </NJVCard>
                                                                </Col>
                                                            </Row>
                                                            <NJVSpacer height={20} />
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                                <Row>
                                                    <Col span={24}>
                                                        <NJVCard>
                                                            <NJVLabel label={i18next.t("tracking_number")} />
                                                            <NJVValue value={data.trackingNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.fromAddress && data.fromTownship ?
                                                                    <>
                                                                        <NJVLabel label={i18next.t("pickup_address")} />
                                                                        <NJVValue value={`${data.fromAddress}, ${data.fromTownship.name}, ${data.fromTownship.division.name} `} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }


                                                        </NJVCard>
                                                    </Col>
                                                </Row>
                                                <NJVSpacer height={20} />
                                                <Row>
                                                    <Col span={24}>
                                                        <NJVCard>
                                                            <NJVLabel label={i18next.t("receiver_name")} />
                                                            <NJVValue value={`${data.recipientName}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("receiver_phone_number")} />
                                                            <NJVValue value={data.recipientPhoneNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.toAddress && data.toTownship ?
                                                                    <>
                                                                        <NJVLabel label={i18next.t("receiver_address")} />
                                                                        <NJVValue value={`${data.toAddress}, ${data.toTownship.name}, ${data.toTownship.division.name}`} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </NJVCard>
                                                    </Col>
                                                </Row>
                                                <NJVSpacer height={20} />
                                                <Row>
                                                    <Col span={24}>
                                                        <NJVCard>
                                                            <NJVSpacer height={30} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("pickup_time")} />
                                                            <NJVValue value={`${data.pickupDate}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("order_type")} />
                                                            <NJVValue value={`${data.orderType}`} />
                                                            {
                                                                data.paymentOption ?
                                                                    <>
                                                                        <NJVSpacer height={30} />
                                                                        <NJVLabel label={i18next.t("payment_option")} />
                                                                        <NJVValue value={`${data.paymentOption}`} />
                                                                    </>
                                                                    :
                                                                    <></>

                                                            }
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="Note" />
                                                            <NJVValue value={`${data.note ? data.note : '------'}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("cod")} />
                                                            {
                                                                data.codAmount ?
                                                                    <NJVValue value={`${data.codAmount} MMK`} />
                                                                    :
                                                                    <NJVValue value={`0 MMK`} />
                                                            }

                                                            <NJVSpacer height={30} />

                                                            {
                                                                data && data.updateDeliveryCharges ?
                                                                    <>
                                                                        <NJVLabel label="Updated Delivery Charges" />
                                                                        <NJVValue value={`${data.updateDeliveryCharges}`} />
                                                                        <NJVSpacer height={30} />
                                                                    </> :
                                                                    <></>
                                                            }
                                                            {
                                                                data && data.updateCodAmount ?
                                                                    <>
                                                                        <NJVLabel label="Updated Cod Amount" />
                                                                        <NJVValue value={`${data.updateCodAmount}`} />
                                                                        <NJVSpacer height={30} />
                                                                    </> :
                                                                    <></>
                                                            }
                                                            <NJVLabel label={i18next.t("status")} />
                                                            <NJVSpacer height={10} />
                                                            <Steps
                                                                className="step-full-width-title"
                                                                size="small"
                                                                current={currentStep}
                                                                direction="vertical"
                                                                items={orderSteps}
                                                            />
                                                            {
                                                                pending_reschedule ?
                                                                    <>
                                                                        <NJVSpacer height={30} />
                                                                        <NJVLabel label={i18next.t("fail_reason")} />
                                                                        <NJVValue value={pending_reschedule} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }


                                                            <NJVSpacer height={30} />
                                                            <div style={{ display: 'flex' }}>
                                                                <di style={{ marginRight: 10 }}>
                                                                    <NJVLabel label="Product Image" />
                                                                    {
                                                                        data.productImageUrl ?

                                                                            <Image
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    border: '1px solid grey'
                                                                                }}
                                                                                onClick={() => this.setState({ viewPhoto: true })}
                                                                                src={data.productImageUrl}
                                                                                // preview={false}
                                                                                width={200}
                                                                            />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }
                                                                </di>
                                                                <div>
                                                                    <NJVLabel label="Package Image" />
                                                                    {
                                                                        data.packageImageUrl ?
                                                                            <Image
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    border: '1px solid grey'
                                                                                }}
                                                                                onClick={() => this.setState({ viewPhoto: true })}
                                                                                src={data.productImageUrl}
                                                                                // preview={false}
                                                                                width={200}
                                                                            />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }


                                                                </div>
                                                            </div>
                                                            <br />
                                                            {
                                                                data.orderType === 'Bus Gate' ?
                                                                    <>
                                                                        <NJVLabel label="Bus Gate Voucher" />
                                                                        {
                                                                            data.busGateVoucherImagePath ?
                                                                                <Image
                                                                                    style={{
                                                                                        marginTop: 5,
                                                                                        borderRadius: 9,
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => this.setState({ viewPhoto: true })}
                                                                                    src={data.busGateVoucherImagePath}
                                                                                    // preview={false}
                                                                                    width={200}
                                                                                />
                                                                                :
                                                                                <div
                                                                                    style={{
                                                                                        marginTop: 5,
                                                                                        borderRadius: 9,
                                                                                        cursor: 'pointer',
                                                                                        padding: 10,
                                                                                        backgroundColor: '#f1f1f1',
                                                                                        border: '1px solid #f1f1f1',
                                                                                        width: 200,
                                                                                        height: 200,
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center'
                                                                                    }}
                                                                                >
                                                                                    <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                                </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }


                                                        </NJVCard>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <div style={{ height: 20 }}>

                                                            <div
                                                                key={2}
                                                                ref={(el) => this.refComponent = el}
                                                                style={customSizeStyles}>
                                                                <AirWayBillSlip
                                                                    key={1}
                                                                    senderName={data.senderName}
                                                                    senderPhone={data.senderPhoneNumber}
                                                                    senderAddress={data.senderAddress}
                                                                    receiverName={data.recipientName}
                                                                    receiverPhone={data.recipientPhoneNumber}
                                                                    receiverAddress={data.recipientAddress}
                                                                    trackId={data.trackingNumber}
                                                                    createDate={data.createdDate}
                                                                    parcelAmount={data.parcelAmount}
                                                                    deliveryCharges={data.deliveryCharges}
                                                                    cod={data.codAmount}
                                                                    note={data.note}
                                                                    sortingCode={data.sortingCode}
                                                                />
                                                            </div>
                                                            <div style={{ textAlign: 'center' }}>
                                                                <ReactToPrint
                                                                    trigger={() => <NJVButton type="primary" style={{ backgroundColor: Colors.primary, width: 150 }}>Print</NJVButton>}
                                                                    content={() => this.refComponent}
                                                                />
                                                                <br />

                                                                {
                                                                    data.orderType === 'Home Delivery' && (data.orderStatus !== 'DELIVERED' || data.orderStatus !== 'CANCELED') ?
                                                                        <>
                                                                            <NJVSpacer height={50} />
                                                                            <NJVButton type="text" onClick={() => this.searchTrackingNumber()}>
                                                                                <span style={{ color: 'blue', fontWeight: '600' }} loading={confirming_tracking_number}>Request Parcel Exchange</span>
                                                                            </NJVButton>
                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                }
                                                                <br />
                                                                {
                                                                    data.orderStatus === 'PENDING_PICK_UP' ?
                                                                        <>
                                                                            <NJVSpacer height={50} />
                                                                            <NJVButton type="text" onClick={() => this.setState({ showOrderCancelConfirmModal: true })}>
                                                                                <span style={{ color: 'red', fontWeight: '600' }}>Cancel Order</span>
                                                                            </NJVButton>
                                                                            <NJVSpacer height={50} />
                                                                            <br />
                                                                            <br />
                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <NJVSpacer height={100} />
                                                </Row>

                                            </div>
                                            :
                                            <></>
                                    }
                                </>
                        }
                    </Col >

                </Row >
            </>
        )
    }

}

export default compose(withRouter)(OrderDetail)