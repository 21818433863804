import { createSlice } from '@reduxjs/toolkit'

export const LocationSlice = createSlice({
    name: 'location',
    initialState: {
        division_collection: [],
        township_collection: [],
        township_map: []
    },
    reducers: {
        updateDivisionList: (state, action) => {
            state.division_collection = action.payload
        },
        updateTownShipList: (state, action) => {
            state.township_map = action.payload
        },
        clearLocation: (state) => {
            state.division_collection = []
            state.township_collection = []
        }

    },
})

export const { updateDivisionList, updateTownShipList, clearLocation } = LocationSlice.actions
export const divisionCollection = (state) => state.location.division_collection
export const townshipCollection = (state) => state.location.township_collection
export const townShipMap = (state) => state.location.township_map
export default LocationSlice.reducer