import feedback from "../page/feedback/feedback";

const shipper = '/v1'
const CustomPath = {
    app: '/',

    dashboard: shipper + '/dashboard',
    profile: shipper + '/profile',

    notification: shipper + '/notification',
    notification_detail: shipper + '/notification/detail',

    order: shipper + '/order',
    order_create: shipper + '/order-create',
    order_return: shipper + '/order-return',
    order_update: shipper + '/order-update',
    order_history: shipper + '/order-history',
    order_detail: shipper + '/order-detail',
    order_detail_param: shipper + '/order-detail/:id',
    public_parcel_detail: '/public/tracking',
    pickup_call: shipper + '/order/add-order-data-to-sheet',
    order_create_fail_log: shipper + '/order/fail-logs',

    order_awb: shipper + '/awb',
    rom_order: shipper + '/rom',
    rts_order: shipper + '/rts-order',
    rts_order_detail: shipper + '/rts-order/detial',

    user_address: shipper + '/address',
    user_address_create: shipper + '/address/create',
    user_address_update: shipper + '/address/update',
    user_address_update_param: shipper + '/address/update/:id',

    airwaybill: shipper + '/air-way-bill',


    terms_and_condition: shipper + '/terms-and-condition',
    privacy_and_policy: shipper + '/privacy-and-policy',
    privacy_and_policy_public: '/public/privacy-and-policy',
    privacy_and_policy_public_driver : '/public/driver/privacy-and-policy',
    guide: shipper + '/guide',
    public_guide: '/public/guide',
    coverage: shipper + '/coverage',
    
    store: shipper + '/store',
    add_product: shipper + '/add-product',
    update_product: shipper + '/update-product',
    products: '/public/store',
    products_param: '/public/store/:uniqueId',
    cart_items_list: '/public/store/:uniqueId/cart-items',
    product_detail: '/public/store/:uniqueId/product-detail',

    single_order_result: shipper + '/single-order-detail',
    single_order_result_param: shipper + '/single-order-detail/:id',

    quick_voucher_customer_confirm: '/public/customer_voucher_confirm',
    quick_voucher_customer_confirm_param: '/public/customer_voucher_confirm/:voucherId',
    confirm_order: shipper + '/order/confirm_order',
    confirm_order_create: '/order/confirm_order/create',
    confirm_order_update: '/order/confirm_order/update',
    confirm_order_detail: '/order/confirm_order/detail',

    feedback: shipper + '/feedback',

    faq: shipper + '/faq',

    invoice: shipper + '/invoice',

    login: '/',
    logout: "/logout",
    signUp: "/signUp",
    forgot_password: "/forgot-password",
    delete_account: "/delete-account",

    payment_history: shipper + "/payment-history",

    printer_setting: shipper + '/printer-setting'
};
export default CustomPath



