
import { ConfigProvider, Layout } from 'antd';
import i18next from "i18next";
import cookies from "js-cookie";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './App.css';
import { currentSelectedMenu, updateCurrentRoute } from './redux/cookie-slice';

import AppContent from './components/layouts/app-content';
import AppHeader from './components/layouts/app-header';
import AppSidebar from './components/layouts/app-sidebar';


function App() {

      const logo = require('./asset/Logo.png');
      const qrImage = require('./asset/ninja_qr_image.png');
      const shipper_profile = require('./asset/shipper_profile.png');

      const dispatch = useDispatch();
      const location = useLocation();

      const [selected, setSelected] = useState(0)
      const [currentLanguage, setCurrentLanguage] = useState(false)
      const [collapsed, setCollapsed] = useState(false);

      useEffect(() => {
            dispatch(updateCurrentRoute(location.pathname))
            const currentLanCode = cookies.get("i18next") || "en"
            setCurrentLanguage(currentLanCode)
            i18next.changeLanguage(currentLanCode)
            document.body.style.zoom = "90%";
      }, [location.pathname, currentLanguage])

      const handleChangeLanguage = (currentLang) => {
            setCurrentLanguage(currentLang)
      }

      return (
            <ConfigProvider theme={{
                  components: {
                        Layout: {
                              bodyBg: "#ffffff",
                        },
                        Menu: {
                              itemBorderRadius: 0,
                              itemSelectedBg: '#c2002f',
                        },
                        Button: {
                              primaryShadow: 'none',
                              defaultShadow: 'none',
                        },
                  }
            }}>
                  <Layout className="shipper_layout">
                        <AppHeader logo={logo} qrImage={qrImage} shipper_profile={shipper_profile} currentLanguage={currentLanguage} handleChangeLanguage={handleChangeLanguage} collapsed={collapsed} setCollapsed={setCollapsed} selected={selected} setSelected={setSelected} />
                        <Layout>
                              <AppSidebar collapsed={collapsed} />
                              <AppContent />
                        </Layout>
                  </Layout>
            </ConfigProvider>
      );
}
export default App;

