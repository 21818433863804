import { PlusOutlined } from '@ant-design/icons';
import { compose } from "@reduxjs/toolkit";
import { Col, Image, Modal, Row, Skeleton, Table, Tabs, Upload, message } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import Lottie from 'lottie-react';
import React from "react";
import NoDataFoundAnimation from '../../animate-lottie/lottie-not-data-found.json';
import AyeBank from '../../asset/ayeBank.jpg';
import KBZPay from '../../asset/kbzPay.jpg';
import WavePay from '../../asset/wave_pay.jpg'
import { NJVButton, NJVCard, NJVDatePicker, NJVSelect } from "../../components/core-component";
import { SvgCheck, SvgExcelOutlined, SvgPdfOutlined, SvgPngOutlined } from "../../components/custom-svgIcon";
import { TitleLevel3 } from "../../components/general-component";
import Theme, { Colors } from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler, InvoiceDownloadByFormat } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class InvoicPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            totalPagination: 0,
            page: 1,
            pageSize: 20,
            last_invoice: null,
            filterData: {},
            isDataFetching: false,
            ispaymentFetching: false,
            isSendingScreenshot: false,
            isFilterAll: false,
            isFilterByDateRange: false,
            defaultFilter: 1,
            previewOpen: false,
            previewImage: '',
            fileList: [],
        }
    }

    componentDidMount() {
        this.fetchInvoice()
        this.fetchPayment()
    }

    fetchInvoice = async (pagiNumber) => {
        const { page, pageSize } = this.state
        try {
            this.setState({
                isDataFetching: true
            })

            const requestParams = {
                "page": pagiNumber ? pagiNumber - 1 : page - 1,
                "size": pageSize,
                "startDate": "",
                "endDate": "",
            }
            const response = await ApiHandler({ url: Api.shipper_invoice, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response && response.data && response.data.items) {
                this.setState({
                    data: response.data.items,
                    isFilterAll: true,
                    isFilterByDateRange: false,
                    last_invoice: response.data.items[0],
                    isDataFetching: false,
                    page: pagiNumber ? pagiNumber : 1,
                    totalPagination: response.data.totalRecords
                })
            } else if (response && !response.success) {
                this.setState({
                    isDataFetching: false,
                    isFilterAll: true,
                    isFilterByDateRange: false,
                })
            }

        } catch (error) {
            this.setState({
                isDataFetching: false,
                isFilterAll: true,
                isFilterByDateRange: false,
            })
        }
    }

    fetchPayment = async () => {
        this.setState({
            ispaymentFetching: true
        })
        try {
            const response = await ApiHandler({ url: Api.shipper_settlement_info, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                this.setState({
                    paymentInfo: response,
                    isPending: response.status === "PENDING" ? true : false,
                    ispaymentFetching: false
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    downloadInvoice = async (type, invoice) => {
        try {
            const param = {
                format: type
            }
            const requestBody = {
                "guid": invoice.guid
            }
            let contentType = 'application/pdf'
            await InvoiceDownloadByFormat(Api.invoice_download, requestBody, param, invoice.merchantName, this.props)

        } catch (error) {
        }
    }

    filterBySpecific = (value) => {
        this.setState({
            defaultFilter: value
        })
        if (value === 1) {
            this.fetchInvoice()
        } else if (value === 2) {
            const endDate = dayjs();
            const startDate = endDate.subtract(7, 'day');
            this.filterByDateRage(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'),)
        } else if (value === 3) {
            const endDate = dayjs();
            const startDate = endDate.subtract(30, 'day');
            this.filterByDateRage(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'),)
        }
    }

    filterByDateRage = async (startDate, endDate, pagiNumber) => {
        const { page, pageSize } = this.state
        try {
            this.setState({
                isDataFetching: true,
                filterData: { 'startDate': startDate, 'endDate': endDate },
            })
            const requestParams = {
                "page": pagiNumber ? pagiNumber - 1 : page - 1,
                "size": pageSize,
                "startDate": startDate,
                "endDate": endDate,

            }
            const response = await ApiHandler({ url: Api.shipper_invoice, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response && response.data && response.data.items) {
                this.setState({
                    data: response.data.items,
                    last_invoice: response.data.items[0],
                    isDataFetching: false,
                    isFilterAll: false,
                    page: pagiNumber ? pagiNumber : 1,
                    isFilterByDateRange: true,
                    totalPagination: response.data.totalRecords,
                })
            } else if (response && !response.success) {
                this.setState({
                    isDataFetching: false,
                    isFilterAll: true,
                    isFilterByDateRange: false,
                })
            }
        } catch (error) {
            this.setState({
                isDataFetching: false,
                isFilterAll: false,
                isFilterByDateRange: true,
            })
        }
    }

    search = () => {
        const { filterData } = this.state
        if (filterData) {
            if ((filterData.fromDate && !filterData.toDate) || (!filterData.fromDate && filterData.toDate)) {
                message.error("Invalid date")
                return
            }
            if (filterData.fromDate > filterData.toDate) {
                message.error("Invalid date")
                return
            }
            const startDate = filterData.fromDate.format("YYYY-MM-DD")
            const endDate = filterData.toDate.format("YYYY-MM-DD")
            this.setState({
                defaultFilter: null
            })
            this.filterByDateRage(startDate, endDate)
        }
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData: filterData
        })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        const { isFilterAll, isFilterByDateRange, filterData } = this.state
        if (isFilterAll) {
            this.fetchInvoice(pageNumber);
        } else if (isFilterByDateRange && filterData && filterData.startDate && filterData.endDate) {
            this.filterByDateRage(filterData.startDate, filterData.endDate, pageNumber)
        }

    };

    invoiceSection = () => {
        const { data, isDataFetching, totalPagination, page, pageSize, last_invoice, defaultFilter } = this.state

        const columns = [
            {
                title: <>{i18next.t("no")}</>,
                width: 30,
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
            },
            {
                title: 'Invoice Date',
                key: 'invoiceDate',
                render: (invoice) => {
                    let invoiceDate = ""
                    if (invoice && invoice.invoiceDate) {
                        const convertedInvoiceDate = dayjs(invoice.invoiceDate, 'YYYY-MM-DD');
                        if (convertedInvoiceDate) {
                            invoiceDate = convertedInvoiceDate.format('DD-MMM-YYYY')
                        }
                    }
                    return <span>{invoiceDate}</span>
                }
            }, {
                title: 'PDF',
                width: 50,
                render: (invoice) => (
                    <NJVButton size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('pdf', invoice)}><SvgPdfOutlined width={25} height={25} color={Colors.primary} /></NJVButton>
                ),
            }, {
                title: 'Image',
                width: 50,
                render: (invoice) => (
                    <NJVButton size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('png', invoice)}><SvgPngOutlined width={25} height={25} color={Colors.primary} /></NJVButton>
                ),
            }, {
                title: 'Excel',
                width: 50,
                render: (invoice) => (

                    <NJVButton size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('excel', invoice)}><SvgExcelOutlined width={25} height={25} color={Colors.primary} /></NJVButton>
                ),
            }
        ]

        return (
            <Row gutter={[16, 16]}>
                {
                    last_invoice ?
                        <Col span={24}>

                            <table style={{ marginBottom: 30 }}>
                                <tr style={{ fontSize: 17, fontWeight: '600', height: 30 }}>
                                    <td>Invoice No </td>
                                    <td>:</td>
                                    <td>{last_invoice.invoiceNo}</td>
                                </tr>
                                <tr style={{ fontSize: 15, height: 30 }}>
                                    <td>Invoice Date </td>
                                    <td>:</td>
                                    <td>{last_invoice.invoiceDate}</td>
                                </tr>
                                <tr style={{ fontSize: 15, height: 30 }}>
                                    <td>Value </td>
                                    <td>:</td>
                                    <td><span style={{ color: 'green', fontWeight: '500' }}>{last_invoice.transferAmount}</span></td>
                                </tr>
                            </table>
                        </Col>
                        : <></>
                }

                {
                    last_invoice ?
                        <Col span={24}>
                            <Row gutter={[16, 16]}>
                                <Col lg={5} md={8} sm={12} xs={12}>
                                    <NJVButton type="primary" size="large" onClick={() => this.downloadInvoice('pdf', last_invoice)} style={{ backgroundColor: Colors.primary, display: 'flex', justifyContent: 'center', width: '100%' }}><SvgPdfOutlined width={20} height={20} color={'white'} /><span style={{ marginLeft: 5 }}>{i18next.t('download_pdf')}</span></NJVButton>
                                </Col>
                                <Col lg={5} md={8} sm={12} xs={12}>
                                    <NJVButton type="primary" size="large" onClick={() => this.downloadInvoice('png', last_invoice)} style={{ backgroundColor: Colors.primary, display: 'flex', justifyContent: 'center', width: '100%' }}><SvgPngOutlined width={20} height={20} color={'white'} /><span style={{ marginLeft: 5 }}>{i18next.t('download_image')}</span></NJVButton>
                                </Col>
                                <Col lg={5} md={8} sm={12} xs={12}>
                                    <NJVButton type="primary" size="large" onClick={() => this.downloadInvoice('excel', last_invoice)} style={{ backgroundColor: Colors.primary, display: 'flex', justifyContent: 'center', width: '100%' }}><SvgExcelOutlined width={20} height={20} color={'white'} /><span style={{ marginLeft: 5 }}>{i18next.t('download_excel')}</span></NJVButton>
                                </Col>
                            </Row>
                        </Col>
                        :
                        <></>
                }
                <Col span={24} style={{ marginBottom: 20, marginTop: 20 }}>
                    <Row gutter={[16, 16]}>
                        <Col lg={6} md={6} sm={24} xs={24}>
                            <NJVSelect
                                size="large"
                                bgcolor={Theme.colors.secondary}
                                style={{
                                    width: '100%'
                                }}
                                placeholder="Select"
                                value={defaultFilter}
                                onChange={(value) => this.filterBySpecific(value)}
                                options={[
                                    {
                                        'key': 1,
                                        'label': <>{i18next.t('order_billing')}</>,
                                        'value': 1
                                    }, {
                                        'key': 2,
                                        'label': 'Last 7 days',
                                        'value': 2
                                    }, {
                                        'key': 3,
                                        'label': 'Last 30 days',
                                        'value': 3
                                    }
                                ]}
                            />
                        </Col>

                        <Col lg={6} md={6} sm={24} xs={24}>
                            <NJVDatePicker
                                onChange={(value) => this.updateFilterData('fromDate', value)}
                                style={{ width: '100%' }}
                                bgcolor={Theme.colors.secondary}
                                size="large"
                                placeholder={i18next.t("from_date")} />
                        </Col>
                        <Col lg={6} md={6} sm={24} xs={24}>
                            <NJVDatePicker
                                onChange={(value) => this.updateFilterData('toDate', value)}
                                bgcolor={Theme.colors.secondary}
                                style={{ width: '100%' }}
                                size="large"
                                placeholder={i18next.t("to_date")} />
                        </Col>
                        <Col lg={6} md={6} sm={24} xs={24}>
                            <NJVButton
                                size="large"
                                style={{ width: '100%' }}
                                type="primary" onClick={() => this.search()}>
                                {i18next.t("search")}
                            </NJVButton>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    {
                        isDataFetching ?
                            <Skeleton />
                            :
                            <Table
                                rowKey={(record) => record.id}
                                className="custom-table"
                                style={{ borderRadius: 10 }}
                                pagination={{
                                    position: 'top' | 'bottom',
                                    total: totalPagination,
                                    current: page,
                                    onChange: this.handlePaginationChange,
                                    defaultPageSize: pageSize
                                }}
                                columns={columns} dataSource={data} />
                    }
                </Col>
            </Row>
        )
    }

    getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
        });
    };

    handleChange = ({ fileList }) => {
        this.setState({ fileList });
    };

    handleImageSubmit = async (imageFile, shipperId) => {
        this.setState({
            isSendingScreenshot: true,
            isSubmittedModalOpen: false
        })
        try {
            const file = imageFile[0];
            const formData = new FormData()
            formData.append('file', file.originFileObj)
            formData.append('owedShipperId', shipperId)
            let response = await ApiHandler({ url: Api.shipper_settlement_screenshot, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData })
            if (response.status === 200) {
                this.setState({
                    isPending: false,
                    isSendingScreenshot: false,
                    isSubmittedModalOpen: true
                })
            }
        } catch (error) {
            console.log(error);
        }

    }

    paymentSection = () => {
        const { ispaymentFetching, fileList, previewOpen, previewImage, paymentInfo, isPending, isSendingScreenshot, isSubmittedModalOpen } = this.state

        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        const buttonStyle = {
            marginTop: 30,
            backgroundColor: fileList.length === 0 ? '#f5f5f5' : `${Theme.colors.primary}`,
            color: fileList.length === 0 ? '#ccc' : '#fff'
        };

        return (
            <>
                <Modal title={null} centered width={350}
                    onOk={() => this.setState({ isSubmittedModalOpen: false })} open={isSubmittedModalOpen} footer={null} onCancel={() => this.setState({ isSubmittedModalOpen: false })}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: 16 }}>
                        <SvgCheck width={50} height={50} color="#00BA00" />
                        <p style={{ fontWeight: 700, fontSize: 20 }}>Success</p>
                        <div style={{ fontWeight: 500 }}>Your payment screenshot has been submitted successfully.</div>
                    </div>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        {
                            ispaymentFetching ?
                                <Skeleton />
                                :
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 20 }}>
                                        <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.props.navigate(CustomPath.payment_history)}>{i18next.t("view_history")}</NJVButton>
                                    </div>
                                    {
                                        isPending ?
                                            <>
                                                <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
                                                    <Col span={24}>
                                                        <NJVCard style={{ marginBottom: 30 }}>
                                                            <p style={{ fontWeight: 600, textAlign: 'center' }}>{paymentInfo?.content}</p>
                                                        </NJVCard>
                                                    </Col>
                                                    <Col lg={8} md={12} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', gap: 30 }}>
                                                            <Image width={100} src={AyeBank} preview={false} />
                                                            <div style={{ display: 'flex', gap: 4 }}>
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: 3, fontWeight: 600 }}>
                                                                    <p>Account Owner</p>
                                                                    <p>Account Number</p>
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: 3, fontWeight: 600 }}>
                                                                    <p>- Kyaw Thet Zaw</p>
                                                                    <p>- 20022991924</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={8} md={12} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', gap: 30 }}>
                                                            <Image width={100} src={KBZPay} preview={false} />
                                                            <div style={{ display: 'flex', gap: 4 }}>
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: 3, fontWeight: 600 }}>
                                                                    <p>Account Owner</p>
                                                                    <p>Account Number</p>
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: 3, fontWeight: 600 }}>
                                                                    <p>- Kyaw Thet Zaw</p>
                                                                    <p>- 09777000650</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={8} md={12} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', gap: 30 }}>
                                                            <Image width={100} src={WavePay} preview={false} style={{borderRadius: 10}} />
                                                            <div style={{ display: 'flex', gap: 4 }}>
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: 3, fontWeight: 600 }}>
                                                                    <p>Account Owner</p>
                                                                    <p>Account Number</p>
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: 3, fontWeight: 600 }}>
                                                                    <p>- Kyaw Thet Zaw</p>
                                                                    <p>- 09777000650</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ textAlign: 'center' }}>
                                                    <Col span={24}>
                                                        <p style={{ fontWeight: 600, color: Theme.colors.primary }}>{i18next.t("owed_msg")}</p>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Upload
                                                            listType="picture-card"
                                                            fileList={fileList}
                                                            onPreview={this.handlePreview}
                                                            onChange={this.handleChange}
                                                            maxCount={1}
                                                            accept={["image/png, image/jpeg"]}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileList.length === 1 ? null : uploadButton}
                                                        </Upload>
                                                        {previewImage && (
                                                            <Image
                                                                wrapperStyle={{ display: 'none' }}
                                                                preview={{
                                                                    visible: previewOpen,
                                                                    onVisibleChange: (visible) => this.setState({ previewOpen: visible }),
                                                                    afterOpenChange: (visible) => !visible && this.setState({ previewImage: '' }),
                                                                }}
                                                                src={previewImage}
                                                            />
                                                        )}
                                                    </Col>
                                                    <Col span={24}>
                                                        <NJVButton loading={isSendingScreenshot} type="primary" disabled={fileList.length === 0} size="large" style={buttonStyle} onClick={() => this.handleImageSubmit(fileList, paymentInfo.id)}>{i18next.t("submit")}</NJVButton>
                                                    </Col>
                                                </Row>
                                            </> :
                                            <Row>
                                                <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                                                    <Lottie
                                                        style={{ height: '200px' }}
                                                        animationData={NoDataFoundAnimation}
                                                        loop={true}
                                                        autoplay={true}
                                                    />
                                                </Col>
                                                <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                                    <div style={{ fontSize: 16, fontWeight: 600 }}>
                                                        {i18next.t("msg_settlement")}
                                                    </div>
                                                </Col>
                                            </Row>
                                    }
                                </>
                        }
                    </Col>
                </Row>
            </>
        )
    }

    render() {

        const items = [
            {
                key: '1',
                label: <TitleLevel3 label={i18next.t("invoice")} />,
                children: this.invoiceSection(),
            },
            {
                key: '2',
                label: <TitleLevel3 label={i18next.t("credit_payment")} />,
                children: this.paymentSection(),
            },
        ]

        return (
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Tabs defaultActiveKey={`${this.props.isSettlement ? "2" : "1"}`} items={items} />
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(InvoicPage)