import { createSlice } from '@reduxjs/toolkit'

export const ninjaCoreAuthSlice = createSlice({
    name: 'ninjaCoreAuth',
    initialState: {
        accessToken: '',
        refreshToken: '',
    },
    reducers: {
        updateNijaCoreAccessToken: (state, action) => {
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
        },
        ninjaCoreSignOut: (state, action) => {
            state.accessToken = ''
            state.refreshToken = ''
        }
    },
})

export const { updateNijaCoreAccessToken, ninjaCoreSignOut } = ninjaCoreAuthSlice.actions
export const ninjaCoreAccessToken = (state) => state.ninjaCoreAuth.accessToken
export const ninjaCoreRefreshToken = (state) => state.ninjaCoreAuth.refreshToken
export default ninjaCoreAuthSlice.reducer
