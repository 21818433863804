import React from "react";
import { LOC_getPrinterType } from '../../core/custom-local-storage';
import GPrinterAWBSlip from './g-printer-awb';

class AirWayBillSlip extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            printerType: null
        }
    }
    componentDidMount() {
        this.setState({
            printerType: LOC_getPrinterType()
        })
    }

    render() {
        const { key, senderName, senderPhone, senderAddress, receiverName, receiverPhone, receiverAddress, trackId, createDate, cod, sortingCode, note, parcelAmount, deliveryCharges } = this.props

        return (
            <>
                <GPrinterAWBSlip
                    key={key}
                    senderName={senderName}
                    senderPhone={senderPhone}
                    senderAddress={senderAddress}
                    receiverName={receiverName}
                    receiverPhone={receiverPhone}
                    receiverAddress={receiverAddress}
                    trackId={trackId}
                    createDate={createDate}
                    parcelAmount={parcelAmount}
                    deliveryCharges={deliveryCharges}
                    cod={cod}
                    note={note}
                    sortingCode={sortingCode} />
                {/* {
                    LOC_getPrinterType() === Printer.GPrinter ?
                        <GPrinterAWBSlip
                            key={key}
                            senderName={senderName}
                            senderPhone={senderPhone}
                            senderAddress={senderAddress}
                            receiverName={receiverName}
                            receiverPhone={receiverPhone}
                            receiverAddress={receiverAddress}
                            trackId={trackId}
                            createDate={createDate}
                            parcelAmount={parcelAmount}
                            deliveryCharges={deliveryCharges}
                            cod={cod}
                            note={note}
                            sortingCode={sortingCode} />
                        :
                        <APrinterAWBSlip
                            key={key}
                            senderName={senderName}
                            senderPhone={senderPhone}
                            senderAddress={senderAddress}
                            receiverName={receiverName}
                            receiverPhone={receiverPhone}
                            receiverAddress={receiverAddress}
                            trackId={trackId}
                            createDate={createDate}
                            parcelAmount={parcelAmount}
                            deliveryCharges={deliveryCharges}
                            cod={cod}
                            note={note}
                            sortingCode={sortingCode} />
                } */}

            </>
        )
    }

    // render() {
    //     const { senderName, senderPhone, senderAddress, receiverName, receiverPhone, receiverAddress, trackId, createDate, cod, sortingCode, note, parcelAmount, deliveryCharges } = this.props
    //     const customSizeStyles = {
    //         width: containerW,
    //         height: containerH,
    //         fontFamily: 'Arial, sans-serif',
    //         paddingLeft: '0.08in',
    //         paddingTop: '0.18in',
    //     };
    //     const slipContainer = {
    //         width: childContainerW,
    //         height: childContainerH,
    //         border: '1px dotted #000000',
    //     }
    //     const lableStyles = {
    //         fontSize: 11,
    //         fontWeight: '600'
    //     };
    //     const senderStyles = {
    //         fontSize: '11px',
    //         fontWeight: '600',
    //         width: '2in'
    //     };

    //     const phoneStyles = {
    //         fontSize: '11px',
    //         fontWeight: '500',
    //     }
    //     const senderAddressStyles = {
    //         fontSize: '11px',
    //         fontWeight: '500',
    //         width: '2.8in',
    //         paddingLeft: 5,
    //         paddingRight: 5
    //     }
    //     const receiverAddressStyles = {
    //         fontSize: '11px',
    //         fontWeight: '500',
    //         width: '2.8in'
    //     }
    //     const firstCellStyle = {
    //         width: '1.8in',
    //         display: 'flex',
    //         paddingLeft: 5
    //     }

    //     const receiverCell = {
    //         width: childContainerW,
    //         height: '0.8in',
    //         display: 'flex',
    //         alignItems: 'center',
    //         paddingLeft: 5,
    //         paddingRight: 5
    //     }

    //     const codStyle = {
    //         fontSize: '18px',
    //         fontWeight: '700'
    //     }

    //     const sortingStyle = {
    //         fontSize: '27px',
    //         fontWeight: '800'
    //     }
    //     const noteStyle = {
    //         fontSize: '10px',
    //         fontWeight: '500'
    //     }

    //     const feesStyles = {
    //         fontSize: 10,
    //         fontWeight: '600',
    //     }
    //     const trackIdStyle = {
    //         fontSize: 10
    //     }
    //     const dateStyle = {
    //         fontSize: 10
    //     }
    //     return (
    //         <div
    //             key={this.props.key}
    //             style={customSizeStyles}>
    //             <div
    //                 style={slipContainer}>
    //                 <div style={{
    //                     width: childContainerW,
    //                     height: '1.3in',
    //                     borderBottom: '1px dotted #000000',
    //                 }}>
    //                     <div style={{ display: 'flex', height: '0.85in' }}>
    //                         <div style={firstCellStyle}>
    //                             <div>
    //                                 <div>
    //                                     <Image
    //                                         preview={false}
    //                                         style={{ width: 100, marginTop: 2 }}
    //                                         src={logo} />
    //                                 </div>
    //                                 <div style={lableStyles}>From ( Sender )</div>
    //                                 <div style={senderStyles}>{senderName}</div>
    //                                 <div style={phoneStyles}>{senderPhone}</div>
    //                             </div>
    //                         </div>
    //                         <div
    //                             style={{
    //                                 width: '0.9in',
    //                                 height: '1.1in',
    //                                 display: 'flex',
    //                                 justifyContent: 'center',
    //                                 paddingTop: '0.05in'

    //                             }}>
    //                             <QRCode
    //                                 style={{
    //                                     width: '0.8in',
    //                                     height: '0.8in',
    //                                 }}
    //                                 value={trackId} />
    //                         </div>
    //                     </div>
    //                     <div style={senderAddressStyles}>{senderAddress}</div>
    //                 </div>
    //                 <div style={{
    //                     width: childContainerW,
    //                     height: '0.8in',
    //                     borderBottom: '1px dotted #000000',
    //                     display: 'flex',
    //                 }}>
    //                     <div style={receiverCell}>
    //                         <div>
    //                             <div style={lableStyles}>To ( Receiver )</div>
    //                             <div style={senderStyles}>{receiverName}&nbsp;-&nbsp;{receiverPhone}</div>
    //                             <div style={receiverAddressStyles}>{receiverAddress}</div>
    //                         </div>
    //                     </div>
    //                 </div>



    //                 <div style={{
    //                     height: '0.25in',
    //                     borderBottom: '1px dotted #000000',
    //                     display: 'flex'
    //                 }}>
    //                     <div style={{
    //                         width: childContainerHalfW,
    //                         height: '0.25in',
    //                         borderRight: '1px dotted #000000',
    //                         display: 'flex',
    //                         justifyContent: 'center',
    //                         alignItems: 'center'
    //                     }}>
    //                         <span style={feesStyles}>Parcel Value - {parcelAmount ? parcelAmount : 0}</span>
    //                     </div>
    //                     <div style={{
    //                         width: childContainerHalfW,
    //                         height: '0.25in',
    //                         display: 'flex',
    //                         justifyContent: 'center',
    //                         alignItems: 'center'
    //                     }}>
    //                         <span style={feesStyles}>Delivery Fees -  {deliveryCharges ? deliveryCharges : 0}</span>
    //                     </div>
    //                 </div>
    //                 <div style={{
    //                     height: '0.3in',
    //                     borderBottom: '1px dotted #000000',
    //                     display: 'flex'
    //                 }}>
    //                     <div style={{
    //                         width: childContainerHalfW,
    //                         height: '0.3in',
    //                         borderRight: '1px dotted #000000',
    //                         display: 'flex',
    //                         justifyContent: 'center',
    //                         alignItems: 'center'
    //                     }}>
    //                         <span style={codStyle}>COD</span>
    //                     </div>
    //                     <div style={{
    //                         width: childContainerHalfW,
    //                         height: '0.3in',
    //                         display: 'flex',
    //                         justifyContent: 'center',
    //                         alignItems: 'center'
    //                     }}>
    //                         <span style={codStyle}>{cod ? cod : 0}</span>
    //                     </div>

    //                 </div>
    //                 <div style={{
    //                     height: '0.35in',
    //                     borderBottom: '1px dotted #000000',
    //                     display: 'flex',
    //                     justifyContent: 'center',
    //                     alignItems: 'center'
    //                 }}>
    //                     <span style={sortingStyle}>{sortingCode}</span>
    //                 </div>
    //                 <div style={{
    //                     height: '0.2in',
    //                     display: 'flex',
    //                     justifyContent: 'center',
    //                     alignItems: 'center',
    //                     borderBottom: '1px dotted #000000',
    //                 }}>
    //                     <span style={trackIdStyle}>{trackId}</span>
    //                 </div>

    //                 <div style={{
    //                     height: '0.3in',
    //                     display: 'flex',
    //                     paddingLeft: 5,
    //                     paddingRight: 5,
    //                     alignItems: 'center',
    //                     justifyContent: 'center',
    //                     borderBottom: '1px dotted #000000',
    //                 }}>
    //                     {
    //                         note ?
    //                             <span style={noteStyle}>Note : {note}</span>
    //                             :
    //                             <span style={noteStyle}>No note here</span>
    //                     }

    //                 </div>

    //                 <div style={{
    //                     width: childContainerW,
    //                     display: 'flex',
    //                     justifyContent: 'center',
    //                     marginTop: '0.01in',
    //                     marginBottom: '0.01in'

    //                 }}>
    //                     <div style={dateStyle}>Create Date : {createDate}</div>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }
}

export default AirWayBillSlip
