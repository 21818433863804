import React from 'react';
import { useQuery } from '@apollo/client';
import { ENUM_QUERY } from '../network/graph-qurey';


const TestApollo = () => {
  const { loading, error, data } = useQuery(ENUM_QUERY, {
    variables: { enumType: "ACCOUNT" }
  });

  // const { loading, error, data } = useQuery(ENUM_QUERY, { enumType: "ACCOUNT" })
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :()</p>;
  return (
    <ul>
      {data.fetchEnum.map((user) => (
        <li key={user.id}>
          {user.key} - {user.value}
        </li>
      ))}
    </ul>
  );
};
export default TestApollo;