import { Image } from 'antd';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from "react-i18next";
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import store, { persistor } from './redux/store';
import reportWebVitals from './reportWebVitals';
import Router from './routes/router';
import GraphProvider from './network/GraphProvider';

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'mm'],
    fallbackLng: 'en',
    debug: false,
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  });
const loadingImage = require('./asset/ninjaIcon.png')
const loadingMarkup = (
  <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div style={{ position: 'relative' }}>
      <div className="loader"></div>
      <div style={{
        width: 80,
        height: 80,
        position: 'absolute',
        left: 10,
        top: 10
      }}>
        <Image
          src={loadingImage}
          preview={false}
          style={{ marginTop: 10, marginLeft: 10, }}
          width={60}
        />
      </div>
    </div>
  </div>
)




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <BrowserRouter>
    <Suspense fallback={loadingMarkup}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GraphProvider>
            <Router />
          </GraphProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  </BrowserRouter>
);
reportWebVitals();
