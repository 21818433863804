import { compose } from "@reduxjs/toolkit";
import { Col, Row } from "antd";
import React from "react";
import withRouter from "../network/with-router";
import Lottie from "lottie-react";
import SystemMaintainAnimation from '../animate-lottie/system-maintain-animation.json';
import Theme from "../components/theme";


class UnderMaintain extends React.Component {

    render() {
        return (
            <>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row style={{ textAlign: 'center', marginTop: 100 }} gutter={[16, 16]}>
                            <Col span={24}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={SystemMaintainAnimation}
                                    loop={true}
                                    autoplay={true}
                                />
                            </Col>
                            <Col span={24} >
                                <span style={{ fontSize: 30, fontWeight: 600, color: Theme.colors.primary }}>
                                    System Maintenance!
                                </span>
                            </Col>
                            <Col span={24}>
                                <span style={{ fontSize: 20, fontWeight: 600 }}>
                                    We'll be back soon. Thank you for your patience.
                                </span>

                            </Col>
                        </Row>
                    </Col >
                </Row>



            </>
        )
    }
}

export default compose(withRouter)(UnderMaintain)