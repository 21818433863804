import { compose } from "@reduxjs/toolkit";
import { Col, Row, Typography } from "antd";
import i18next from "i18next";
import React from "react";
import withRouter from '../../network/with-router';
const { Title, Paragraph } = Typography;

class TermsAndCondition extends React.Component {
    render() {
        return (
            <Row>
                <Col span={1} />
                <Col span={22}>

                    <Title level={3}>{i18next.t("terms_and_condition")}</Title>
                    <Paragraph>
                        We, Time Definite Delivery (TDD) (Flying Helios Co., Ltd.), a company incorporated in Myanmar and hereinafter referred to as ("Time Definite Delivery (TDD)”, "we", “us", "our" or "Company"), would like to thank you hereinafter referred to as (“you”, “user” or “buyer”) for visiting our website tdd-mm.com, mobile site, or mobile app ("Site" or “Platform”). Your terms of use of our Site and the provision of services by us through the Site shall be governed by the terms and conditions as set out herein ("User Agreement"), our privacy policy ("Privacy Policy") and all other policies on our site. Your use of our Site constitutes your full agreement to our User Agreement, our Privacy Policy and all other policies, which shall form a legally binding and enforceable contract between you and us. If you do not accept any provision in this User Agreement or our Privacy Policy, you should immediately cease all usage of our Site and notify us to discontinue any updates which you have signed up for, failing which you shall be deemed to be bound by all the provisions contained in this User Agreement, Privacy Policy and all other policies.
                    </Paragraph>
                    <Title level={4}>1. Application and Acceptance of the Terms</Title>
                    <Paragraph>
                        1.1 Your use of the Sites and Time Definite Delivery (TDD) services, software and products (collectively the as the “Services” hereinafter) is subject to the terms and conditions contained in this document as well as the Privacy Policy and any other rules and policies of the Sites that Time Definite Delivery (TDD) may publish from time to time. This document and such other rules and policies of the Sites are collectively referred to below as the “Terms”. By accessing the Sites or using the Services, you agree to accept and be bound by the Terms. Please do not use the Services or the Sites if you do not accept all of the Terms.
                    </Paragraph>
                    <Paragraph>
                        1.2 You may not use the Services and may not accept the Terms if (a) you are not of legal age to form a binding contract with Time Definite Delivery (TDD), or (b) you are not permitted to receive any Services under the laws of Singapore or other countries / regions including the country / region in which you are resident or from which you use the Services.
                    </Paragraph>
                    <Paragraph>
                        1.3 Time Definite Delivery (TDD) may amend any Terms at any time by posting the relevant amended and restated Terms on the Sites. By continuing to use the Services or the Sites, you agree that the amended Terms will apply to you.
                    </Paragraph>
                    <Paragraph>
                        1.4 If Time Definite Delivery (TDD) has posted or provided a translation of the English language version of the Terms, you agree that the translation is provided for convenience only and that the English language version will govern your uses of the Services or the Sites.
                    </Paragraph>
                    <Paragraph>
                        1.5 You may be required to enter into a separate agreement, whether online or offline, with Time Definite Delivery (TDD) or our affiliate for any Service (“Additional Agreements”). If there is any conflict or inconsistency between the Terms and an Additional Agreement, the Additional Agreement shall take precedence over the Terms only in relation to that Service concerned.
                    </Paragraph>
                    <Paragraph>
                        1.6 The Terms may not otherwise be modified except in writing by an authorized officer of Time Definite Delivery (TDD).
                    </Paragraph>

                    <Title level={4}>2. Provision of Services</Title>
                    <Paragraph>
                        2.1 The contracting entity that you are contracting with is Time Definite Delivery (TDD) if you are a registered member of the Sites and registered or resident in Singapore. If you registered in a jurisdiction outside Singapore, you are contracting with Time Definite Delivery (TDD)(incorporated in Singapore with Company Reg. No. 201412014E). If you registered in Southeast Asia, you are contracting with Time Definite Delivery (TDD) affiliates as some or part of the Services may be supported and provided by affiliates of Time Definite Delivery (TDD), Time Definite Delivery (TDD) may delegate some of the Services to its affiliates.
                    </Paragraph>
                    <Paragraph>
                        2.2 You must register as a member on the Sites in order to access and use some Services. Further, Time Definite Delivery (TDD) reserves the right, without prior notice, to restrict access to or use of certain Services (or any features within the Services) to paying Users or subject to other conditions that Time Definite Delivery (TDD) may impose in our discretion.
                    </Paragraph>
                    <Paragraph>
                        2.3 Services (or any features within the Services) may vary for different regions and countries. No warranty or representation is given that a particular Service or feature or function thereof or the same type and extent of the Service or features and functions thereof will be available for Users. Time Definite Delivery (TDD) may in our sole discretion limit, deny or create different level of access to and use of any Services (or any features within the Services) with respect to different Users.
                    </Paragraph>
                    <Paragraph>
                        2.4 Time Definite Delivery (TDD) may launch, change, upgrade, impose conditions to, suspend, or stop any Services (or any features within the Services) without prior notice except that in case of a fee-based Service, such changes will not substantially adversely affect the paying Users in enjoying that Service.
                    </Paragraph>
                    <Paragraph>
                        2.5 Some Services may be provided by Time Definite Delivery (TDD) affiliates on behalf of Time Definite Delivery (TDD).
                    </Paragraph>

                    <Title level={4}>3. Users Generally </Title>
                    <Paragraph>
                        3.1 As a condition of your access to and use of the Sites or Services, you agree that you will comply with all applicable laws and regulations when using the Sites or Services.
                    </Paragraph>
                    <Paragraph>
                        3.2 You agree to use the Sites or Services solely for your own private and internal purposes. You agree that (a) you will not copy, reproduce, download, re-publish, sell, distribute or resell any Services or any information, text, images, graphics, video clips, sound, directories, files, databases or listings, etc available on or through the Sites (the “Site Content”), and (b) you will not copy, reproduce, download, compile or otherwise use any Site Content for the purposes of operating a business that competes with Time Definite Delivery (TDD), or otherwise commercially exploiting the Site Content. Systematic retrieval of Site Content from the Sites to create or compile, directly or indirectly, a collection, compilation, database or directory (whether through robots, spiders, automatic devices or manual processes) without written permission from Time Definite Delivery (TDD) is prohibited. Use of any content or materials on the Sites for any purpose not expressly permitted in the Terms is prohibited.
                    </Paragraph>
                    <Paragraph>
                        3.3 You must read Time Definite Delivery (TDD)’s Privacy Policy which governs the protection and use of personal information about Users in the possession of Time Definite Delivery (TDD) and our affiliates.
                    </Paragraph>
                    <Paragraph>
                        3.4 Time Definite Delivery (TDD) may allow Users to access to content, products or services offered by third parties through hyperlinks (in the form of word link, banners, channels or otherwise), API or otherwise to such third parties' web sites. You are cautioned to read such web sites' terms and conditions and/or privacy policies before using the Sites. You acknowledge that Time Definite Delivery (TDD) has no control over such third parties' web sites, does not monitor such web sites, and shall not be responsible or liable to anyone for such web sites, or any content, products or services made available on such web sites.
                    </Paragraph>
                    <Paragraph>
                        3.5 You agree not to undertake any action to undermine the integrity of the computer systems or networks of Time Definite Delivery (TDD) and/or any other User nor to gain unauthorized access to such computer systems or networks.
                    </Paragraph>
                    <Paragraph>
                        3.6 You agree not to undertake any action which may undermine the integrity of Time Definite Delivery (TDD)’s feedback system, such as leaving positive feedback for yourself using secondary Member IDs or through third parties or by leaving unsubstantiated negative feedback for another User.
                    </Paragraph>
                    <Paragraph>
                        3.7 By posting or displaying any information, content or material (“User Content”) on the Sites or providing any User Content to Time Definite Delivery (TDD) or our representative(s), and to the extent permitted under applicable laws, you grant an irrevocable, perpetual, worldwide, royalty-free, and sub-licensable (through multiple tiers) license to Time Definite Delivery (TDD) to display, transmit, distribute, reproduce, publish, duplicate, adapt, modify, translate, create derivative works, and otherwise use any or all of the User Content in any form, media, or technology now known or not currently known in any manner and for any purpose which may be beneficial to the operation of the Sites, the provision of any Services and/or the business of the User. You confirm and warrant to Time Definite Delivery (TDD) that you have all the rights, power and authority necessary to grant the above license. Information that is protected under data protection laws will only be used and kept in compliance with those laws.
                    </Paragraph>
                    <Title level={4}>4. Member Accounts</Title>
                    <Paragraph>
                        4.1 User must be registered on the Sites to access or use some Services (a registered User is also referred to as a “Member” below). Except with Time Definite Delivery (TDD)’s approval, one User may only register one member account on the Sites. Time Definite Delivery (TDD) may cancel or terminate a User’s member account if we have reasons to suspect that the User has concurrently registered or controlled two or more member accounts. Further, Time Definite Delivery (TDD) may reject User’s application for registration for any reason.
                    </Paragraph>
                    <Paragraph>
                        4.2 Upon registration on the Sites, Time Definite Delivery (TDD) shall assign an account and issue a member ID and password (the latter shall be chosen by a registered User during registration) to each registered User. An account may have a web-based email account with limited storage space for the Member to send or receive emails.
                    </Paragraph>
                    <Paragraph>
                        4.3 A set of Member ID and password is unique to a single account. Each Member shall be solely responsible for maintaining the confidentiality and security of your Member ID and password and for all activities that occur under your account. No Member may share, assign, or permit the use of your Member account, ID or password by another person outside of the Member’s own business entity. Member agrees to notify Time Definite Delivery (TDD) immediately if you become aware of any unauthorized use of your password or your account or any other breach of security of your account.
                    </Paragraph>
                    <Paragraph>
                        4.4 Member agrees that all activities that occur under your account (including without limitation, posting any company or product information, clicking to accept any Additional Agreements or rules, subscribing to or making any payment for any services, sending emails using the email account or sending SMS) will be deemed to have been authorized by the Member.
                    </Paragraph>
                    <Paragraph>
                        4.5 Member acknowledges that sharing of your account with other persons, or allowing multiple users outside of your business entity to use your account (collectively, "multiple use"), may cause irreparable harm to Time Definite Delivery (TDD) or other Users of the Sites. Member shall indemnify Time Definite Delivery (TDD), our affiliates, directors, employees, agents and representatives against any loss or damages (including but not limited to loss of profits) suffered as a result of the multiple use of your account. Member also agrees that in case of the multiple use of your account or Member’s failure to maintain the security of your account, Time Definite Delivery (TDD) shall not be liable for any loss or damages arising from such a breach and shall have the right to suspend or terminate Member’s account without liability to Member.
                    </Paragraph>


                    <Title level={4}>5. Member's Responsibilities</Title>
                    <Paragraph>
                        5.1 Each Member represents, warrants and agrees that (a) you have full power and authority to accept the Terms, to grant the license and authorization and to perform the obligations hereunder; (b) you use the Sites and Services for business purposes only; and (c) the address you provide when registering is the principal place of business of your business entity. For purposes of this provision, a branch or liaison office will not be considered a separate entity and your principal place of business will be deemed to be that of your head office.
                    </Paragraph>
                    <Paragraph>
                        5.2 Member will be required to provide information or material about your entity, business or products/services as part of the registration process on the Sites or your use of any Service or the member account. Each Member represents, warrants and agrees that (a) such information and material whether submitted during the registration process or thereafter throughout the continuation of the use of the Sites or Service is true, accurate, current and complete, and (b) you will maintain and promptly amend all information and material to keep it true, accurate, current and complete.
                    </Paragraph>
                    <Paragraph>
                        5.3 Upon becoming a Member, you consent to the inclusion of the contact information about you in our Buyer Database and authorize Time Definite Delivery (TDD) and our affiliates to share the contact information with other Users or otherwise use your personal information in accordance with the Privacy Policy.
                    </Paragraph>
                    <Paragraph>
                        5.4 Each Member represents, warrants and agrees that (a) you shall be solely responsible for obtaining all necessary third party licenses and permissions regarding any User Content that you submit, post or display; (b) any User Content that you submit, post or display does not infringe or violate any of the copyright, patent, trademark, trade name, trade secrets or any other personal or proprietary rights of any third party (“Third Party Rights”); (c) you have the right and authority to sell, trade, distribute or export or offer to sell, trade, distribute or export the products or services described in the User Content and such sale, trade, distribution or export or offer does not violate any Third Party Rights and (d) you and your affiliates are not the subject of any trade restrictions, sanctions or other legal restrictions enacted by any country, international organization or jurisdiction.
                    </Paragraph>
                    <Paragraph>
                        5.5 Each Member further represents, warrants and agrees that the User Content that you submit, post or display shall:
                        <ul style={{ listStyle: 'none' }}>
                            <li>a) be true, accurate, complete and lawful;</li>
                            <li>b) not be false, misleading or deceptive;</li>
                            <li>c) not contain information that is defamatory, libelous, threatening or harassing, obscene, objectionable, offensive, sexually explicit or harmful to minors;</li>
                            <li>d) not contain information that is discriminatory or promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</li>
                            <li>e) not violate other Terms or any applicable Additional Agreements</li>
                            <li>f) not violate any applicable laws and regulations (including without limitation those governing export control, consumer protection, unfair competition, or false advertising) or promote any activities which may violate any applicable laws and regulations;</li>
                            <li>g) not contain any link directly or indirectly to any other web Sites which includes any content that may violate the Terms.</li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        5.6 Each Member further represents, warrants and agrees that you shall/are:
                        <ul style={{ listStyle: 'none' }}>
                            <li>a) carry on your activities on the Sites in compliance with any applicable laws and regulations;</li>
                            <li>b) conduct your business transactions with other users of the Sites in good faith;</li>
                            <li>c) carry on your activities in accordance with the Terms and any applicable Additional Agreements;</li>
                            <li>d) not use the Services or Sites to defraud any person or entity (including without limitation sale of stolen items, use of stolen credit/debit cards);</li>
                            <li>e) not impersonate any person or entity, misrepresent yourself or your affiliation with any person or entity;</li>
                            <li>f) not engage in spamming or phishing;</li>
                            <li>g) not engage in any other unlawful activities (including without limitation those which would constitute a criminal offence, give rise to civil liability, etc) or encourage or abet any unlawful activities;</li>
                            <li>h) not involve attempts to copy, reproduce, exploit or expropriate Time Definite Delivery (TDD)’s various proprietary directories, databases and listings;</li>
                            <li>i) not involve any computer viruses or other destructive devices and codes that have the effect of damaging, interfering with, intercepting or expropriating any software or hardware system, data or personal information;</li>
                            <li>j) not involve any scheme to undermine the integrity of the data, systems or networks used by Time Definite Delivery (TDD) and/or any user of the Sites or gain unauthorized access to such data, systems or networks;</li>
                            <li>k) not, and your director(s), officer(s), controlling party/ies, affiliates and legal jurisdiction in which any of the foregoing persons or entities is organized or has operations are not, persons or entities that are subject to any economic or fraud sanctions of any governmental, international or regulatory entities; and</li>
                            <li>l) not engage in any activities that would otherwise create any liability for Time Definite Delivery (TDD) or our affiliates.</li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        5.7 Member may not use the Services and member account to engage in activities which are identical or similar to Time Definite Delivery (TDD)’s business
                    </Paragraph>
                    <Paragraph>
                        5.8 If Member provides a business referee, Member represents, warrants and agrees that you have obtained all necessary consents, approvals and waivers from your business partners and associates to (a) act as your business referee; (b) post and publish their contact details and information, reference letters and comments on their behalf; and (c) that third parties may contact such business referees to support claims or statements made about you. You further warrant and agree that all reference letters and comments are true and accurate and third parties may contact the business referees without the need to obtain your consent.
                    </Paragraph>
                    <Paragraph>
                        5.9 Member agrees to provide all necessary information, materials and approval, and render all reasonable assistance and cooperation necessary for Time Definite Delivery (TDD)’s provision of the Services, evaluating whether Member has breached the Terms and/or handling any complaint against the Member. If Member’s failure to do so results in delay in, or suspension or termination of, the provision of any Service, Time Definite Delivery (TDD) shall not be obliged to extend the relevant service period nor shall be liable for any loss or damages arising from such delay, suspension or termination.
                    </Paragraph>
                    <Paragraph>
                        5.10 Member acknowledges and agrees that Time Definite Delivery (TDD) shall not be required to actively monitor nor exercise any editorial control whatsoever over the content of any message or material or information created, obtained or accessible through the Services or Sites. Time Definite Delivery (TDD) does not endorse, verify or otherwise certify the contents of any comments or other material or information made by any Member. Each Member is solely responsible for the contents of their communications and may be held legally liable or accountable for the content of their comments or other material or information
                    </Paragraph>
                    <Paragraph>
                        5.11 Member acknowledges and agrees that the Services may only be used by businesses and their representatives for business use and not for individual consumers or for personal use.
                    </Paragraph>
                    <Paragraph>
                        5.12 Member acknowledges and agrees that each Member is solely responsible for observing applicable laws and regulations in its respective jurisdictions to ensure that all use of the Site and Services are in compliance with the same.
                    </Paragraph>

                    <Title level={4}>6. Breaches by Members</Title>
                    <Paragraph>
                        6.1 Time Definite Delivery (TDD) reserves the right in our sole discretion to remove, modify or reject any User Content that you submit to, post or display on the Sites which we reasonably believe is unlawful, violates the Terms, could subject Time Definite Delivery (TDD) or our affiliates to liability, or is otherwise found inappropriate in Time Definite Delivery (TDD)’s opinion.
                    </Paragraph>
                    <Paragraph>
                        6.2 If any Member breaches any Terms, or if Time Definite Delivery (TDD) has reasonable grounds to believe that a Member is in breach of any Terms, Time Definite Delivery (TDD) shall have the right to take such disciplinary actions as it deems appropriate, including without limitation: (i) suspending or terminating the Member’s account and any and all accounts determined to be related to such account by Time Definite Delivery (TDD) in its discretion; (ii) restricting, downgrading, suspending or terminating the subscription of, access to, or current or future use of any Service; (iii) removing any product listings or other User Content that the Member has submitted, posted or displayed, or imposing restrictions on the number of product listings or User Content that the Member may post or display; (iv) imposing other restrictions on the Member’s use of any features or functions of any Service as Time Definite Delivery (TDD) may consider appropriate in its sole discretion; and (v) any other corrective actions, discipline or penalties as Time Definite Delivery (TDD) may deem necessary or appropriate in its sole discretion.
                    </Paragraph>

                    <Paragraph>
                        6.3 Without limiting the generality of the provisions of the Terms, a Member would be considered as being in breach of the Terms in any of the following circumstances:
                        <ul style={{ listStyle: "none" }}>
                            <li>a) upon complaint or claim from any third party, Time Definite Delivery (TDD) has reasonable grounds to believe that such Member has willfully or materially failed to perform your contract with such third party including without limitation where the Member has failed to deliver any items ordered by such third party after receipt of the purchase price, or where the items Member has delivered materially fail to meet the terms and descriptions outlined in your contract with such third party,</li>
                            <li>b) Time Definite Delivery (TDD) has reasonable grounds to suspect that such Member has used a stolen credit card or other false or misleading information in any transaction with a counterparty,</li>
                            <li>c) Time Definite Delivery (TDD) has reasonable grounds to suspect that any information provided by the Member is not current or complete or is untrue, inaccurate, or misleading, or</li>
                            <li>d) Time Definite Delivery (TDD) believes that the Member's actions may cause financial loss or legal liability to Time Definite Delivery(TDD) or our affiliates or any other Users.</li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        6.4 Time Definite Delivery(TDD) reserves the right to cooperate fully with governmental authorities, private investigators and / or injured third parties in the investigation of any suspected criminal or civil wrongdoing.Further, to the extent permitted by applicable laws and policies, Time Definite Delivery(TDD) may disclose the Member's identity, contact information and/or information regarding the Member's account(s), transactions or activities carried out on or via the Site, if requested by a government or law enforcement body, an injured third party, or as a result of a subpoena or other legal action.Time Definite Delivery(TDD) shall not be liable for damages or results arising from such disclosure, and Member agrees not to bring any action or claim against Time Definite Delivery(TDD) for such disclosure.
                    </Paragraph >
                    <Paragraph>
                        6.5 Time Definite Delivery (TDD) may, at any time and in our reasonable discretion, impose limitations on, suspend or terminate the Member’s use of any Service or the Sites without being liable to the Member if Time Definite Delivery (TDD) has received notice that the Member is in breach of any agreement or undertaking with any affiliate of Time Definite Delivery (TDD) and such breach involves or is reasonably suspected of involving dishonest or fraudulent activities. Time Definite Delivery (TDD) shall not be required to investigate such breach or request confirmation from the Member.
                    </Paragraph>
                    <Paragraph>
                        6.6 Each Member agrees to indemnify Time Definite Delivery (TDD), our affiliates, directors, employees, agents and representatives and to hold them harmless, from any and all damages, losses, claims and liabilities (including legal costs on a full indemnity basis) which may arise from your submission, posting or display of any User Content, from your use of the Sites or Services, or from your breach of the Terms.
                    </Paragraph>
                    <Paragraph>
                        6.7 Each Member further agrees that Time Definite Delivery (TDD) is not responsible, and shall have no liability to you or anyone else for any User Content or other material transmitted over the Sites, including fraudulent, untrue, misleading, inaccurate, defamatory, offensive or illicit material and that the risk of damage from such material rests entirely with each Member. Time Definite Delivery (TDD) reserves the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by the Member, in which event the Member shall cooperate with Time Definite Delivery (TDD) in asserting any available defenses.
                    </Paragraph>

                    <Title level={4}>7. Limitation of Liability</Title>

                    <Paragraph>
                        7.1 TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICES PROVIDED BY TIME DEFINITE DELIVERY (TDD) ON OR THROUGH THE SITES ARE PROVIDED "AS IS", "AS AVAILABLE" AND “WITH ALL FAULTS”, AND TIME DEFINITE DELIVERY (TDD) HEREBY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, ANY WARRANTIES OF CONDITION, QUALITY, DURABILITY, PERFORMANCE, ACCURACY, RELIABILITY, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. ALL SUCH WARRANTIES, REPRESENTATIONS, CONDITIONS, AND UNDERTAKINGS ARE HEREBY EXCLUDED.
                    </Paragraph>
                    <Paragraph>
                        7.2 TO THE MAXIMUM EXTENT PERMITTED BY LAW, TIME DEFINITE DELIVERY MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE VALIDITY, ACCURACY, CORRECTNESS, RELIABILITY, QUALITY, STABILITY, COMPLETENESS OR CURRENTNESS OF ANY INFORMATION PROVIDED ON OR THROUGH THE SITES; TIME DEFINITE DELIVERY DOES NOT REPRESENT OR WARRANT THAT THE MANUFACTURE, IMPORTATION, EXPORT, DISTRIBUTION, OFFER, DISPLAY, PURCHASE, SALE AND/OR USE OF PRODUCTS OR SERVICES OFFERED OR DISPLAYED ON THE SITES DOES NOT VIOLATE ANY THIRD PARTY RIGHTS; AND TIME DEFINITE DELIVERY (TDD) MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND CONCERNING ANY PRODUCT OR SERVICE OFFERED OR DISPLAYED ON THE SITES.
                    </Paragraph>
                    <Paragraph>
                        7.3 Any material downloaded or otherwise obtained through the Sites is done at each User's sole discretion and risk and each User is solely responsible for any damage to Time Definite Delivery (TDD)’s computer system or loss of data that may result from the download of any such material. No advice or information, whether oral or written, obtained by any User from Time Definite Delivery (TDD) or through or from the Sites shall create any warranty not expressly stated herein.
                    </Paragraph>
                    <Paragraph>
                        7.4 The Sites may make available to User services or products provided by independent third parties. No warranty or representation is made with regard to such services or products. In no event shall Time Definite Delivery (TDD) and our affiliates be held liable for any such services or products.
                    </Paragraph>
                    <Paragraph>
                        7.5 Each User hereby agrees to indemnify and save Time Definite Delivery (TDD), our affiliates, directors, officers and employees harmless, from any and all losses, claims, liabilities (including legal costs on a full indemnity basis) which may arise from such User's use of the Sites or Services (including but not limited to the display of such User's information on the Sites) or from your breach of any of the terms and conditions of the Terms. Each User hereby further agrees to indemnify and save Time Definite Delivery (TDD), our affiliates, directors, officers and employees harmless, from any and all losses, damages, claims, liabilities (including legal costs on a full indemnity basis) which may arise from User's breach of any representations and warranties made by User to Time Definite Delivery (TDD), including but not limited to those set forth in Section 5 hereunder.
                    </Paragraph>
                    <Paragraph>
                        7.6 Each User hereby further agrees to indemnify and save Time Definite Delivery (TDD), our affiliates, directors, officers and employees harmless, from any and all losses, damages, claims, liabilities (including legal costs on a full indemnity basis) which may arise, directly or indirectly, as a result of any claims asserted by Third Party Rights claimants or other third parties relating to products offered or displayed on the Sites. Each User hereby further agrees that Time Definite Delivery (TDD) is not responsible and shall have no liability to you, for any material posted by others, including defamatory, offensive or illicit material and that the risk of damages from such material rests entirely with each User. Time Definite Delivery (TDD) reserves the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you shall cooperate with Time Definite Delivery (TDD) in asserting any available defenses.
                    </Paragraph>
                    <Paragraph>
                        7.7 Time Definite Delivery(TDD) shall not be liable for any special, direct, indirect, punitive, incidental or consequential damages or any damages whatsoever(including but not limited to damages for loss of profits or savings, business interruption, loss of information), whether in contract, negligence, tort, equity or otherwise or any other damages resulting from any of the following:
                        <ul style={{ listStyle: 'none' }}>
                            <li>a) the use or the inability to use the Sites or Services;</li>
                            <li>b) any defect in goods, samples, data, information or services purchased or obtained from a User or any other third party through the Sites;</li>
                            <li>c) violation of Third Party Rights or claims or demands that User's manufacture, importation, export, distribution, offer, display, purchase, sale and/or use of products or services offered or displayed on the Sites may violate or may be asserted to violate Third Party Rights; or claims by any party that they are entitled to defense or indemnification in relation to assertions of rights, demands or claims by Third Party Rights claimants;</li>
                            <li>d) unauthorized access by third parties to data or private information of any User;</li>
                            <li>e) statements or conduct of any User of the Sites; or;</li>
                            <li>f) any matters relating to Services however arising, including negligence.</li>
                        </ul>
                    </Paragraph>
                    < Paragraph >
                        7.8 Notwithstanding any of the foregoing provisions, the aggregate liability of Time Definite Delivery(TDD), our employees, agents, affiliates, representatives or anyone acting on our behalf with respect to each User for all claims arising from the use of the Sites or Services during any calendar year shall be limited to the greater of(a) the amount of fees the User has paid to Time Definite Delivery(TDD) or our affiliates during the calendar year and(b) the maximum amount permitted in the applicable law.The preceding sentence shall not preclude the requirement by the User to prove actual damages.All claims arising from the use of the Sites or Services must be filed within one(1) year from the date the cause of action arose or such longer period as prescribed under any applicable law governing this Term of Use..
                    </Paragraph >
                    <Paragraph>
                        7.9 The limitations and exclusions of liability to you under the Terms shall apply to the maximum extent permitted by law and shall apply whether or not Time Definite Delivery (TDD) has been advised of or should have been aware of the possibility of any such losses arising.
                    </Paragraph>

                    <Title level={4}>
                        8. Force Majeure
                    </Title>

                    <Paragraph>
                        8.1 Under no circumstances shall Time Definite Delivery (TDD) be held liable for any delay or failure or disruption of the content or services delivered through the Sites resulting directly or indirectly from acts of nature, forces or causes beyond our reasonable control, including without limitation, Internet failures, computer, telecommunications or any other equipment failures, electrical power failures, strikes, labor disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, flood, storms, explosions, acts of God, war, governmental actions, orders of domestic or foreign courts or tribunals or non-performance of third parties.
                    </Paragraph>
                    <Title level={4}>9. Intellectual Property Rights</Title>

                    <Paragraph>
                        9.1 Time Definite Delivery (TDD) is the sole owner or lawful licensee of all the rights and interests in the Sites and the Site Content. The Sites and Site Content embody trade secrets and other intellectual property rights protected under worldwide copyright and other laws. All title, ownership and intellectual property rights in the Sites and Site Content shall remain with Time Definite Delivery (TDD), our affiliates or licensors of the Site Content, as the case may be. All rights not otherwise claimed under the Terms or by Time Definite Delivery (TDD) are hereby reserved.
                    </Paragraph>
                    <Paragraph>
                        9.2 "Time Definite Delivery (TDD)” and related icons and logos are registered trademarks or trademarks or service marks of Time Definite Delivery (TDD), in various jurisdictions and are protected under applicable copyright, trademark and other proprietary rights laws. The unauthorized copying, modification, use or publication of these marks is strictly prohibited.
                    </Paragraph>
                    <Paragraph>
                        9.3 Time Definite Delivery (TDD) may have independent third parties involved in the provision of the Services (e.g., the authentication and verification service providers). You may not use any trademark, service mark or logo of such independent third parties without prior written approval from such parties.
                    </Paragraph>
                    <Title level={4}>10. General Provisions</Title>

                    <Paragraph>
                        10.1 If any provision of the Terms is held to be invalid or unenforceable, such provision shall be deleted and the remaining provisions shall remain valid and be enforced.
                    </Paragraph>
                    <Paragraph>
                        10.2 Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section.
                    </Paragraph>
                    <Paragraph>
                        10.3 Time Definite Delivery (TDD)'s failure to enforce any right or failure to act with respect to any breach by you under the Terms will not constitute a waiver of that right nor a waiver of Time Definite Delivery (TDD)’s right to act with respect to subsequent or similar breaches.
                    </Paragraph>
                    <Paragraph>
                        10.4 Time Definite Delivery (TDD) shall have the right to assign the Terms (including all of our rights, titles, benefits, interests, and obligations and duties in the Terms to any person or entity (including any affiliates of Time Definite Delivery (TDD)). You may not assign, in whole or part, the Terms to any person or entity.
                    </Paragraph>
                </Col >
            </Row >
        )
    }
}

export default compose(withRouter)(TermsAndCondition)