import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { cartItems } from '../redux/cart-slice';
import { currentRoute } from '../redux/cookie-slice';
import { divisionCollection, townShipMap, townshipCollection } from '../redux/location-slice';
import { ninjaCoreAccessToken, ninjaCoreRefreshToken } from '../redux/ninja-core-auth-slice';
import { advanceOrderList, orderCreateTime, orderList } from '../redux/order-form-slice';
import { businessName, fullName, isFetchProfile, isSettlement, profileImg, shipperData, shipperUniqueId, statusKey } from '../redux/profile-slice';

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const translate = useTranslation();
    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state.auth.accessToken)
    const refreshToken = useSelector((state) => state.auth.refreshToken)
    const authenticated = useSelector((state) => state.auth.authenticated)
    const orderDraftList = useSelector(orderList)
    const orderSessionTime = useSelector(orderCreateTime)
    const advanceOrderDraftList = useSelector(advanceOrderList)
    const coreAccessToken = useSelector(ninjaCoreAccessToken)
    const coreRefreshToken = useSelector(ninjaCoreRefreshToken)
    const divisionList = useSelector(divisionCollection)
    const townshipList = useSelector(townshipCollection)
    const townshipTempMap = useSelector(townShipMap)
    const shipperName = useSelector(fullName)
    const shipperId = useSelector(shipperUniqueId)
    const fetchProfile = useSelector(isFetchProfile)
    const bName = useSelector(businessName)
    const img = useSelector(profileImg)
    const shipper_data = useSelector(shipperData)
    const isOwed = useSelector(isSettlement);
    const products = useSelector(cartItems)
    const status = useSelector(statusKey);

    //local storage
    const currentLocation = useSelector(currentRoute)

    return (
        <WrappedComponent
            {...props}
            params={params}
            navigate={navigate}
            location={location}
            translate={translate}
            dispatch={dispatch}
            authenticated={authenticated}
            accessToken={accessToken}
            refreshToken={refreshToken}
            coreRefreshToken={coreRefreshToken}
            coreAccessToken={coreAccessToken}
            currentLocation={currentLocation}
            orderDraftList={orderDraftList}
            advanceOrderDraftList={advanceOrderDraftList}
            orderSessionTime={orderSessionTime}
            divisionList={divisionList}
            townshipList={townshipList}
            townshipMap={townshipTempMap}
            shipperName={shipperName}
            shipperId={shipperId}
            fetchProfile={fetchProfile}
            businessName={bName}
            profileImgUrl={img}
            isSettlement={isOwed}
            cartItems={products}
            statusKey={status}
            shipperData={shipper_data}
        />
    );
};

export default withRouter;