import React from "react";
import { ApiHandler } from "../../network/network-manager";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { compose } from "@reduxjs/toolkit";
import withRouter from "../../network/with-router";
import { Col, Row, Table } from "antd";
import { TitleLevel3 } from "../../components/general-component";
import i18next from "i18next";
class OrderCreateFailLog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: []

        }
    }
    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const response = await ApiHandler({url: Api.redis_order_create_fail_log, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON})
            this.setState({
                data: response
            })
        } catch (error) {

        }
    }
    render() {
        const { data } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{index + 1}</span>,
                width: 5
            },
            {
                title: 'Receiver',
                dataIndex: 'receiverName',
                key: 'receiverName',
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
            },
            {
                title: 'Order Type',
                key: 'orderType',
                dataIndex: 'orderType'
            },
            {
                title: 'COD',
                key: 'codAmount',
                render: (orderHistory) => (
                    <div>{orderHistory.codAmount ? orderHistory.codAmount : 0}</div>
                ),

            },
            {
                title: 'Parcel Amount',
                dataIndex: 'parcelAmount',
                key: 'parcelAmount'
            }
        ]
        return (
            <>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <TitleLevel3 label={i18next.t("order_create_fail_logs")} />
                            </Col> 
                            <Col span={24}>
                                <Table
                                    rowKey={(record) => record.id}
                                    style={{ borderRadius: 10 }}
                                    pagination={false}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </>
        )
    }
}

export default compose(withRouter)(OrderCreateFailLog)