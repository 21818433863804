import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';
import authSlice from './auth-slice';
import ninjaCoreAuthSlice from './ninja-core-auth-slice';
import cookieSlice from './cookie-slice';
import orderFormSlice from './order-form-slice';
import LocationSlice from './location-slice';
import profileSlice from './profile-slice';
import cartSlice from './cart-slice';

const persistConfig = {
  key: 'ninija-van-shipper-root',
  storage,
}

const rootReducer = combineReducers({
  auth: authSlice,
  ninjaCoreAuth: ninjaCoreAuthSlice,
  cookie: cookieSlice,
  orderform: orderFormSlice,
  location: LocationSlice,
  profile: profileSlice,
  cart: cartSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer
})

const persistor = persistStore(store)
export { persistor }
export default store