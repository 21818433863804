import i18next from "i18next";

export const PhoneNumberValidator = (_, value) => {
    let isNumber = false;
    const numberPattern = /^[0-9]+$/;
    if (!value || value.length < 6 || value.length > 12 || !numberPattern.test(value)) {
        return Promise.reject();
    }
    return Promise.resolve();
};

export const NRCValidator = (_, value) => {
    let isNumber = false;
    const numberPattern = /^[0-9]+$/;
    if (!value || value.length < 6 || value.length > 6 || !numberPattern.test(value)) {
        return Promise.reject('Invalid NRC Number');
    }
    return Promise.resolve();
};

export const NumberOnlyValidator = (_, value) => {
    const numberPattern = /^[0-9]+$/;
    if (!value || value.length < 6 || value.length > 17 || !numberPattern.test(value)) {
        return Promise.reject('Invalid input');
    }
    return Promise.resolve();
};

export const NumberOnlyForOptionalValidator = (_, value) => {
    const numberPattern = /^[0-9]+$/;
    let isValueExist = false
    if (value && (value.length < 6 || value.length > 16)) {
        return Promise.reject('Invalid input');
    }
    if (value && value.length != 0) {
        isValueExist = true
    }
    if (isValueExist && !numberPattern.test(value)) {
        return Promise.reject('Invalid input');
    }
    return Promise.resolve();
};

export const ParcelAmountValidator = (_, value) => {
    const numberPattern = /^[0-9]+$/;
    let isValueExist = false
    if (value && (value.length < 3 || value.length > 7)) {
        return Promise.reject('Invalid input');
    }
    if (value && value.length != 0) {
        isValueExist = true
    }
    if (isValueExist && !numberPattern.test(value)) {
        return Promise.reject('Invalid input');
    }
    return Promise.resolve();
};