import { Image } from 'antd';
import QRCode from 'qrcode.react';
import React from "react";
import { LOC_getPrinterType } from '../../core/custom-local-storage';

const containerW = '3in'
const containerH = '4in'

const childContainerW = '2.9in'
const childContainerH = '3.6'

const childContainerHalfW = '1.45in'
const logo = require('../../asset/awb-logo.png');


class GPrinterAWBSlip extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            printerType: null
        }
    }
    componentDidMount() {
        this.setState({
            printerType: LOC_getPrinterType()
        })
    }

    render() {
        const { senderName, senderPhone, senderAddress, receiverName, receiverPhone, receiverAddress, trackId, createDate, cod, sortingCode, note, parcelAmount, deliveryCharges } = this.props
        const customSizeStyles = {
            width: containerW,
            height: containerH,
            fontFamily: 'Arial, sans-serif',
            paddingLeft: '0.08in',
            paddingTop: '0.18in',
        };
        const slipContainer = {
            width: childContainerW,
            height: childContainerH,
            border: '1px dotted #000000',
        }
        const lableStyles = {
            fontSize: 11,
            fontWeight: '600'
        };
        const senderStyles = {
            fontSize: '11px',
            fontWeight: '600',
            width: '2in'
        };

        const phoneStyles = {
            fontSize: '11px',
            fontWeight: '500',
        }
        const senderAddressStyles = {
            fontSize: '11px',
            fontWeight: '500',
            width: '2.8in',
            paddingLeft: 5,
            paddingRight: 5
        }
        const receiverAddressStyles = {
            fontSize: '11px',
            fontWeight: '500',
            width: '2.8in'
        }
        const firstCellStyle = {
            width: '1.8in',
            display: 'flex',
            paddingLeft: 5
        }

        const receiverCell = {
            width: childContainerW,
            height: '0.8in',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 5,
            paddingRight: 5
        }
        return (
            <div
                key={this.props.key}
                style={customSizeStyles}>
                <div
                    style={slipContainer}>
                    <div style={{
                        width: childContainerW,
                        height: '1.3in',
                        borderBottom: '1px dotted #000000',
                    }}>
                        <div style={{ display: 'flex', height: '0.85in' }}>
                            <div style={firstCellStyle}>
                                <div>
                                    <Image
                                        preview={false}
                                        style={{ width: 100, marginTop: 2 }}
                                        src={logo} />
                                    <div style={lableStyles}>From ( Sender )</div>
                                    <div style={senderStyles}>{senderName}</div>
                                    <div style={phoneStyles}>{senderPhone}</div>
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '0.9in',
                                    height: '1.1in',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingTop: '0.05in'

                                }}>
                                <QRCode
                                    style={{
                                        width: '0.8in',
                                        height: '0.8in',
                                    }}
                                    value={trackId} />
                            </div>
                        </div>
                        <div style={senderAddressStyles}>{senderAddress}</div>
                    </div>
                    <div style={{
                        width: childContainerW,
                        height: '0.8in',
                        borderBottom: '1px dotted #000000',
                        display: 'flex',
                    }}>
                        <div style={receiverCell}>
                            <div>
                                <div style={lableStyles}>To ( Receiver )</div>
                                <div style={senderStyles}>{receiverName}&nbsp;-&nbsp;{receiverPhone}</div>
                                <div style={receiverAddressStyles}>{receiverAddress}</div>
                            </div>
                        </div>
                    </div>



                    <div style={{
                        height: '0.25in',
                        borderBottom: '1px dotted #000000',
                        display: 'flex'
                    }}>
                        <div style={{
                            width: childContainerHalfW,
                            height: '0.25in',
                            borderRight: '1px dotted #000000',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 10,
                            fontWeight: '600',
                        }}>
                            <span>Parcel Value - {parcelAmount ? parcelAmount : 0}</span>
                        </div>
                        <div style={{
                            width: childContainerHalfW,
                            height: '0.25in',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 10,
                            fontWeight: '600',
                        }}>
                            <span>Delivery Fees -  {deliveryCharges ? deliveryCharges : 0}</span>
                        </div>
                    </div>
                    <div style={{
                        height: '0.3in',
                        borderBottom: '1px dotted #000000',
                        display: 'flex'
                    }}>
                        <div style={{
                            width: childContainerHalfW,
                            height: '0.3in',
                            borderRight: '1px dotted #000000',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '18px',
                            fontWeight: '700'
                        }}>
                            <span>COD</span>
                        </div>
                        <div style={{
                            width: childContainerHalfW,
                            height: '0.3in',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '18px',
                            fontWeight: '700'
                        }}>
                            <span>{cod ? cod : 0}</span>
                        </div>

                    </div>
                    <div style={{
                        height: '0.35in',
                        borderBottom: '1px dotted #000000',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '27px',
                        fontWeight: '800'
                    }}>
                        <span>{sortingCode}</span>
                    </div>
                    <div style={{
                        height: '0.2in',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderBottom: '1px dotted #000000',
                        fontSize: 10
                    }}>
                        <span>{trackId}</span>
                    </div>

                    <div style={{
                        height: '0.3in',
                        display: 'flex',
                        paddingLeft: 5,
                        paddingRight: 5,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderBottom: '1px dotted #000000',
                        fontSize: 10,
                        fontWeight: '500'
                    }}>
                        {
                            note ?
                                <span>{note}</span>
                                :
                                <span>-</span>
                        }

                    </div>

                    <div style={{
                        width: childContainerW,
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '0.01in',
                        marginBottom: '0.01in',
                        fontSize: 10
                    }}>
                        <span>Create Date : {createDate}</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default GPrinterAWBSlip
