import { compose } from "@reduxjs/toolkit";
import { Col, Form, Image, Row, message } from "antd";
import i18next from "i18next";
import React from "react";
import { NJVButton, NJVInput } from "../../components/core-component";
import { SvgLockFilled, SvgMobileFilled } from "../../components/custom-svgIcon";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler, ApiHandlerNoAuth } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const logo = require('../../asset/Logo.png');

const initialState = 1
const tokenState = 2
const passwordConfrimState = 3
const resendPhoneNumberCountdownNumber = 90

const tokenInputStyle = {
    width: 40,
    height: 40,
    borderRadius: 7,
    border: 'none',
    backgroundColor: '#f5f5f5',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: '500'
}

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            userId: null,
            currentStep: initialState,
            phoneNumber: null,
            token: '',
            phoneNumberLoading: false, tokenLoading: false, confirmLoading: false, resendPhoneNumberLoading: false,
            visiblePassword: false,
            resendPhoneNumberEnable: false,
            countdown: resendPhoneNumberCountdownNumber
        }
        this.inputRef1 = React.createRef(null)
        this.inputRef2 = React.createRef()
        this.inputRef3 = React.createRef()
        this.inputRef4 = React.createRef()
        this.inputRef5 = React.createRef()
        this.inputRef6 = React.createRef()
    }

    componentDidMount = () => {

    }
    backToInitialState = () => {
        this.props.navigate(CustomPath.login)
        this.setState({
            currentStep: initialState,
            phoneNumber: null,
            userId: null
        })
    }

    checkPhoneNumberExist = async (formData) => {
        try {
            this.setState({
                phoneNumberLoading: true,
                phoneNumber: formData.phoneNumber
            })
            const requestParams = { 'phoneNumber': formData.phoneNumber }
            const response = await ApiHandlerNoAuth({url: Api.get_otp, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON,requestParams})
            if (response) {
                this.setState({
                    userId: response.key,
                    currentStep: tokenState,
                    phoneNumberLoading: false,
                })
                this.handleButtonClickWithDelay()
            }
        } catch (error) {
            this.setState({
                phoneNumberLoading: false
            })
        }
    }

    resendCheckPhoneNumberExist = async () => {
        const { phoneNumber } = this.state
        try {
            this.setState({
                resendPhoneNumberLoading: true
            })
            const requestParams = { 'phoneNumber': phoneNumber }
            const response = await ApiHandlerNoAuth({url: Api.get_otp, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams})
            if (response) {
                this.setState({
                    userId: response.key,
                    resendPhoneNumberLoading: false,
                    resendPhoneNumberEnable: false,
                    countdown: resendPhoneNumberCountdownNumber
                })
                this.handleButtonClickWithDelay()
            }
        } catch (error) {
            this.setState({
                resendPhoneNumberLoading: false,
                resendPhoneNumberEnable: false,
                countdown: resendPhoneNumberCountdownNumber
            })
        }
    }

    confirmOTP = async () => {
        const { userId } = this.state
        let otp = ''
        if (this.inputRef1.current.value && this.inputRef2.current.value && this.inputRef3.current.value
            && this.inputRef4.current.value && this.inputRef5.current.value && this.inputRef6.current.value) {
            otp = '' + this.inputRef1.current.value + this.inputRef2.current.value + this.inputRef3.current.value
                + this.inputRef4.current.value + this.inputRef5.current.value + this.inputRef6.current.value
        } else {
            message.error("Please enter 6 digit")
            return
        }

        try {
            this.setState({
                tokenLoading: true
            })
            const requestParams = { 'otp': otp, 'key': userId + '' }
            const response = await ApiHandlerNoAuth({url: Api.check_otp, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON,requestParams})
            if (response) {
                this.setState({
                    userId: response.key,
                    currentStep: passwordConfrimState,
                    tokenLoading: false,
                    resendPhoneNumberEnable: false
                })
            }
        } catch (error) {

            this.setState({
                tokenLoading: false
            })
        }
    }

    confirmPassword = async (formData) => {
        const { userId } = this.state
        try {
            if (formData.newPassword === formData.confirmPassword) {
                try {
                    this.setState({
                        confirmLoading: true
                    })
                    const requestParams = { 'password': formData.confirmPassword, 'id': userId }
                    // const response = await ApiHandlerNoAuth(Api.forgetPassword, HTTP_METHOD.POST, MEDIA_TYPE.JSON,null,null, param)
                    const response = await ApiHandlerNoAuth({url: Api.forgetPassword, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON,requestParams})
                    if (response) {
                        this.setState({
                            userId: response.key,
                            currentStep: passwordConfrimState,
                            tokenLoading: false
                        })
                        this.props.navigate(CustomPath.login)
                    }
                } catch (error) {

                    this.setState({
                        tokenLoading: false
                    })
                }
            } else {
                message.destroy()
                message.error("Password does not match")
                this.setState({
                    tokenLoading: false
                })
            }
        } catch (error) {
            message.destroy()
            message.error("Something Wrong Please")
            this.setState({
                tokenLoading: false
            })
        }

    }

    handleButtonClickWithDelay = () => {
        const countdownInterval = setInterval(() => {
            const { countdown } = this.state;
            if (countdown === 0) {
                this.setState({ resendPhoneNumberEnable: true, countdown: 0 });
                clearInterval(countdownInterval);
            } else {
                this.setState({ countdown: countdown - 1 });
            }
        }, 1000);
    };

    handleTokenChange = (event, key) => {
        if (key === 1 && event.target.value) {
            if (this.checkIsNumberOrNot(event.target.value)) {
                this.inputRef2.current.focus()
            } else {
                this.inputRef1.current.value = null
            }
        } else if (key === 2 && event.target.value) {
            if (this.checkIsNumberOrNot(event.target.value)) {
                this.inputRef3.current.focus()
            } else {
                this.inputRef2.current.value = null
            }
        } else if (key === 3 && event.target.value) {
            if (this.checkIsNumberOrNot(event.target.value)) {
                this.inputRef4.current.focus()
            } else {
                this.inputRef3.current.value = null
            }
        } else if (key === 4 && event.target.value) {
            if (this.checkIsNumberOrNot(event.target.value)) {
                this.inputRef5.current.focus()
            } else {
                this.inputRef4.current.value = null
            }
        } else if (key === 5 && event.target.value) {
            if (this.checkIsNumberOrNot(event.target.value)) {
                this.inputRef6.current.focus()
            } else {
                this.inputRef5.current.value = null
            }
        } else if (key === 6 && event.target.value) {
            if (this.checkIsNumberOrNot(event.target.value)) {
                this.inputRef6.current.blur()
            } else {
                this.inputRef6.current.value = null
            }
        }
    }

    inputdelete = (event, key) => {
        if (event.key === 'Backspace') {
            if (key === 2 && this.inputRef2.current.value === '') {
                this.inputRef1.current.focus()
            } else if (key === 3 && this.inputRef3.current.value === '') {
                this.inputRef2.current.focus()
            } else if (key === 4 && this.inputRef4.current.value === '') {
                this.inputRef3.current.focus()
            } else if (key === 5 && this.inputRef5.current.value === '') {
                this.inputRef4.current.focus()
            } else if (key === 6 && this.inputRef6.current.value === '') {
                this.inputRef5.current.focus()
            }

        }
    }

    checkIsNumberOrNot = (value) => {
        const numberPattern = /^[0-9]+$/;
        if (!value || !numberPattern.test(value)) {
            return false
        } else {
            return true
        }
    }

    render() {
        const { currentStep, phoneNumberLoading, tokenLoading, confirmLoading, visiblePassword, countdown, resendPhoneNumberEnable, resendPhoneNumberLoading } = this.state
        return (
            <>
                <Row style={{ height: '100vh' }}>
                    <Col lg={8} md={8} sm={0} xs={0} />
                    <Col lg={8} md={8} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {
                            currentStep === initialState ?
                                <Row>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <Image
                                            width={200}
                                            height={55}
                                            src={logo}
                                            preview={false}
                                        />
                                        <div style={{ marginTop: 30, fontSize: 20, fontWeight: '700' }}>{i18next.t("forgot_password")}</div>
                                        <div style={{ marginTop: 10, fontSize: 17 }}>{i18next.t("otp_code_sent_to_phone_number")}</div>
                                        <Form
                                            name="normal_login"
                                            className="login-form"
                                            onFinish={this.checkPhoneNumberExist}
                                            ref={this.formRef}
                                        >
                                            <Form.Item
                                                style={{ marginBottom: 30 }}
                                                name="phoneNumber"
                                            >
                                                <NJVInput
                                                    className="login-box"
                                                    style={{ background: '#f5f5f5', border: 'none', borderRadius: 12, marginTop: 50 }}
                                                    prefix={<SvgMobileFilled width={20} height={20} color={"#898989"} />}
                                                    placeholder={i18next.t("verify_phone_number")}
                                                    onChange={this.handleInputChange}
                                                />

                                            </Form.Item>
                                            <Form.Item>
                                                <NJVButton style={{ backgroundColor: Theme.colors.primary, width: 150 }} type="primary" size="large" htmlType="submit" loading={phoneNumberLoading}>
                                                    {i18next.t("continue")}
                                                </NJVButton>
                                                <br />
                                                <NJVButton style={{ width: 150, marginTop: 20 }} type="text" size="large" onClick={() => this.backToInitialState()}>
                                                    {i18next.t("go_back_to_home")}
                                                </NJVButton>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                                :
                                <>
                                    {
                                        currentStep === tokenState ?
                                            <Row>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Image
                                                        width={200}
                                                        height={55}
                                                        src={logo}
                                                        preview={false}
                                                    />
                                                    <div style={{ marginTop: 30, fontSize: 20, fontWeight: '700' }}>OTP Verification</div>
                                                    <div style={{ marginTop: 10, fontSize: 17 }}>{i18next.t("otp_code_enter_to_phone_number")}</div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 50 }}>
                                                        <input
                                                            onChange={(e) => this.handleTokenChange(e, 1)}
                                                            on
                                                            maxLength={1}
                                                            onKeyDown={(e) => this.inputdelete(e, 1)}
                                                            ref={this.inputRef1}
                                                            style={tokenInputStyle}
                                                        />
                                                        <input
                                                            onChange={(e) => this.handleTokenChange(e, 2)}
                                                            onKeyDown={(e) => this.inputdelete(e, 2)}
                                                            maxLength={1}
                                                            ref={this.inputRef2}
                                                            style={tokenInputStyle}
                                                        />
                                                        <input
                                                            onChange={(e) => this.handleTokenChange(e, 3)}
                                                            onKeyDown={(e) => this.inputdelete(e, 3)}
                                                            maxLength={1}

                                                            ref={this.inputRef3}
                                                            style={tokenInputStyle}
                                                        />
                                                        <input
                                                            onChange={(e) => this.handleTokenChange(e, 4)}
                                                            onKeyDown={(e) => this.inputdelete(e, 4)}
                                                            maxLength={1}
                                                            ref={this.inputRef4}
                                                            style={tokenInputStyle}
                                                        />
                                                        <input
                                                            onChange={(e) => this.handleTokenChange(e, 5)}
                                                            onKeyDown={(e) => this.inputdelete(e, 5)}
                                                            maxLength={1}
                                                            ref={this.inputRef5}
                                                            style={tokenInputStyle}
                                                        />
                                                        <input
                                                            onChange={(e) => this.handleTokenChange(e, 6)}
                                                            onKeyDown={(e) => this.inputdelete(e, 6)}
                                                            maxLength={1}
                                                            ref={this.inputRef6}
                                                            style={tokenInputStyle}
                                                        />
                                                    </div>
                                                    <div style={{ marginTop: 20 }}>

                                                        {
                                                            resendPhoneNumberEnable ?
                                                                <NJVButton style={{ width: 150, marginTop: 20 }} type="text" size="large" loading={resendPhoneNumberLoading} onClick={() => this.resendCheckPhoneNumberExist()}>
                                                                    {i18next.t("resend_code")}
                                                                </NJVButton>
                                                                :
                                                                <span>
                                                                    {i18next.t("code_resend_info")} {countdown}s
                                                                </span>
                                                        }
                                                    </div>
                                                    <div style={{ marginTop: 30 }}>
                                                        <NJVButton style={{ backgroundColor: Theme.colors.primary, width: 150 }} type="primary" size="large" onClick={() => this.confirmOTP()} loading={tokenLoading}>
                                                            {i18next.t("continue")}
                                                        </NJVButton>
                                                    </div>
                                                </Col>
                                            </Row>
                                            :
                                            <Row style={{ width: '80%', textAlign: 'center' }}>
                                                <Col span={24}>
                                                    <Image
                                                        width={200}
                                                        height={55}
                                                        src={logo}
                                                        preview={false}
                                                    />
                                                    <div style={{ marginTop: 30, marginBottom: 30, fontSize: 20, fontWeight: '700' }}>{i18next.t("enter_new_password")}</div>
                                                    <Form
                                                        name="normal_login"
                                                        onFinish={this.confirmPassword}
                                                        ref={this.formRef}
                                                    >
                                                        <Form.Item
                                                            style={{ marginBottom: 10 }}
                                                            name="newPassword"
                                                        >
                                                            <NJVInput
                                                                className="login-box"
                                                                style={{ background: '#f5f5f5', border: 'none', borderRadius: 12 }}
                                                                prefix={<SvgLockFilled width={20} height={20} color={"#898989"} />}
                                                                type={visiblePassword ? "text" : "password"}
                                                                placeholder={i18next.t("new_password")}
                                                                ispasswordinput={true}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 10 }}
                                                            name="confirmPassword"
                                                        >
                                                            <NJVInput
                                                                className="login-box"
                                                                style={{ background: '#f5f5f5', border: 'none', borderRadius: 12, marginTop: 15 }}
                                                                prefix={<SvgLockFilled width={20} height={20} color={"#898989"} />}
                                                                type={visiblePassword ? "text" : "password"}
                                                                placeholder={i18next.t("confirm_password")}
                                                                ispasswordinput={true}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <NJVButton style={{ backgroundColor: Theme.colors.primary, width: 150, marginTop: 30 }} type="primary" size="large" htmlType="submit" loading={confirmLoading} >
                                                                {i18next.t("confirm")}
                                                            </NJVButton><br />
                                                            <NJVButton style={{ width: 150, marginTop: 20 }} type="text" size="large" onClick={() => this.backToInitialState()}>
                                                                {i18next.t("previous")}
                                                            </NJVButton>

                                                        </Form.Item>
                                                    </Form>
                                                </Col>
                                            </Row>
                                    }
                                </>
                        }
                    </Col>
                </Row >
            </>
        )
    }
}

export default compose(withRouter)(ForgotPassword)