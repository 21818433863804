import React, { Component } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import '../css/order-create.css'

class NJVDatePicker extends Component {
    handleDateChange = (date, dateString) => {
        const { name, onChange } = this.props;
        if (onChange) {
            onChange({ date, dateString, name });
        }
    };

    getValidDate = () => {
        const currentDate = dayjs();
        const targetTime = dayjs().set('hour', 14).set('minute', 0).set('second', 0);
        const isBefore2PM = currentDate.isBefore(targetTime);
        if (isBefore2PM) {
            return dayjs().startOf('day');
        } else {
            return dayjs().endOf('day');
        }
    }

    disabledDate = (current) => {
        return current && current < this.getValidDate();
    };

    render() {
        const { name, ...otherProps } = this.props;

        return (
            <DatePicker
                // className="my-date-picker"
                {...otherProps}
                onChange={this.handleDateChange}
                disabledDate={this.disabledDate}
                size="large"
                style={{ width: '100%', cursor: 'pointer', backgroundColor: 'white' }}
                format="YYYY-MM-DD" // Customize the date format if needed
            />
        );
    }
}

export default NJVDatePicker;
