import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { Outlet } from 'react-router-dom';

const AppContent = () => {

    return (
        <Content
            style={{
                padding: '30px 0px',
            }}
        >
            <Outlet />
        </Content>
    )
}

export default AppContent