import { compose } from "@reduxjs/toolkit";
import { Col, Divider, Image, Row, Skeleton } from "antd";
import React from "react";
import Api from "../network/api";
import { HTTP_METHOD } from "../network/httpMethod";
import { MEDIA_TYPE } from "../network/mediaType";
import { ApiHandler } from "../network/network-manager";
import withRouter from "../network/with-router";
import { updateProfile } from "../redux/profile-slice";
import i18next from "i18next";

const CardDisplayLabel = (props) => {
    const { label, value, style } = props
    return <Col span={24} style={{ background: '#F8F5F5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '14px 10px', marginBottom: 2, ...style }}>
        <div style={{ fontSize: 15, fontWeight: '400' }}>
            {label}
        </div>
        <div style={{ fontSize: 15, fontWeight: '400' }}>
            {value}
        </div>
    </Col>
}

class ProfilePage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: this.props?.shipperData,
        }
    }

    render() {
        const { data } = this.state
        console.log(data);
        
        return (
            <Row>
                <Col lg={5} md={5} sm={1} xs={1} />
                <Col lg={14} md={14} sm={22} xs={22}>
                    <Row>
                        <Col span={24}>
                            <h1>Profile</h1>
                            <Divider />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    <h3 style={{ marginTop: 0 }}>My Information</h3>
                                </Col>
                                <CardDisplayLabel label={"Unique ID"} value={data.uniqueId} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} />
                                <CardDisplayLabel label={"Full Name"} value={data.fullName} />
                                <CardDisplayLabel label={"Phone Number"} value={data.phoneNumber} />
                                <CardDisplayLabel label={"Email Address"} value={data.emailAddress} />
                                <CardDisplayLabel label={"Address"} value={data.address1} style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} />
                            </Row>
                            <br />
                            <br />
                            {
                                data.business ?
                                    <Row>
                                        <Col span={24}>
                                            <h3>Business Information</h3>
                                        </Col>
                                        <CardDisplayLabel label={"Business Name"} value={data.business.businessName} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} />

                                        {
                                            data.business.productCategory ?
                                                <CardDisplayLabel label={"Business Type"} value={data.business.productCategory.name} />
                                                :
                                                <></>
                                        }
                                        <CardDisplayLabel label={"E-commerce Registration Number"} value={data.business.ecommerceRegNumber} />
                                        <CardDisplayLabel label={"Business Owner NRC"} value={data.business.businessOwnerNRC} />
                                        <CardDisplayLabel label={"Facebook Page Link"} value={data.business.facebookPageLink} />
                                        <CardDisplayLabel label={"Primary Payment Type"} value={data.business.paymentType} />
                                        <CardDisplayLabel label={"Primary Payment Number"} value={data.business.paymentNumber} style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} />
                                        <Col span={24} style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                            <h3>{i18next.t("bank_acc_photo")}</h3>
                                            {
                                               data.business.bankBookImg ? <Image width={200} height={200} src={data.business.bankBookImg} style={{borderRadius: 8}} /> : <div style={{width: 200,height: 150, backgroundColor: '#f1f1f1', borderRadius: 8,display: 'flex',justifyContent: 'center', alignItems: 'center'}}>No Image</div> 
                                            }
                                            
                                        </Col>
                                    </Row>
                                    :
                                    <></>
                            }
                        </Col>
                    </Row>
                </Col >
            </Row >
        )
    }
}

export default compose(withRouter)(ProfilePage)