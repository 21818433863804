import { compose } from "@reduxjs/toolkit";
import { Card, Col, Image, Row, Skeleton, Tag, message } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import React from "react";
import { NJVButton, NJVInput, NJVSpacer } from "../../components/core-component";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const NJVLabel = ({ label }) => {
    return (
        <>
            <span style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</span>
            <br />
        </>)

}

const NJVValue = ({ value }) => {
    return (
        <>
            <span style={{ fontSize: 16, fontWeight: '500', color: 'black' }}>{value}</span>
            <br />
        </>)
}

const StepTitileStyle = ({ title, date }) => {
    return (<>
        {
            date ?
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}><span>{title}</span> <Tag>{date}</Tag></div>
                :
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}><span>{title}</span></div>
        }
    </>)
}




class RTSOrderDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            order_object: props.location.state?.data || {},
            data: {},
            isDataFetching: false,
            show_request_support_form: false,
            is_submit_request: false,
            is_confirm_rts: false

        }
        this.inputRef = React.createRef();

    }

    componentDidMount() {
        this.setState({
            isDataFetching: true
        })
        this.fetchData()
    }



    fetchData = async () => {
        const { order_object } = this.state
        try {
            const requestParams = {
                trackingNumber: order_object.id
            }
            const response = await ApiHandler({url: Api.order_get_by_tracking_number,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams})
            if (response) {
                let data = response
                let fromAddress = data.fromAddress
                let toAddress = data.toAddress
                if (data && data.fromTownship) {
                    fromAddress = fromAddress + "," + data.fromTownship.name
                    if (data.fromTownship.division) {
                        fromAddress = fromAddress + "," + data.fromTownship.division.name
                    }
                }
                if (data && data.toTownship) {
                    toAddress = toAddress + "," + data.toTownship.name
                    if (data.toTownship.division) {
                        toAddress = toAddress + "," + data.toTownship.division.name
                    }
                }
                data = { ...data, senderAddress: fromAddress, recipientAddress: toAddress }

                if (data.createdDate != null) {
                    const createdDate = dayjs(data.createdDate, 'YYYY-MM-DD HH:mm A');
                    if (createdDate) {
                        data = { ...data, 'createdDate': createdDate.format('YYYY-MMM-DD hh:mm A') }
                    }
                }
                let currentStep = 0

                let timeMap = {}
                let steps = []

                this.setState({
                    data: data,
                    orderEvent: response.orderEvents ? response.orderEvents : [],
                    currentStep: currentStep,
                    orderSteps: steps
                })
            }
        } catch (error) {

        }
        this.setState({
            isDataFetching: false,
        })
    }

    submitRequestSupport = async () => {
        const { form_reason, data } = this.state
        this.setState({ is_submit_request: true })
        try {
            let formData = new FormData();
            formData.append('trackingNumber', data.trackingNumber)
            formData.append('category', "Request Support")
            formData.append('subCategory', "Return to Sender")
            formData.append('reason', form_reason)
            formData.append('type', 'RETURN_ORDER')
            const response = await ApiHandler({url: Api.order_issue,method: HTTP_METHOD.POST,mediaType: MEDIA_TYPE.FORM_DATA,requestData: formData})
            if (response && response.data) {
                message.success(i18next.t('lb_ticket_successfully_submitted'))
                this.confirmRTS('REJECT')
            }
        } catch (error) {
        }

    }

    confirmRTS = async (rtsStatus) => {
        const { order_object } = this.state
        this.setState({ is_confirm_rts: true })
        try {
            const formData = {
                status: rtsStatus,
                id: order_object.rts_id
            }
            const response = await ApiHandler({url: Api.order_rts_update_status,method: HTTP_METHOD.PUT,mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData})
            if (response && response.data) {
                this.setState({
                    isFormSubmittedSuccess: true,
                })
                this.props.navigate(CustomPath.rts_order)
            }
        } catch (error) {
        }
    }

    render() {
        const { data, isDataFetching, show_request_support_form, form_reason, is_confirm_rts, is_submit_request } = this.state

        return (
            <>
                <Row style={{ marginBottom: 200, paddingBottom: 200 }}>
                    <Col lg={6} md={6} xs={1} sm={1} />
                    <Col lg={12} md={6} xs={22} sm={22}>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <h2>{i18next.t("rts_parcel_detail")}</h2>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <NJVButton type="primary" onClick={() => this.props.navigate(CustomPath.rts_order)}>Back</NJVButton>
                            </Col>
                        </Row>
                        <NJVSpacer height={20} />
                        {
                            isDataFetching ?
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <Skeleton active />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    {
                                        data ?
                                            <div>
                                                {
                                                    data.orderStatus === 'CANCELLED' ?
                                                        <>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Card
                                                                        style={{ backgroundColor: "red", boxShadow: 'none' }}
                                                                        bordered={false}>
                                                                        <span style={{ fontSize: 13, fontWeight: '600', color: 'white' }}>This order has been cancelled</span>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                            <NJVSpacer height={20} />
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                                <Row>
                                                    <Col span={24}>

                                                        <Card
                                                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                            bordered={false}>
                                                            <NJVLabel label={i18next.t("tracking_number")} />
                                                            <NJVValue value={data.trackingNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.fromAddress && data.fromTownship ?
                                                                    <>
                                                                        <NJVLabel label={i18next.t("pickup_address")} />
                                                                        <NJVValue value={`${data.fromAddress}, ${data.fromTownship.name}, ${data.fromTownship.name} `} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }


                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <NJVSpacer height={20} />
                                                <Row>
                                                    <Col span={24}>
                                                        <Card
                                                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                            bordered={false}>
                                                            <NJVLabel label={i18next.t("receiver_name")} />
                                                            <NJVValue value={`${data.recipientName}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("receiver_phone_number")} />
                                                            <NJVValue value={data.recipientPhoneNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.toAddress && data.toTownship ?
                                                                    <>
                                                                        <NJVLabel label={i18next.t("receiver_address")} />
                                                                        <NJVValue value={`${data.toAddress}, ${data.toTownship.name}, ${data.toTownship.division.name}`} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <NJVSpacer height={20} />
                                                <Row>
                                                    <Col span={24}>
                                                        <Card
                                                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                            bordered={false}>
                                                            <NJVSpacer height={30} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("pickup_time")} />
                                                            <NJVValue value={`${data.pickupDate}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("order_type")} />
                                                            <NJVValue value={`${data.orderType}`} />
                                                            {
                                                                data.paymentOption ?
                                                                    <>
                                                                        <NJVSpacer height={30} />
                                                                        <NJVLabel label={i18next.t("payment_option")} />
                                                                        <NJVValue value={`${data.paymentOption}`} />
                                                                    </>
                                                                    :
                                                                    <></>

                                                            }
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="Note" />
                                                            <NJVValue value={`${data.note ? data.note : '------'}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("cod")} />
                                                            {
                                                                data.codAmount ?
                                                                    <NJVValue value={`${data.codAmount} MMK`} />
                                                                    :
                                                                    <NJVValue value={`0 MMK`} />
                                                            }

                                                            <NJVSpacer height={30} />

                                                            {
                                                                data && data.updateDeliveryCharges ?
                                                                    <>
                                                                        <NJVLabel label="Updated Delivery Charges" />
                                                                        <NJVValue value={`${data.updateDeliveryCharges}`} />
                                                                        <NJVSpacer height={30} />
                                                                    </> :
                                                                    <></>
                                                            }
                                                            {
                                                                data && data.updateCodAmount ?
                                                                    <>
                                                                        <NJVLabel label="Updated Cod Amount" />
                                                                        <NJVValue value={`${data.updateCodAmount}`} />
                                                                        <NJVSpacer height={30} />
                                                                    </> :
                                                                    <></>
                                                            }

                                                            {
                                                                data.comment ?
                                                                    <>
                                                                        <NJVSpacer height={30} />
                                                                        <NJVLabel label={i18next.t("fail_reason")} />
                                                                        <NJVValue value={data.comment} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }


                                                            <NJVSpacer height={30} />
                                                            <div style={{ display: 'flex' }}>
                                                                <di style={{ marginRight: 10 }}>
                                                                    <NJVLabel label="Product Image" />
                                                                    {
                                                                        data.productImageUrl ?

                                                                            <Image
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    border: '1px solid grey'
                                                                                }}
                                                                                onClick={() => this.setState({ viewPhoto: true })}
                                                                                src={data.productImageUrl}
                                                                                // preview={false}
                                                                                width={200}
                                                                            />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }
                                                                </di>
                                                                <div>
                                                                    <NJVLabel label="Package Image" />
                                                                    {
                                                                        data.packageImageUrl ?
                                                                            <Image
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    border: '1px solid grey'
                                                                                }}
                                                                                onClick={() => this.setState({ viewPhoto: true })}
                                                                                src={data.productImageUrl}
                                                                                // preview={false}
                                                                                width={200}
                                                                            />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }


                                                                </div>
                                                            </div>
                                                            <br />
                                                            {
                                                                data.orderType === 'Bus Gate' ?
                                                                    <>
                                                                        <NJVLabel label="Bus Gate Voucher" />
                                                                        {
                                                                            data.busGateVoucherImagePath ?
                                                                                <Image
                                                                                    style={{
                                                                                        marginTop: 5,
                                                                                        borderRadius: 9,
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => this.setState({ viewPhoto: true })}
                                                                                    src={data.busGateVoucherImagePath}
                                                                                    width={200}
                                                                                />
                                                                                :
                                                                                <div
                                                                                    style={{
                                                                                        marginTop: 5,
                                                                                        borderRadius: 9,
                                                                                        cursor: 'pointer',
                                                                                        padding: 10,
                                                                                        backgroundColor: '#f1f1f1',
                                                                                        border: '1px solid #f1f1f1',
                                                                                        width: 200,
                                                                                        height: 200,
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center'
                                                                                    }}
                                                                                >
                                                                                    <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                                </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }


                                                        </Card>
                                                    </Col>
                                                </Row>
                                                {
                                                    data.issue ?
                                                        <>
                                                        </>
                                                        :
                                                        <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
                                                            <Col span={12}>
                                                                <NJVButton type="primary" size="large" shape="square" style={{ backgroundColor: '#f5f5f5', color: 'black', width: '100%' }} onClick={() => { this.setState({ show_request_support_form: true }) }} >Request Support</NJVButton>
                                                            </Col>
                                                            <Col span={12}>
                                                                <NJVButton type="primary" size="large" shape="square" style={{ backgroundColor: Theme.colors.primary, width: '100%' }} loading={is_confirm_rts} onClick={() => this.confirmRTS('CONFIRM')}> OK</NJVButton>
                                                            </Col>
                                                            {
                                                                show_request_support_form ?
                                                                    <>
                                                                        <NJVSpacer height={50} />
                                                                        <Col span={24}>
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                <span style={{ fontSize: 16, fontWeight: '600' }}>{i18next.t("request_support")}</span>
                                                                                <NJVButton type="primary" onClick={() => this.setState({ show_request_support_form: false })}>Close</NJVButton>
                                                                            </div>
                                                                        </Col>
                                                                        <Col span={24}>
                                                                            <NJVInput value={form_reason} bgcolor={Theme.colors.secondary} onChange={(e) => this.setState({ form_reason: e.target.value })} placeholder={i18next.t("enter_reason")} style={{ backgroundColor: '#f5f5f5', height: 100 }} istextareainput="true" />
                                                                        </Col>
                                                                        <Col span={24}>
                                                                            <NJVButton type="primary" size="large" shape="square" style={{ backgroundColor: Theme.colors.primary, width: '100%' }} onClick={() => this.submitRequestSupport()} loading={is_submit_request}>Submit</NJVButton>
                                                                        </Col>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </Row>

                                                }




                                            </div>
                                            :
                                            <></>
                                    }
                                </>
                        }
                    </Col>

                </Row >
            </>
        )
    }

}

export default compose(withRouter)(RTSOrderDetail)