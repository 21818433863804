import { compose } from '@reduxjs/toolkit';
import { Col, Image, Layout, Row } from 'antd';
import cookies from "js-cookie";
import React, { Component } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { SvgMobileFilled, SvgMyanmarFlag, SvgUKFlag } from '../../components/custom-svgIcon';
import { NJVPhotoURL } from "../../core/constant";
import withRouter from '../../network/with-router';
import CustomPath from "../../routes/custom-path";
import i18next from 'i18next';

const { Content } = Layout;
const logo = require('../../asset/Ninjavan-logo-red-white.png')

class PublicApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLanguage: cookies.get("i18next") || "en"
        }
    }

    changeLanguage = () => {
        const { currentLanguage } = this.state
        if (currentLanguage === 'en') {
            i18next.changeLanguage("mm")
            this.setState({ currentLanguage: 'mm' })
        } else if (currentLanguage === 'mm') {
            i18next.changeLanguage("en")
            this.setState({ currentLanguage: 'en' })
        }
    }

    render() {
        const { currentLanguage } = this.state;

        return (
            <>
                <div style={{
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    top: 0,
                    zIndex: 2,
                    background: '#000'
                }}>
                    <Row>
                        <Col lg={6} md={4} sm={1} xs={1}></Col>
                        <Col lg={12} md={16} sm={22} xs={22}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Image
                                    width={140}
                                    src={logo}
                                    preview={false}
                                />
                                <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.changeLanguage()}>
                                    {
                                        currentLanguage === 'en' ?
                                            <SvgUKFlag width={35} height={35} />
                                            :
                                            <SvgMyanmarFlag width={35} height={35} />
                                    }

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                {/* <div
                    style={{
                        height: "70px",
                        display: 'flex',
                        alignItems: 'center',
                        position: 'fixed',
                        left: 0,
                        right: 0,
                        top: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        background: '#ffffff',
                        zIndex: 2,
                    }}
                >
                    <Row style={{ width: '100%' }}>
                        <Col lg={6} md={4} sm={1} xs={1} />
                        <Col lg={7} md={10} sm={20} xs={20} >
                            <Link to={CustomPath.dashboard}>
                                <Image
                                    width={200}
                                    src={NJVPhotoURL.logo_url}
                                    preview={false}
                                />
                            </Link>
                        </Col>
                        <Col lg={5} md={6} sm={2} xs={2} style={{ textAlign: 'right' }}>
                            <div
                                style={{ cursor: 'pointer', marginRight: 7, marginTop: 6 }}
                                onClick={() => this.changeLanguage()}>
                                {
                                    currentLanguage === 'en' ?
                                        <SvgUKFlag width={35} height={35} />
                                        :
                                        <SvgMyanmarFlag width={35} height={35} />
                                }

                            </div>
                        </Col>
                    </Row>
                </div> */}
                < Layout >
                    <Content style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'white',
                        marginTop: 50,
                        marginBottom: 50,
                        overflow: 'auto',
                        padding: '30px 0 20px',
                    }}>
                        <Row>
                            <Col lg={3} md={1} xs={0} sm={0} />
                            <Col lg={18} md={22} xs={24} sm={24}>
                                <Outlet />
                            </Col>
                        </Row>
                    </Content>
                </Layout >
                <Row style={{ position: 'fixed', bottom: 0, left: 0, right: 0, background: '#000', alignItems: 'center', marginTop: 20 }}>
                    <Col lg={24} md={24} sm={0} xs={0}>
                        <div style={{ display: 'flex', alignItems: 'center', color: '#fff', gap: 30, marginBlock: 15, justifyContent: 'center' }}>
                            <span style={{ textTransform: 'uppercase', fontSize: 18, fontWeight: 500 }}>Get In Touch</span>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                <SvgMobileFilled width={20} height={20} color="#fff" />
                                <span onClick={() => window.location.href = 'tel:+959777111001'} style={{ cursor: 'pointer' }}>
                                    +95 9 777 111 001
                                </span>
                            </div>
                            {/* <div style={{display: 'flex',alignItems: 'center',gap: 7}}>
                                <MailOutlined style={{ fontSize: 18 }} />
                                <Link target='_blank' style={{ color: '#fff' }} to='https://mail.google.com/mail/?view=cm&fs=1&to=support_mm@ninjavan.co'>support_mm@ninjavan.co</Link>
                            </div> */}
                        </div>
                    </Col>
                    <Col lg={0} md={0} sm={24} xs={24}>
                        <div style={{ display: 'flex', alignItems: 'center', color: '#fff', gap: 5, flexDirection: 'column', paddingBlock: 8 }}>
                            <span style={{ textTransform: 'uppercase', fontWeight: 500 }}>Get In Touch</span>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                <SvgMobileFilled width={18} height={18} color="#fff" />
                                <span onClick={() => window.location.href = 'tel:+959777111001'} style={{ cursor: 'pointer' }}>
                                    +95 9 777 111 001
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(PublicApp)