import { PlusCircleFilled } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Col, Row, Tabs } from "antd";
import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { NJVButton } from "../../components/core-component";
import { TitleLevel3 } from "../../components/general-component";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import LocationList from "./location-list";
import { updateStatusKey } from "../../redux/profile-slice";

class UserAddressPage extends React.Component {

    onChange = (key) => {
        if(key === '1'){
            this.props.dispatch(updateStatusKey("myLocation"))
        }else if(key === '2'){
            this.props.dispatch(updateStatusKey("royalCustomer"))
        }
    }

    render() {

        const items = [
            {
                key: '1',
                label: <span style={{ fontSize: 15, fontWeight: '500' }}>{i18next.t("my_address")}</span>,
                children: <LocationList status="myLocation" />,
            },
            {
                key: '2',
                label: <span style={{ fontSize: 15, fontWeight: '500' }}>{i18next.t("loyal_customer")}</span>,
                children: <LocationList status="royalCustomer" />,
            },
        ];

        return (
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <TitleLevel3 label={i18next.t("fav_location")} />
                        </Col>
                        <Col span={12} style={{ textAlign: "end" }}>
                            <Link to={CustomPath.user_address_create}>
                                <NJVButton
                                    type="primary"
                                    icon={<PlusCircleFilled />}
                                    shape="round"
                                >
                                    {i18next.t("create_new_location")}
                                </NJVButton>
                            </Link>
                        </Col>
                        <Col span={24}>
                            <Tabs defaultActiveKey={`${this.props?.statusKey?.myLocation ? '1' : this.props?.statusKey?.royalCustomer ? '2' : '1' }`} items={items} onChange={this.onChange} />
                        </Col>
                    </Row>
                </Col>
            </Row >
        )
    }
}
export default compose(withRouter)(UserAddressPage);