import { compose } from '@reduxjs/toolkit';
import { Badge, Col, ConfigProvider, Image, Layout, Row } from 'antd';
import React, { Component } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { SvgCartFilled } from '../../components/custom-svgIcon';
import Theme from '../../components/theme';
import withRouter from '../../network/with-router';
import CustomPath from '../../routes/custom-path';
import { ApiHandlerNoAuth } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';

const { Content } = Layout;
const logo = require('../../asset/Ninjavan-logo-red-white.png')
const default_profile = require('../../asset/shipper_profile.png')

class PublicProductApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shipperInfo: null
        }
    }

    componentDidMount = () => {
        this.fetchShipperInfo();
    }


    fetchShipperInfo = async () => {
        // await ApiHandlerNoAuth(Api.public_shipper_info, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, this.props.params.uniqueId).then((response) => {
        await ApiHandlerNoAuth({url: Api.public_shipper_info,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON,specificId: this.props.params.uniqueId}).then((response) => {
            this.setState({
                shipperInfo: response
            })
        }).catch(error => console.log(error))
    }

    render() {
        const { cartItems } = this.props;
        const isSameShipper = this.props.params.uniqueId !== Object.keys(cartItems)[0];
        const { shipperInfo } = this.state

        return (
            <>
                <div style={{
                    position: 'fixed',
                    // height: 70,
                    left: 0,
                    right: 0,
                    top: 0,
                    zIndex: 2
                }}>
                    <Row>
                        <Col lg={3} md={1} sm={0} xs={0}></Col>
                        <Col lg={18} md={22} sm={24} xs={24}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10, paddingRight: 30, alignItems: 'center' }}>
                                <Link to={`${CustomPath.products}/${this.props.params.uniqueId}`}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Image style={{ borderRadius: '50%' }}
                                            width={30}
                                            src={shipperInfo?.profileUrl ? shipperInfo?.profileUrl : default_profile}
                                            preview={false}
                                        />
                                        <span
                                            style={{ fontSize: 20, fontWeight: '600', color: '#000', marginLeft: 10 }}
                                        >{shipperInfo?.businessName}</span>
                                    </div>
                                </Link>
                                {Object.values(cartItems)[0] ? <Link to={`/public/store/${this.props.params.uniqueId}/cart-items`}>
                                    <Badge count={isSameShipper ? 0 : Object.values(cartItems)[0].length} color='#000' style={{ background: '#fff' }}>
                                        <SvgCartFilled width={22} height={22} color={Theme.colors.primary} />
                                    </Badge>
                                </Link> :  <Badge count={0} color='#000' style={{ background: '#fff' }}>
                                        <SvgCartFilled width={22} height={22} color={Theme.colors.primary} />
                                    </Badge>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                <Layout>
                    <Content style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'white',
                        marginTop: 50,
                        marginBottom: 50,
                        overflow: 'auto',
                        padding: '30px 24px 40px',
                    }}>

                        <Row>
                            <Col lg={3} md={1} xs={0} sm={0} />
                            <Col lg={18} md={22} xs={24} sm={24}>
                                <Outlet />
                            </Col>
                        </Row>

                    </Content>
                </Layout>
                <Row style={{ position: 'fixed', bottom: 0, left: 0, right: 0, background: '#000', alignItems: 'center', justifyContent: 'center', paddingBlock: 5, marginTop: 20 }}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <span style={{ color: '#fff' }}>Powered by </span><Image width={80} src={logo} preview={false} />
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(PublicProductApp)