import {
    CheckOutlined,
    CopyOutlined
} from '@ant-design/icons'
import { compose } from '@reduxjs/toolkit'
import { Col, Modal, Row, Skeleton, message } from 'antd'
import dayjs from 'dayjs'
import i18next from "i18next"
import Lottie from 'lottie-react'
import { Component, default as React } from "react"
import ShippingAnimation from '../../animate-lottie/lottie-shipping.json'
import { NJVButton, NJVDatePicker } from "../../components/core-component"
import { FormLabel } from "../../components/general-component"
import Theme from "../../components/theme"
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from "../../network/network-manager"
import withRouter from '../../network/with-router'

const CardDisplayLabel = (props) => {
    const { label, value, style, text } = props
    return <Col span={24} style={{ background: '#F8F5F5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px 10px', marginBottom: 2, flexWrap: 'wrap', ...style }}>
        <span>
            {label}
        </span>
        <span>
            {value} {text && text}
        </span>
    </Col>
}

const initialState = 1
const successState = 2

class VoucherDetailModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uniqueId: this.props?.voucherId || '',
            isExitUserGroup : this.props?.isExitUserGroup,
            data: {},
            isFetchingData: true,
            isCopied: false,
            isCreatingParcel: false,
            pickUpDate: dayjs(this.getValidDate()),
            openConfirmModal: false,
            voucherState: initialState,
            showButton: true
        }
    }

    componentDidMount = () => {
        this.fetchDetailData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.voucherId !== this.props.voucherId) {
            this.setState({ uniqueId: this.props.voucherId }, () => {
                this.fetchDetailData();
            });
        }
    }

    fetchDetailData = async () => {
        const { uniqueId } = this.state;
        let requestParams = {
            uniqueId
        }
        this.setState({
            isFetchingData: true
        })
        try {
            const response = await ApiHandler({url: Api.voucher_by_unique_id,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams})
            if (response) {
                this.setState({
                    data: response
                })
            }
        } catch (error) {
        }
        this.setState({
            isFetchingData: false
        })
    }

    handleCopy = () => {
        navigator.clipboard?.writeText(this.state.data.customerConfirmUrl).then(() => {
            this.setState({ isCopied: true });
            setTimeout(() => this.setState({ isCopied: false }), 3000)
        }).catch((error) => {
            console.log('Unable to copy: ', error);
        })
    }

    getValidDate = () => {
        const currentDate = dayjs();
        const targetTime = dayjs().set('hour', 13).set('minute', 0).set('second', 0);
        return currentDate.isBefore(targetTime) ? currentDate : currentDate.add(1, 'day');
    }

    disabledDate = (current) => {
        const now = dayjs();
        const today1PM = now.startOf('day').add(13, 'hours');
        const yesterdayEnd = now.subtract(1, 'day').endOf('day');

        if (now.isBefore(today1PM)) {
            return current && current.isBefore(yesterdayEnd);
        } else {
            return current && current.isBefore(now);
        }
    }

    handlePickupDate = (value) => {
        this.setState({
            pickUpDate: value,
        })
    }

    // routerToHome = (status) => {
    //     if (status !== 'READY') {
    //         this.props.dispatch(updateStatusKey({ isConfirm: true }))
    //     }
    //     this.props.navigate(CustomPath.confirm_order)
    // }

    handleCreateParcel = async () => {
        const { orderType, paymentOption, addressDetail, parcelAmount, codAmount, deliveryCharges, note, id, pickupLocationId } = this.state.data;
        const pickup_date = this.state.pickUpDate.format("YYYY-MM-DD");
        try {
            this.setState({
                isCreatingParcel: true,
            })
            const formData = new FormData();
            formData.append('receiverDTO.orderType', orderType)
            formData.append('receiverDTO.paymentOption', paymentOption)
            formData.append('receiverDTO.addressDetail', addressDetail)
            formData.append('receiverDTO.isSameDay', false)
            formData.append('receiverDTO.parcelAmount', parcelAmount)
            formData.append('receiverDTO.codAmount', codAmount)
            formData.append('receiverDTO.deliveryCharges', deliveryCharges)
            formData.append('receiverDTO.note', note || '')
            formData.append('pickUpDate', pickup_date)
            formData.append('voucherId', id)
            formData.append('pickupLocationId', pickupLocationId || 0)
            const response = await ApiHandler({url: Api.confirm_voucher,method: HTTP_METHOD.POST,mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData});
            if (response && response.status === 200) {
                this.setState({
                    isCreatingParcel: false,
                    openConfirmModal: false,
                    showButton: false
                })
            }
        } catch (error) {
            console.log(error);
            if (error && error.response && error.response.data.status === 500) {
                this.setState({
                    isCreatingParcel: false
                });
                message.error(error.response.data.error);
            } else if (error && error.response.data.code === "040") {
                message.error(error.response.data.message)
                this.setState({
                    isCreatingParcel: false
                });
            } else if (error && error.response.data.code === "009") {
                message.error(error.response.data.message)
                this.setState({
                    isCreatingParcel: false
                });
            }
        }
    }

    render() {
        const { isFetchingData, isCopied, isCreatingParcel, data, pickUpDate, openConfirmModal, voucherState, showButton, isExitUserGroup } = this.state;

        return (
            <>
                <Modal
                    title={null}
                    centered
                    open={openConfirmModal}
                    onOk={() => this.handleCreateParcel()}
                    footer={null}
                    closeIcon={null}
                >
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            {
                                voucherState === initialState ?
                                    <>
                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={ShippingAnimation}
                                            loop={true}
                                            autoplay={true}
                                        />
                                        <div style={{ marginBottom: 10, fontSize: 18, fontWeight: '500' }}>{i18next.t("confirm_to_create_parcel")}</div>
                                        <NJVButton type='text' style={{ marginTop: 10, marginRight: 10 }} onClick={() => this.setState({ openConfirmModal: false, currentStep: 1 })}>{i18next.t("cancel")}</NJVButton>
                                        <NJVButton type='primary' loading={isCreatingParcel} style={{ marginTop: 10 }} onClick={() => this.handleCreateParcel()}>{i18next.t("confirm")}</NJVButton>

                                    </> :
                                    <></>
                            }
                            {/* {
                                voucherState === successState ?
                                        <div style={{ width: '100%', textAlign: 'center', marginTop: 16 }}>
                                            <SvgCheck width={50} height={50} color="#00BA00" />
                                            <div style={{ fontWeight: 700, fontSize: 20 }}>Success</div>
                                            <div style={{ fontWeight: 500,marginBottom: 10 }}>Your Parcel has been created successfully.</div>
                                            <NJVButton type='primary' size="larger" style={{background: Theme.colors.primary}} onClick={() => this.props.navigate(CustomPath.confirm_order)}>Ok</NJVButton>
                                        </div>
                                        : <></>
                            } */}
                        </div>
                    </div>
                </Modal>
                <Row style={{ marginTop: 15 }}>
                    <Col span={24}>
                        {
                            isFetchingData ?
                                <Skeleton active />
                                :
                                <>
                                    {
                                        data.status === 'READY' && isExitUserGroup && (
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col lg={8} md={8} sm={12} xs={12}>
                                                    <FormLabel label={i18next.t("pickup_date")} />
                                                    <NJVDatePicker bgcolor={Theme.colors.secondary}
                                                        name={`pickupDate`}
                                                        allowClear={false}
                                                        disabledDate={(current) => this.disabledDate(current)}
                                                        defaultValue={pickUpDate}
                                                        placeholder={i18next.t("select_date")}
                                                        onChange={(value) => this.handlePickupDate(value)}
                                                        size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    <Row>
                                        <Col span={24}>
                                            <h3 style={{ marginTop: 0 }}>{i18next.t('customer_info')}</h3>
                                        </Col>
                                        {data?.customerName && <CardDisplayLabel label={i18next.t("name")} value={data.customerName} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} />}
                                        {data?.customerPhoneNo && <CardDisplayLabel label={i18next.t("phone_number")} value={data.customerPhoneNo} />}
                                        {data?.facebookName && <CardDisplayLabel label={i18next.t("fb_name")} value={data.facebookName} />}
                                        {data?.customerTownship?.division?.name && <CardDisplayLabel label={i18next.t("division_state")} value={data.customerTownship?.division?.name} />}
                                        {data?.customerTownship?.name && <CardDisplayLabel label={i18next.t("township_city")} value={data.customerTownship?.name} />}
                                        {data?.addressDetail && <CardDisplayLabel label={i18next.t("address")} value={data.addressDetail} style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} />}
                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Col span={24}>
                                            <h3 style={{ marginTop: 0 }}>{i18next.t('general_info')}</h3>
                                        </Col>
                                        <CardDisplayLabel label={i18next.t("id_number")} value={data.uniqueId} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} />
                                        <CardDisplayLabel label={i18next.t("status")} value={data.status} />
                                        <CardDisplayLabel label={i18next.t("payment_option")} value={data.paymentOption} />
                                        <CardDisplayLabel label={i18next.t("cod_amount")} value={data.codAmount} text="MMK" />
                                        <CardDisplayLabel label={i18next.t("parcel_value")} value={data.parcelAmount} text="MMK" />
                                        <CardDisplayLabel label={i18next.t("delivery_fees")} value={data.deliveryCharges} style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} text="MMK" />
                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Col span={24}>
                                            <h3 style={{ marginTop: 0 }}>{i18next.t('product_info')}</h3>
                                            <Row
                                                style={{ padding: 10, borderRadius: 8, backgroundColor: '#f1f1f1', marginBottom: 0.7, textAlign: 'center' }}>
                                                <Col span={2}>
                                                    <span>{i18next.t("no")}</span>
                                                </Col>
                                                <Col span={14}>
                                                    <span>{i18next.t("name")}</span>
                                                </Col>
                                                <Col span={3}>
                                                    <span>{i18next.t("quantity")}</span>
                                                </Col>
                                                <Col span={5}>{i18next.t("price")}</Col>

                                            </Row>
                                            {
                                                data && data.shippingItems && data.shippingItems.map((item, index) => { 
                                                    return (
                                                    <Row
                                                        key={item.id}
                                                        style={{ padding: 10, borderRadius: 8, backgroundColor: '#f1f1f1', marginBottom: 1, textAlign: 'center' }}>
                                                        <Col span={2}>
                                                            <span>{index + 1}</span>
                                                        </Col>
                                                        <Col span={14}>
                                                            <span>{item.name}</span>
                                                        </Col>
                                                        <Col span={3}>
                                                            <span>{item.quantity}</span>
                                                        </Col>
                                                        <Col span={5}>
                                                            <span>{item.price}</span>
                                                        </Col>
                                                    </Row>

                                                )})
                                            }
                                        </Col>
                                        <Col span={24} style={{ marginTop: 20 }}>
                                            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                                <h3 style={{ margin: 0 }}>{i18next.t('voucher_link')}</h3>
                                                <NJVButton type="primary" shape="circle" style={{ background: Theme.colors.primary }} icon={isCopied ? <CheckOutlined /> : <CopyOutlined/>} onClick={this.handleCopy}></NJVButton>
                                            </div>
                                            <p>{data.customerConfirmUrl}</p>
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                            {
                                                data.status === "READY" && isExitUserGroup && showButton ?
                                                    <div style={{ display: 'flex', gap: 10, justifyContent: 'right' }}>
                                                        <NJVButton htmlType="submit" type="primary" onClick={() => this.setState({ openConfirmModal: true })} style={{ background: Theme.colors.primary }} size='large'>Create Parcel</NJVButton>
                                                    </div>
                                                    : <></>
                                            }
                                        </Col>
                                    </Row>
                                </>
                        }
                    </Col>
                </Row >
            </>
        )
    }
}

export default compose(withRouter)(VoucherDetailModal)