import {
    PlusOutlined
} from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Breadcrumb, Card, Col, Radio, Row, Select, Steps, Upload, message } from "antd";
import React from "react";
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';
import { NJVButton, NJVInput, NJVSelect } from '../../components/core-component';
import NJVDatePicker from '../../components/custom-datepicker';
import { SvgCheckCircleFill, SvgExclamationMarkFill } from '../../components/custom-svgIcon';
import { ErrorLabel, FormLabel, FormLabelOptional } from '../../components/general-component';
import '../../css/order-create.css';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from "../../network/network-manager";
import withRouter from '../../network/with-router';
const { Dragger } = Upload;

const panelStyle = {
    marginBottom: 24,
    border: 'none',
    backgroundColor: 'white',
    borderRadius: 8
};


const { Option } = Select

const orderTypeList = [
    {
        id: 1,
        value: 'Door To Door'
    }, {
        id: 2,
        value: 'Bus Gate'
    }, {
        id: 3,
        value: 'Post Office'
    }
]

const deliverTypeList = [
    {
        id: 1,
        value: 'Standard',
    }, {
        id: 2,
        value: 'Same Day'
    }
]

const deliveryHashMap = {
    '1': 'Standard',
    '2': 'Same Day'
}

const timeSlot = [
    {
        id: 1,
        value: '09:00 - 12:00'
    }, {
        id: 2,
        value: '09:00 - 18:00'
    }, {
        id: 3,
        value: '09:00 - 22:00'
    }, {
        id: 4,
        value: '12:00 - 15:00'
    }, {
        id: 5,
        value: '15:00 - 18:00'
    }, {
        id: 6,
        value: '18:00 - 22:00'
    }
]

const timeSlotHashMap = {
    '1': {
        start_time: '09:00',
        end_time: '12:00',
        timezone: "Asia/Yangon"
    }, '2': {
        start_time: '09:00',
        end_time: '18:00',
        timezone: "Asia/Yangon"
    }, '3': {
        start_time: '09:00',
        end_time: '22:00',
        timezone: "Asia/Yangon"
    }, '4': {
        start_time: '12:00',
        end_time: '15:00',
        timezone: "Asia/Yangon"
    }, '5': {
        start_time: '15:00',
        end_time: '18:00',
        timezone: "Asia/Yangon"
    }, '6': {
        start_time: '18:00',
        end_time: '22:00',
        timezone: "Asia/Yangon"
    }
}

const steps = [
    {
        title: 'Sender Information',
        content: 'First-content',
    },
    {
        title: 'Receiver Information',
        content: 'Second-content',
    },
    {
        title: 'Create Order',
        content: 'Last-content',
    },
];


class OrderReturnForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            multipleReceiverList: [],
            collapseId: '',
            isOrderTypeUpload: true,
            userAddress_collection: [],
            userInput_collection: [],
            defaultLocation: {},
            userAddress_hashMap: {},
            isAllOrderComplete: false,
            firstRender: 0,
            currentStep: 2,
            pickupInformation: {},

        };
    }

    componentDidMount() {
        this.fetchData()
    }

    items = steps.map((item) => ({ key: item.title, title: item.title }));

    fetchData = async () => {
        try {
            const response = await ApiHandler({url: Api.user_address_filter,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON})
            if (response && response.content) {
                var location = {}
                var foundDefaultLocation = false
                var userAddress_hashMap = {}
                var userAddress_collection = []
                if (response.content) {
                    response.content.map((address) => {
                        if (address.defaultLocation) {
                            location = address.id
                            foundDefaultLocation = true
                        }
                        userAddress_hashMap[address.id] = {
                            "address1": address.address1,
                            "country": "MM",
                            "postcode": address.township.postalCode,
                            "township": address.township.name,
                            "state": address.township.division.name
                        }

                        if (address.township && address.township.active && address.township.division && address.township.division.active) {
                            address = { ...address, available: true }
                            userAddress_collection = [...userAddress_collection, address]
                        }



                    })
                    if (!foundDefaultLocation && response.content.length != 0) {
                        location = response.content[0].id
                    }
                }
                this.setState({
                    userAddress_collection: userAddress_collection,
                    defaultLocation: location,
                    userAddress_hashMap: userAddress_hashMap
                });
            }
            this.addDefaultReceiver()
        } catch (error) {
        }
    }

    addDefaultReceiver = () => {
        const uid = uuidv4()
        const receiver = {
            key: uid,
            label: this.receiverLabel(1, false),
            children: this.receiverForm(uid),
            style: panelStyle,
        }
        this.setState({
            multipleReceiverList: [receiver],
            collapseId: uid
        })
    }

    addNewReceiver = () => {
        const { multipleReceiverList } = this.state
        let uid = uuidv4()

        let newReceipent = {
            key: uid,
            label: this.receiverLabel(multipleReceiverList.length + 1, false),
            children: this.receiverForm(uid),
            style: panelStyle,
        }
        this.setState({
            multipleReceiverList: [...multipleReceiverList, newReceipent],
            collapseId: uid
        })
    }

    deleteReceiver = (uid) => {
        const { multipleReceiverList } = this.state
        var newMultipleReceiverList = []
        multipleReceiverList.map((value, index) => {
            if (uid != value.key) {
                newMultipleReceiverList = [...newMultipleReceiverList, value]
            }
        })
        this.setState({
            multipleReceiverList: [...newMultipleReceiverList],
            collapseId: uid
        })
    }

    normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    handleSegment = () => {
        this.setState({
            isOrderTypeUpload: !this.state.isOrderTypeUpload
        })
    }

    onChangeInputField = (event) => {
        const { id, value, name } = event.target
        if (name.startsWith('orderType')) {
            const splitedArray = name.split('/');
            this.updateInputState(splitedArray[1], splitedArray[0], value)
        } else if (name.startsWith('deliveryType')) {
            const splitedArray = name.split('/');
            this.updateInputState(splitedArray[1], splitedArray[0], value)
        } else {
            this.updateInputState(id, name, value)
        }
    }

    handleUserSelect = (value, object) => {
        const { userAddress_collection, defaultLocation } = this.state
        var receiverLocation = {}
        var senderLocation = {}
        var sameLocation = false
        userAddress_collection && userAddress_collection.map((userAddress) => {
            if (userAddress.id == value) {
                receiverLocation = userAddress.township.division
            }
            if (userAddress.id == defaultLocation) {
                senderLocation = userAddress.township.division
            }
        })
        if (receiverLocation.id == senderLocation.id) {
            sameLocation = true
        }
        this.updateInputState(object.id, object.type, value, sameLocation)
    }

    changeDatePicker = ({ dateString, name }) => {
        const splitedArray = name.split('/');
        this.updateInputState(splitedArray[1], splitedArray[0], dateString)
    }

    updateInputState = (id, key, value, sameLocation) => {
        const { userInput_collection, multipleReceiverList } = this.state
        var new_userInput_collection = []
        var update_multiple_receiver_collection = []
        let newUserInput = {};
        let isExistUserInput = true;
        if (sameLocation != null || sameLocation != undefined) {
            newUserInput = { ...newUserInput, sameLocation: sameLocation }
        }

        if (userInput_collection && userInput_collection.length != 0) {
            userInput_collection.map((userInput) => {
                if (userInput.id == id) {
                    newUserInput = { ...userInput, ...newUserInput, [key]: value }

                    let checkValidate = true;
                    if (newUserInput.pickupDate != undefined && newUserInput.pickupDate != '' && newUserInput.deliveryDate != undefined && newUserInput.deliveryDate != '') {
                        const pickupDate = new Date(newUserInput.pickupDate);
                        const deliveryDate = new Date(newUserInput.deliveryDate);
                        if (deliveryDate < pickupDate) {
                            checkValidate = false
                        }
                    }
                    newUserInput = { ...newUserInput, validDate: checkValidate }
                    new_userInput_collection = [...new_userInput_collection, newUserInput]
                    isExistUserInput = false
                } else {
                    new_userInput_collection = [...new_userInput_collection, userInput]
                }
            })
        } else {
            newUserInput = { id: id, ...newUserInput, [key]: value }
            new_userInput_collection = [newUserInput]

        }

        if (isExistUserInput) {
            newUserInput = { id: id, ...newUserInput, [key]: value }
            new_userInput_collection = [...userInput_collection, newUserInput]
        }
        multipleReceiverList && multipleReceiverList.map((receiver, index) => {
            if (receiver.key == id) {
                var validatedUserInput = this.validateFields(newUserInput);
                var newReceiver = { ...receiver, label: this.receiverLabel(index + 1, validatedUserInput.isfinish), children: this.receiverForm(receiver.key, validatedUserInput) }
                update_multiple_receiver_collection = [...update_multiple_receiver_collection, newReceiver]
            } else {
                update_multiple_receiver_collection = [...update_multiple_receiver_collection, receiver]
            }
        })
        this.setState({
            userInput_collection: new_userInput_collection,
            multipleReceiverList: update_multiple_receiver_collection
        })
    }


    validateFields = (userInput) => {
        var newUserInput = { ...userInput }
        var isFinish = true;

        if (userInput.name == null) {
            newUserInput = { ...newUserInput, name: '' }
        }
        if (userInput.phoneNumber == null) {
            newUserInput = { ...newUserInput, phoneNumber: '' }
        }
        // if (userInput.parcelType == null) {
        //     newUserInput = { ...newUserInput, parcelType: '' }
        // }
        if (userInput.orderType == null) {
            newUserInput = { ...newUserInput, orderType: 1 }
        }
        if (userInput.deliveryType == null) {
            newUserInput = { ...newUserInput, deliveryType: 1 }
        }
        if (userInput.weight == null) {
            newUserInput = { ...newUserInput, weight: 0 }
        }
        if (newUserInput.name == '') {
            isFinish = false
        }
        if (newUserInput.phoneNumber == '') {
            isFinish = false
        }
        if (newUserInput.parcelType == '') {
            isFinish = false
        }
        // if (newUserInput.weight == '' || newUserInput.weight == 0) {
        //     isFinish = false
        // }
        if (newUserInput.deliveryDate == undefined || newUserInput.deliveryDate == '') {
            isFinish = false
        }
        newUserInput = { ...newUserInput, isfinish: isFinish }
        this.setState({
            isAllOrderComplete: isFinish
        })
        return newUserInput
    }

    // pick up 
    handlePickupLocation = (value) => {
        const { pickupInformation } = this.state
        const pickUpInfo = { ...pickupInformation, 'location': value }
        this.setState({
            pickupInformation: pickUpInfo
        })
        this.checkPickUpComplete(pickUpInfo)
    }
    handlePickupDate = (value) => {
        const { pickupInformation } = this.state
        const { dateString } = value
        const pickUpInfo = { ...pickupInformation, 'pickupDate': dateString }
        this.setState({
            pickupInformation: pickUpInfo
        })
        this.checkPickUpComplete(pickUpInfo)
    }
    handlePickuptimeSlot = (value) => {
        const { pickupInformation } = this.state
        const pickUpInfo = { ...pickupInformation, 'pickupTimeSlot': timeSlotHashMap[value] }
        this.setState({
            pickupInformation: pickUpInfo
        })
        this.checkPickUpComplete(pickUpInfo)
    }

    checkPickUpComplete = (pickupInformation) => {
        var complete = true;


        if (pickupInformation.location === undefined || pickupInformation.location === '') {
            complete = false
        }
        if (pickupInformation.pickupDate === undefined || pickupInformation.pickupDate === '') {
            complete = false
        }
        if (pickupInformation.pickupTimeSlot === undefined || pickupInformation.pickupTimeSlot === '') {
            complete = false
        }
        if (complete) {
            this.setState({
                currentStep: 1
            })
        } else {
            this.setState({
                currentStep: 0
            })
        }
    }

    // pick up 
    handleDrag = (event) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.file)
    }

    handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];

            // XLSX.utils.sheet_to_json(worksheet).forEach((row) => {
            //     if (row.hasOwnProperty('ADDRESS 1')) {
            //         {
            //             row['ADDRESS_1'] = row['ADDRESS 1'];
            //             delete row['ADDRESS 1'];
            //         }
            //         // if (row['ADDRESS 1'] === 'ADDRESS 1') {
            //         //     row['ADDRESS 1'] = 'address1';
            //         // }
            //     }
            // });
            const excelData = XLSX.utils.sheet_to_json(worksheet);

            var fieldUndefined = false
            var fieldUndefinedRow = 0;
            var isValidSheet = true;
            excelData.map((value, index) => {
                if (value['FIRST NAME*'] == undefined || value['FIRST NAME*'] == '') {
                    isValidSheet = false
                }
                if (value['CONTACT*'] == undefined || value['CONTACT*'] == '') {
                    isValidSheet = false
                }
                if (value['ADDRESS 1*'] == undefined || value['ADDRESS 1*'] == '') {
                    isValidSheet = false
                }
                if (value['ADDRESS 2'] == undefined || value['ADDRESS 2'] == '') {
                    isValidSheet = false
                }
                if (value['POSTCODE'] == undefined || value['POSTCODE'] == '') {
                    isValidSheet = false
                }
                if (value['STATE'] == undefined || value['STATE'] == '') {
                    isValidSheet = false
                }
                if (value['NOTE'] == undefined || value['NOTE'] == '') {
                    isValidSheet = false
                }
                if (value['COD'] == undefined || value['COD'] == '') {
                    isValidSheet = false
                }

                const data = {
                    "service_level": 'Parcel',
                    "to": {
                        "name": value['NAME'],
                        "phone_number": value['CONTACT'],
                        "address": {
                            "address1": value['ADDRESS 1'],
                            "address2": value['ADDRESS 2'],
                            "country": "MM",
                            "postcode": value['POSTCODE'],
                            "township": '',
                            "state": value['STATE']
                        }
                    },
                    "parcel_job": {
                        "is_pickup_required": true,
                        "pickup_address_id": "98989012",
                        "pickup_service_type": "Scheduled",
                        "pickup_service_level":'Standard',
                        "pickup_date": '',
                        "pickup_timeslot": {
                            start_time: '09:00',
                            end_time: '22:00',
                            timezone: "Asia/Yangon"
                        },
                        "delivery_start_date": value['DELIVERY DATE'],
                        "delivery_timeslot": {
                            start_time: '09:00',
                            end_time: '22:00',
                            timezone: "Asia/Yangon"
                        },
                        "cash_on_delivery": value['COD'] ? value['COD'] : 0,
                    }
                }

            })
            if (fieldUndefined)
                message.error("Field can not empty at row" + fieldUndefinedRow)
        };

        reader.readAsArrayBuffer(file);
    };

    createOrder = async () => {
        const { userInput_collection, userAddress_hashMap } = this.state
        let postData = []

        userInput_collection.map((receiver) => {
            const data = {
                "marketplace": {
                    "seller_id": "38155",
                    "seller_company_name": "9032347 - A Sone Ya (CITY)"
                },
                "service_type": "Marketplace",
                "service_level": "Standard",
                "from": {
                    "name": "Min Htay",
                    "phone_number": "+95977873433",
                    "address": {
                        ...userAddress_hashMap[receiver.location]
                    }
                },
                "to": {
                    "name": receiver.name,
                    "phone_number": receiver.phoneNumber,
                    "address": {
                        ...userAddress_hashMap[receiver.location]
                    }
                },
                "parcel_job": {
                    "is_pickup_required": true,
                    "pickup_address_id": "98989012",
                    "pickup_service_type": "Scheduled",
                    "pickup_service_level": receiver.deliveryType ? deliveryHashMap[receiver.deliveryType] : deliveryHashMap[1],
                    "pickup_date": receiver.pickupDate,
                    "pickup_timeslot": {
                        ...timeSlotHashMap[receiver.delieveryTimeSlot]
                    },
                    "delivery_instructions": receiver.notes ? receiver.notes : '',
                    "delivery_start_date": receiver.deliveryDate,
                    "delivery_timeslot": {
                        ...timeSlotHashMap[receiver.delieveryTimeSlot]
                    },
                    "dimensions": {
                        "size": receiver.size ? receiver.size : '',
                        "weight": receiver.weight ? receiver.weight : 0
                    },

                }
            }
            postData = [...postData, data]
        })

        //post order
        if (postData && postData.length != 0) {
            postData.map((data) => {

            })
        }
    }

    loaded = () => {
        this.setState({
            defaultLocation: 1
        }
        )
    }

    render() {
        const { collapseId, isOrderTypeUpload, userAddress_collection, pickupInformation, currentStep } = this.state

        const draggerProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            beforeUpload: () => false,
            onChange: (info) => {
                const { file } = info;
                this.handleFileUpload(file);
            },
        };
        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={1} />
                        <Col span={22}>
                            <Breadcrumb
                                items={[
                                    {
                                        title: 'Home',
                                    },
                                    {
                                        title: 'Order Return'
                                    }
                                ]}
                            />
                            <br />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 30 }}>
                        <Col span={1}></Col>
                        <Col span={22}>
                            <Steps current={currentStep} items={this.items} />
                            <br />
                            <Card
                                style={{ backgroundColor: "#f5f5f5", boxShadow: 'none', paddingBottom: 20 }}
                                bordered={false}
                            >
                                <Row style={{ marginBottom: 15 }}>
                                    <Col span={24}>
                                        <h4>Sender Information</h4>
                                    </Col>
                                    <Col span={24} >
                                        <FormLabel label="Pickup Location" />
                                        <NJVSelect
                                            className='my-location-selector'
                                            // value={defaultLocation}
                                            // defaultValue={defaultLocation}
                                            placeholder={"Select Pickup location"}
                                            onChange={(value) => this.handlePickupLocation(value)}
                                            size='large'
                                            style={{ width: '100%' }}
                                        >
                                            {
                                                userAddress_collection && userAddress_collection.map((userAddress) => {
                                                    return <Option value={userAddress.id}>{userAddress.name}{userAddress.available}</Option>
                                                })
                                            }
                                        </NJVSelect>
                                    </Col>
                                </Row>

                                <Row style={{ marginBottom: 10 }}>
                                    <Col lg={11} md={11} sm={24} xs={24}>
                                        <FormLabel label={"Pick Up Date"} />
                                        <NJVDatePicker
                                            className="my-date-picker"
                                            name={`pickupDate`} onChange={(value) => this.handlePickupDate(value)} />
                                    </Col>
                                    <Col lg={2} md={2} />
                                    <Col lg={11} md={11} sm={24} xs={24}>
                                        <FormLabel label={"Pickup Time Slot"} />
                                        <NJVSelect
                                            className='my-location-selector'
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder={"Select time slot"}
                                            size="large"
                                            onChange={(value) => this.handlePickuptimeSlot(value)}
                                            options={
                                                timeSlot.map((slot) => {
                                                    return {
                                                        value: slot.id,
                                                        label: slot.value,
                                                        type: 'pickupTimeSlot'
                                                    }
                                                })
                                            }

                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    {
                        currentStep === 1 || currentStep === 2 ?
                            <Row style={{ marginBottom: 30 }}>
                                <Col span={1}></Col>
                                <Col span={22}>
                                    <Card
                                        style={{
                                            backgroundColor: "#f5f5f5",
                                            boxShadow: 'none',
                                            paddingTop: 25,
                                            paddingBottom: 10,
                                        }}
                                        bordered={false}
                                    >
                                        <Col span={24}>
                                            {/* <Collapse
                                                bordered={false}
                                                defaultActiveKey={[collapseId]}
                                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                style={{
                                                    background: '#fff',
                                                }}
                                                items={}
                                            /> */}
                                            <Card>
                                                {/* {this.state.multipleReceiverList} */}
                                            </Card>
                                        </Col>
                                    </Card>
                                </Col>
                            </Row>
                            :
                            <></>
                    }

                    {
                        currentStep === 2 ?
                            <Row style={{ textAlign: 'right' }}>
                                <Col span={1} />
                                <Col span={22}>
                                    <NJVButton type="primary" onClick={this.createOrder}>Create Order</NJVButton>
                                </Col>
                            </Row>
                            :
                            <>
                            </>
                    }
                </Col>
            </Row>
        );
    }

    receiverLabel = (number, finish) => {
        return <span style={{ fontWeight: 'bold', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span></span>
            {
                finish ? <SvgCheckCircleFill width={20} height={20} color="green" /> : <SvgExclamationMarkFill width={20} height={20} color="gray" />
            }


        </span>
    }



    receiverForm = (uid, userInput) => {
        const { userAddress_collection } = this.state
        return (
            <Row>
                <Col span={24} >
                    <h4>Receiver Information</h4>
                </Col>
                <Col span={24} style={{ marginBottom: 15 }}>
                    <FormLabel label="Name" />
                    <NJVInput name="name" id={uid} onChange={this.onChangeInputField} size="large" placeholder="Emily Grace" value={userInput && userInput.name ? userInput.name : ''} />
                    {
                        userInput && userInput.name == '' ? <ErrorLabel /> : <></>
                    }
                </Col>
                <Col span={24} style={{ marginBottom: 15 }}>
                    <FormLabel label="Phone Number" />
                    <NJVInput name="phoneNumber" id={uid} onChange={this.onChangeInputField} size="large" placeholder="979xxxxxxxx" value={userInput && userInput.phoneNumber ? userInput.phoneNumber : ''} />
                    {userInput && userInput.phoneNumber == '' ? <ErrorLabel /> : <></>}
                </Col>
                <Col span={24} style={{ marginBottom: 20 }}>
                    <FormLabel label={"Location"} />
                    <NJVSelect
                        style={{
                            width: '100%',
                        }}
                        placeholder={"Select receiver location"}
                        size="large"
                        onChange={this.handleUserSelect}
                        options={
                            userAddress_collection && userAddress_collection.map((userAddress) => {
                                return {
                                    value: userAddress.id,
                                    label: userAddress.name,
                                    id: uid,
                                    type: 'location'
                                }
                            })
                        }

                    />
                    {
                        userInput != undefined && (userInput.location === '' || userInput.location === undefined) ? <ErrorLabel /> : <></>
                    }
                </Col>
                <Col span={24}>
                    <FormLabelOptional label="Address Detail" />
                    <NJVInput
                        name="address" id={uid} onChange={this.onChangeInputField}
                        style={{
                            width: '100%',
                            height: 60,
                            marginBottom: 24,
                        }}
                        placeholder="No.12,Thu kha Street"
                        defaultValue={userInput && userInput.notes ? userInput.notes : ''}
                        istextareainput="true"
                    />
                </Col>
                <Col span={24} >
                    <h4>Delivery</h4>
                </Col>

                <Col span={24}>
                    <Row style={{ marginBottom: 20 }}>
                        <Col lg={24}>
                            <FormLabel label={"Delivery Date"} />
                            <NJVDatePicker
                                name={`deliveryDate/${uid}`}
                                onChange={this.changeDatePicker} />
                            {
                                userInput != undefined && (userInput.deliveryDate === '' || userInput.deliveryDate === undefined) ? <ErrorLabel /> : <></>
                            }
                            {
                                userInput != undefined && userInput.validDate != undefined && !userInput.validDate ?
                                    <span color='red'>
                                        The pickup date should be earlier than the delivery date!
                                    </span> : <></>
                            }
                        </Col>
                    </Row>

                </Col>


                <Col span={24} style={{ marginBottom: 20 }}>
                    <FormLabel label="Order Type" />
                    <Radio.Group name={`orderType/${uid}`} onChange={this.onChangeInputField} value={userInput && userInput.orderType ? userInput.orderType : 1} style={{ marginLeft: 15 }}>
                        {
                            orderTypeList.map((order) => {
                                return <Radio value={order.id}>{order.value}</Radio>
                            })
                        }
                    </Radio.Group>
                </Col>

                <Col span={24} style={{ marginBottom: 20 }}>
                    <FormLabel label="Delivery Type" />
                    <Radio.Group name={`deliveryType/${uid}`} onChange={this.onChangeInputField} value={userInput && userInput.deliveryType ? userInput.deliveryType : 1} style={{ marginLeft: 15 }}>
                        <Radio value={1}>Standard</Radio>
                        <Radio value={2} disabled={userInput && userInput.sameLocation ? !userInput.sameLocation : true}>Same Day</Radio>
                    </Radio.Group>
                    <div style={{ color: 'gray', marginLeft: 15 }}>Same day feature can be used when sender deliver same township</div>
                </Col>

                <Col span={24} style={{ marginBottom: 15 }}>
                    <Row>
                        <Col span={24}>
                            <h4>Package Information</h4>
                        </Col>
                        <Col span={24} style={{ marginBottom: 15 }}>
                            <FormLabel label="Package Photo" />
                            <Upload action="/upload.do" listType="picture-card" accept={["image/png", "image/jpg", "image/jpeg"]} maxCount={2}>
                                <div>
                                    <PlusOutlined />
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Upload
                                    </div>
                                </div>
                            </Upload>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={5} md={5} sm={11} xs={11}>
                            <div style={{ height: 32, alignItems: 'center' }}>
                                <span>
                                    Weight (kg)
                                </span>
                                <span style={{ color: 'gray' }}> (Optional)</span>
                            </div>
                            <NJVInput name="weight" id={uid} placeholder="10" onChange={this.onChangeInputField} style={{ width: '100%' }} size="large" />
                        </Col>
                        <Col lg={1} md={1} sm={2} xs={2} />
                        <Col lg={5} md={5} sm={11} xs={11}>
                            <div style={{ height: 32, alignItems: 'center' }}>
                                <span>
                                    Size
                                </span>
                                <span style={{ color: 'gray' }}> (Optional)</span>
                            </div>
                            <NJVSelect
                                defaultValue="S"
                                style={{
                                    width: '100%',
                                }}
                                size="large"
                                onChange={this.handleUserSelect}
                                options={[
                                    {
                                        id: uid,
                                        type: 'size',
                                        value: 'S',
                                        label: 'S',
                                    },
                                    {
                                        id: uid,
                                        type: 'size',
                                        value: 'M',
                                        label: 'M',
                                    },
                                    {
                                        id: uid,
                                        type: 'size',
                                        value: 'L',
                                        label: 'L',
                                    },
                                    {
                                        id: uid,
                                        type: 'size',
                                        value: 'XL',
                                        label: 'XL',

                                    }, {
                                        id: uid,
                                        type: 'size',
                                        value: 'XXL',
                                        label: 'XXL',

                                    },
                                ]}
                            />

                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ marginBottom: 15 }}>
                    <FormLabelOptional label="Parcel Type" />
                    <NJVInput name="parcelType" id={uid} onChange={this.onChangeInputField} size="large" placeholder="e.g Backbag" value={userInput && userInput.parcelType ? userInput.parcelType : ''} />
                </Col>
                <Col span={24} style={{ marginBottom: 15 }}>
                    <FormLabelOptional label="Price" />
                    <NJVInput name="price" id={uid} onChange={this.onChangeInputField} size="large" placeholder="10xxx" value={userInput && userInput.price ? userInput.price : ''} />
                </Col>
                <Col span={24}>
                    <FormLabelOptional label="Notes" />
                    <NJVInput
                        name="notes" id={uid} onChange={this.onChangeInputField}
                        style={{
                            width: '100%',
                            height: 80,
                            marginBottom: 24,
                        }}
                        placeholder="If recipient is not around, leave parcel in power riser."
                        defaultValue={userInput && userInput.notes ? userInput.notes : ''}
                        istextareainput="true"
                    />
                </Col>
                {/* <Col span={24}>
                    {
                        userInput != undefined && userInput.isfinish != undefined && userInput.isfinish ?
                            <Button type='primary' onClick={this.addNewReceiver}>New Receiver</Button> :
                            <Button type='primary' disabled={true} onClick={this.addNewReceiver}>New Receiver</Button>
                    }
                    &nbsp;&nbsp;
                    <Button type="primary" onClick={() => this.deleteReceiver(uid)} danger>Delete</Button>
                </Col> */}


            </Row >
        );
    }

}

export default compose(withRouter)(OrderReturnForm);