import { Col, Image, Row, Skeleton, Typography } from "antd";
import React from "react";
import Api from "../network/api";
import { HTTP_METHOD } from "../network/httpMethod";
import { MEDIA_TYPE } from "../network/mediaType";
import { ApiHandlerNoAuth } from "../network/network-manager";
const { Title } = Typography;

class ImageGuide extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isFetchingData: false,
            imageItems: []
        }
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        this.setState({
            isFetchingData: true
        })
        await ApiHandlerNoAuth({url: Api.public_get_tutorial, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON})
            .then((response) => {
                this.setState({
                    isFetchingData: false
                })
                response.map((item) => {
                    if (item.type === "PHOTO") {
                        this.setState((prevState) => ({
                                imageItems: [...prevState.imageItems, item] 
                            }
                        ))
                    }
                })
            })
            .catch((error) => {
                this.setState({
                    isFetchingData: false
                })
            })
    }

    render() {
        const { imageItems, isFetchingData } = this.state;

        return (
            <Row>
                <Col lg={6} md={5} sm={0} xs={0} />
                <Col lg={12} md={14} sm={24} xs={24}>
                    <Row gutter={[24, 24]}>
                        <Col span={24} style={{ marginBlock: 10, textAlign: 'center' }}>
                            <Title level={2}>Ninja Van Myanmar Application Guide Book</Title>
                        </Col>
                        {isFetchingData ? (
                            <Skeleton active />
                        ) : (
                            <div style={{ marginTop: 20 }}>
                                {imageItems?.map(img => (
                                    <Image
                                        key={img.id}
                                        alt="example"
                                        style={{ width: '100%' }}
                                        src={img.url}
                                        preview={false}
                                    />
                                ))}
                            </div>
                        )}
                    </Row>
                </Col>
            </Row>

        )
    }
}

export default ImageGuide;