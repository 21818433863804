import React, { Component } from 'react'
import { NJVButton, NJVCard } from '../../components/core-component'
import { Col, Image, List, Row, Skeleton, Tag } from 'antd'
import Lottie from "lottie-react";
import dayjs from 'dayjs';
import NoDataFoundAnimation from '../../animate-lottie/lottie-not-data-found.json';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import i18next from 'i18next';
import Theme from '../../components/theme';
import CustomPath from '../../routes/custom-path';
import withRouter from '../../network/with-router';
import { compose } from '@reduxjs/toolkit';
import { TitleLevel3 } from '../../components/general-component';
import { MEDIA_TYPE } from '../../network/mediaType';
import { updateSettlementNoti } from '../../redux/profile-slice';

class PaymentHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSettlementFetching: false,
            show_load_more: false,
            isLoadMore: false,
            page: 0
        }
    }

    componentDidMount() {
        this.fetchSettlementHistory()
    }

    fetchSettlementHistory = async () => {
        try {
            this.setState({
                isSettlementFetching: true
            })
            const requestParams = {
                page : 0
            }
            const response = await ApiHandler({url: Api.shipper_settlement_history, method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams});
            if(response && response.content){
                let updatedList = [];
                response.content && response.content.map(settlement => {
                    const time = dayjs(settlement.createdDate,'YYYY-MM-DD HH:mm:ss');
                    if(time){
                        settlement = {
                            ...settlement,
                            createdDate: time.format('DD MMM,YYYY hh:mm A')
                        }
                    }
                    updatedList.push(settlement)
                })
                this.setState({
                    historyData: updatedList,
                    isSettlementFetching: false,
                    show_load_more: response.totalElements < 20 ? false : true,
                    isLast: response?.last
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleBackButton = () => {
        this.props.navigate(CustomPath.invoice)
        this.props.dispatch(updateSettlementNoti(true))
    }

    loadMore = async () => {
        const { page } = this.state
        try {
            this.setState({
                isLoadMore: true
            })
            const requestParams = {
                page : page + 1
            }
            const response = await ApiHandler({url: Api.shipper_settlement_history,method:  HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON,requestParams});
            if(response && response.content){
                let updatedList = [];
                response.content && response.content.map(settlement => {
                    const time = dayjs(settlement.createdDate,'YYYY-MM-DD HH:mm:ss');
                    if(time){
                        settlement = {
                            ...settlement,
                            createdDate: time.format('DD MMM,YYYY hh:mm A')
                        }
                    }
                    updatedList.push(settlement)
                })
                this.setState((prev) => ({
                    historyData: [...prev?.historyData,...updatedList],
                    show_load_more: response.totalElements < 20 ? false : true,
                    isLoadMore: false,
                    isLast: response?.last,
                    page: page + 1
                }))
            }
        } catch (error) {
            console.log(error);
        }
    }

  render() {
    const { historyData, isSettlementFetching, isLoadMore, show_load_more, isLast } = this.state   

        return (
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                    <TitleLevel3 label={i18next.t("settlement_history")} />
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <NJVButton type="primary" onClick={this.handleBackButton}>{i18next.t('back')}</NJVButton>
                                </Col>
                    </Row>
                    {
                        isSettlementFetching ?
                            <Skeleton />
                            :
                            <>
                                {
                                    historyData && historyData.length !== 0 ?
                                    <>
                                        <List itemLayout="vertical" size="large" dataSource={historyData} renderItem={(item) => (
                                            <NJVCard style={{
                                                marginBottom: 10,
                                                borderRadius: 20,
                                                width: '100%',
                                            }}>
                                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                                    <div style={{ borderRadius: '10px',border: '1px solid #fff',backgroundColor: '#fff',padding: '5px 10px',display: 'flex',flexDirection: 'column',alignItems: 'center',
                                                            marginRight: 20}}>
                                                        <span style={{ color: Theme.colors.primary,fontWeight: 'bold' }}>{item.createdDate?.split(/,|\s/)[1]}</span>                                            
                                                        <span style={{ fontWeight: 'bold' }}>{item.createdDate?.split(' ')[0]}</span>                                            
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between',width: '100%',alignItems: 'center', flexWrap: 'wrap', gap: 10}}>
                                                        <div style={{ display: 'flex',flexDirection: 'column',gap: 3}}>
                                                            <span>
                                                                Settlement Amount
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontSize: 17,
                                                                    fontWeight: '600'
                                                                }}
                                                            > {item.amount} MMK</span>
                                                            {
                                                            item.rejectReason && <p style={{color: '#cf1322',background: '#fff1f0',border: '1px solid #ffa39e',fontSize: 13,fontWeight: 400, margin: 0, paddingInline: 10,textAlign: 'center',borderRadius: 5}}>{item.rejectReason}</p>
                                                            }
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Image width={50} src={item.evidence} preview={item.evidence ? true : false} />
                                                            <div style={{ width: '120px',display: 'flex',justifyContent: 'center'}}>
                                                                <Tag color={`${item.status === 'READY' ? '#2db7f5' : item.status === 'CANCEL' ? '#FFA500' : item.status === 'APPROVED' ? '#87d068' : item.status === 'REJECT' ? '#ff0000' : item.status === 'PENDING' ? '#ccc' : ''}`}>{item.status}</Tag>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NJVCard>
                                        )
                                        }
                                        /> 
                                        {
                                            show_load_more && !isLast ?
                                                <div style={{ textAlign: 'center' }}>
                                                    <NJVButton type="primary" onClick={() => this.loadMore()} loading={isLoadMore}>
                                                        Load More
                                                    </NJVButton>
                                                </div>
                                                :
                                                <></>
                                        }
                                        </>
                                        : 
                                        <Row>
                                            <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                                                <Lottie
                                                    style={{ height: '200px' }}
                                                    animationData={NoDataFoundAnimation}
                                                    loop={true}
                                                    autoplay={true}
                                                />
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                                <div style={{ fontSize: 16, fontWeight: '600', marginBottom: 10 }}>
                                                    {i18next.t("no_settlement_yet")}
                                                </div>
                                                <div style={{ fontSize: 16 }}>
                                                    {i18next.t("msg_settlement_history")}
                                                </div>
                                            </Col>
                                        </Row>
                                }
                            </>
                    }
                </Col>
            </Row>
        )
  }
}

export default compose(withRouter)(PaymentHistory)