import { LoadingOutlined } from '@ant-design/icons';
import { compose } from "@reduxjs/toolkit";
import { Col, Divider, Form, Image, Input, Row, Spin, Steps, message } from "antd";
import axios from 'axios';
import i18next from "i18next";
import React from "react";
import Theme from "../components/theme";
import Api from "../network/api";
import withRouter from "../network/with-router";

const logo = require('../asset/Logo.png');

const { TextArea } = Input

const headerStyle = {
    fontSize: 25,
    fontWeight: '600',
    color: 'red'
}
const subHeaderStyle = {
    fontSize: 18,
    fontWeight: '600'
}

const contentStyle = {
    fontSize: 15,
    fontWeight: '400'
}

const liStyle = {
    marginBottom: 5
}

const axiosInstance = axios.create({
    baseURL: Api.host
});


class DeleteAccountPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            visiblePassword: false,
            isVerify: false,
            isRequestSubmit: false,
            currentStep: 0,
            phoneNumber: ''
        }
    }

    verify = async (values) => {
        message.destroy()
        if ((values.phoneNumber === undefined || values.phoneNumber === '') && (values.password === undefined || values.password === '')) {
            message.error("Please Enter Phone number & Password")
        } else if (values.phoneNumber === undefined || values.phoneNumber === '') {
            message.error("Please Enter Phone number")
        } else if (values.password === undefined || values.password === '') {
            message.error("Please Enter Password")
        } else if (values.phoneNumber.length > 12 || values.phoneNumber.length < 2) {
            message.error("Invalid Phone Number")
        } else {
            this.setState({
                isVerify: true
            })
            try {
                const response = await axiosInstance.post(Api.delete_account_verify, {
                    "username": values.phoneNumber,
                    "password": values.password
                })
                if (response) {
                    this.setState({
                        currentStep: 1,
                        phoneNumber: values.phoneNumber
                    })
                }
            } catch (error) {
                message.error("We are unable to verify your account at the moment.")
            }

        }
        this.setState({
            isVerify: false
        })

    }

    submitReason = async (formData) => {
        try {
            this.setState({
                isRequestSubmit: true
            })
            const params = {
                phoneNumber: this.state.phoneNumber,
                reason: formData.reason
            }
            const response = await axiosInstance.post(Api.delete_account_request, null, { params: params })
            if (response) {
                this.setState({
                    currentStep: 2,
                })
            }
        } catch (error) {
        }
        this.setState({
            isRequestSubmit: false
        })
    }

    render() {
        const { visiblePassword, isVerify, isRequestSubmit, currentStep } = this.state
        return (
            <>
                <Row>
                    <Col lg={7} md={7} sm={2} xs={2} />
                    <Col lg={10} md={10} sm={20} xs={20}>
                        <Row gutter={[16, 16]}>
                            <Col span={24} style={{ marginTop: 20, marginBottom: 40 }}>
                                <Image
                                    width={200}
                                    height={55}
                                    src={logo}
                                    preview={false}
                                />
                            </Col>
                            <Col span={24}>
                                <span style={headerStyle}>Delete Account</span>
                            </Col>
                            <Col span={24}>
                                <div style={subHeaderStyle}>Are you sure to continue?</div>
                                <div style={contentStyle}>
                                    <span>After the account has been deleted:</span>
                                    <ol type="1">
                                        <li style={liStyle}>You can no longer use the Ninja Van Features.</li>
                                        <li style={liStyle}>Your personal information will be cleared.</li>
                                        <li>Your order history will be cleared.</li>
                                    </ol>
                                </div>
                            </Col>
                            <Col span={24}>
                                <Divider type="horizontal" dashed={true} />
                            </Col>
                            <Col span={24}>
                                <div style={subHeaderStyle}>How to delete Ninja Van Account</div>
                                <div style={contentStyle}>
                                    <ol type="1">
                                        <li style={liStyle}>Send an acount deletion request via the function below.</li>
                                        <li style={liStyle}>Ninja Van Call Center will review your request and contact your within 3 business days.</li>
                                        <li>For more information, please contact the Ninja Van Call Center at 09777111001</li>
                                    </ol>
                                </div>
                            </Col>
                            <Col span={24}>
                                <Divider type="horizontal" dashed={true} />
                            </Col>

                            {
                                currentStep === 2 ?
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <span style={subHeaderStyle}>Your request has been successfully sent to Ninja Van.</span>
                                    </Col>
                                    :
                                    <>
                                        <Col span={24}>
                                            <div style={subHeaderStyle}>Verify first to confirm your identity:</div>
                                        </Col>
                                        <Col span={24}>
                                            <Steps
                                                current={currentStep}
                                                size="small"
                                                items={[
                                                    {
                                                        title: 'Verify Account',
                                                    },
                                                    {
                                                        title: 'Request Delete Account',
                                                    }
                                                ]}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            {
                                                currentStep === 0 ?
                                                    <>
                                                        <Form
                                                            name="normal_login"
                                                            className="login-form"
                                                            onFinish={this.verify}
                                                        >
                                                            <Row gutter={[16, 0]}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 30 }}
                                                                        name="phoneNumber"
                                                                    >
                                                                        <Input
                                                                            className="login-box"
                                                                            style={{ background: '#f5f5f5', border: 'none' }}
                                                                            placeholder={i18next.t("phone_number")}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 10 }}
                                                                        name="password"
                                                                    >
                                                                        <Input
                                                                            className="login-box"
                                                                            style={{ background: '#f5f5f5', border: 'none' }}
                                                                            type={visiblePassword ? "text" : "password"}
                                                                            placeholder={i18next.t("password")}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={24} className="login-form-button" style={{ textAlign: 'start' }} >
                                                                    <Form.Item >
                                                                        <button type="submit"
                                                                            style={{
                                                                                backgroundColor: Theme.colors.primary,
                                                                                height: 35,
                                                                                width: 100,
                                                                                textAlign: 'center',
                                                                                borderRadius: 10,
                                                                                border: 'none',
                                                                                color: 'white',
                                                                                fontSize: 15,
                                                                                cursor: 'pointer'
                                                                            }}>
                                                                            {
                                                                                isVerify ?
                                                                                    <>
                                                                                        <Spin
                                                                                            indicator={
                                                                                                <LoadingOutlined
                                                                                                    style={{
                                                                                                        fontSize: 17,
                                                                                                        color: '#fff'
                                                                                                    }}
                                                                                                    spin
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                        <span style={{ marginLeft: 5 }}>Verify</span>
                                                                                    </>
                                                                                    :
                                                                                    <span>Verify</span>
                                                                            }

                                                                        </button>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </>
                                                    :
                                                    <>
                                                        <Form
                                                            name="normal_login"
                                                            className="login-form"
                                                            onFinish={this.submitReason}
                                                            ref={this.formRef}
                                                        >
                                                            <Row gutter={[16, 0]}>
                                                                <Col span={24}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 30 }}
                                                                        name="reason"
                                                                    >
                                                                        <TextArea
                                                                            className="login-box"
                                                                            style={{ background: '#f5f5f5', border: 'none', height: 100 }}
                                                                            placeholder={i18next.t("delete_account_reason")}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>

                                                                <Col span={24} className="login-form-button" style={{ textAlign: 'start' }} >
                                                                    <Form.Item >
                                                                        <button type="submit"
                                                                            style={{
                                                                                backgroundColor: Theme.colors.primary,
                                                                                height: 35,
                                                                                width: 100,
                                                                                textAlign: 'center',
                                                                                borderRadius: 10,
                                                                                border: 'none',
                                                                                color: 'white',
                                                                                fontSize: 15,
                                                                                cursor: 'pointer'
                                                                            }}>
                                                                            {
                                                                                isRequestSubmit ?
                                                                                    <>
                                                                                        <Spin
                                                                                            indicator={
                                                                                                <LoadingOutlined
                                                                                                    style={{
                                                                                                        fontSize: 17,
                                                                                                        color: '#fff'
                                                                                                    }}
                                                                                                    spin
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                        <span style={{ marginLeft: 5 }}>Submit</span>
                                                                                    </>
                                                                                    :
                                                                                    <span>Submit</span>
                                                                            }
                                                                        </button>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </>
                                            }
                                        </Col>
                                    </>
                            }

                        </Row>
                    </Col >

                </Row >
            </>
        )
    }
}
export default compose(withRouter)(DeleteAccountPage)