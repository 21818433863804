import { Printer } from "./constant";

const accessTokenKey = 'nvms-shipper-access-token'
const refreshTokenKey = 'nvms-shipper-refresh-token'
const printKey = 'nvms-printer-key'


const encodeValue = (value) => {
  return btoa(value);
};
const decodeValue = (encodedValue) => {
  return atob(encodedValue);
};

export const LOC_storeToken = (data) => {
  if (data) {
    const encodedAccessToken = encodeValue(data.accessToken);
    const encodedRefreshToken = encodeValue(data.refreshToken);
    const encodedOsGuid = encodeValue(data.osGuid);
    localStorage.setItem(accessTokenKey, encodedAccessToken);
    localStorage.setItem(refreshTokenKey, encodedRefreshToken);
  }
}
export const LOC_getAccessToken = () => {
  const accessToken = localStorage.getItem(accessTokenKey)
  let decodeToken = null
  if (accessToken && accessToken !== null && accessToken !== undefined) {
    decodeToken = decodeValue(accessToken)
  }
  return decodeToken;
}

export const LOC_clear_token = () => {
  localStorage.clear()
}

export const LOC_getRefreshToken = () => decodeValue(localStorage.getItem(refreshTokenKey));

export const LOC_getPrinterType = () => {
  let key = localStorage.getItem(printKey)
  if (!key) {
    key = Printer.GPrinter
  }
  return Number(key)
}
export const LOC_updatePrinterType = (data) => {
  localStorage.setItem(printKey, data)
}

